<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
  <span header-title>{{(editable ? 
    (schemeId ? 'cards.products.product_pricelist_retail_edit.title_edit' : 'cards.products.product_pricelist_retail_edit.title_new' ) 
    : 'cards.products.product_pricelist_retail_edit.title') | translate
    }} 
  </span>
  <div body style="padding-bottom: 40px;">

    <scope translationsPath="cards.products.product_pricelist_retail_edit">

      <form-field dialog label="retail_chain" description="retail_chain" for="retailChain" [required]="true">
        <lxm-select select [options]="retailChains" for="retailChain" [searchable]="true" [clearable]="false" [sortByLabel]="true"></lxm-select>
        <validation-errors for="chainId" aliasTo="retailChain"></validation-errors>
      </form-field>

      <form-field dialog label="scheme_type" for="schemeType" description="scheme_type">
        <lxm-select select [options]="schemeTypeOptions" value="id" for="schemeType" [searchable]="true" [clearable]="false"></lxm-select>
        <validation-errors for="schemeType"></validation-errors>
      </form-field>

      <form-field dialog label="valid" description="valid" for="validFrom" [required]="true">
        <div combined class="flex" style="align-items: center;">
          <lxm-datepicker class="dialog-input-fixed-width-1" for="validFrom" [min]="nextDay"></lxm-datepicker>
          <ng-container *ngIf="form.get('schemeType').value === ProductPriceSchemeType.Campaign || form.get('schemeType').value === ProductPriceSchemeType.Procurement">
            <div class="ml10 mr10">-</div>
            <lxm-datepicker class="dialog-input-fixed-width-1" for="validTo"></lxm-datepicker>
          </ng-container>
        </div>
        <validation-errors for="validFrom"></validation-errors>
        <validation-errors for="validTo"></validation-errors>
      </form-field>

      <form-field dialog for="publicSalesPrice" label="public_sales_price" for="price" description="public_sales_price" [required]="true">
        <lxm-input input for="publicSalesPrice" type="price" class="dialog-input-fixed-width-1" [currency]="form.value.currency">
          <validation-errors for="publicSalesPrice"></validation-errors>
        </lxm-input>
        <span after class="ml10" style="min-width: 100px">
          <lxm-select for="currency" [readonly]="currencies.length < 2" [clearable]="false" [options]="currencies" labelField="value" compareField="id"></lxm-select>
        </span>
      </form-field>

      <form-field dialog for="discount" label="discount" for="discount"p description="discount">
        <lxm-input input type="percent" [digits]="2" class="dialog-input-fixed-width-1" for="discount"></lxm-input>
        <validation-errors for="discount"></validation-errors>
      </form-field>

      <form-field dialog for="retailSalesPrice" [required]="true" label="retail_sales_price" description="retail_sales_price">
        <lxm-input input for="retailSalesPrice" type="price" class="dialog-input-fixed-width-1" [currency]="form.value.currency">
          <validation-errors for="retailSalesPrice"></validation-errors>
        </lxm-input>
        <div after row class="ml10">
          <span class="flex flex-row">
            <span class="strong">
              {{('cards.products.product_pricelist.table.retail_prices.margin' | translate)}}:
            </span>
            <span class="ml5">
              {{margin | percentValue}}
            </span>
          </span>
        </div>
      </form-field>
    </scope>
  </div>

  <div footer class="space-between">

    <span class="flex flex-row">
      <lxm-button type="orange" label="action.save" [loading]="saveLoading" (click)="save()" buttonType="submit">
      </lxm-button>
      <lxm-button type="grey" label="action.cancel" class="ml10" (click)="closeDialog()"></lxm-button>
    </span>

    <span class="flex flex-row">
      <lxm-button *ngIf="data.scheme" type="transparent" label="action.delete" icon="trash" class="ml30"
        (click)="deleteScheme()"></lxm-button>
    </span>

  </div>

</lxm-dialog>