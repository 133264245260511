<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
    <div header-title class="flex flex-column">
        <span>{{'cards.offer.bind_supplier.title' | translate}}</span>
        <span class="mt10">{{lxmSupplierName}}</span>
    </div>

    <div body>
        {{ 'cards.offer.bind_supplier.label.bind_new_supplier_or_create_new' | translate }}

        <div [ngSwitch]="hasMatches" class="matches-container" [class.has-matches]="hasMatches">

            <div *ngSwitchCase="true">
                <div class="bold mb15">
                    {{ 'cards.offer.bind_supplier.label.matches_found' | translate }}
                </div>
                <div class="matches-list">
                    <div *ngFor="let supplier of suggestedOptions" class="supplier-match mb10" (click)="selectSupplier(supplier)" [class.selected]="form.value.retailerSupplier?.id == supplier.retailerSupplierId">
                        {{ supplier.name }}&nbsp;
                        <span *ngIf="supplier.regNo">
                            ({{ supplier.regNo }})
                        </span>
                    </div>
                </div>
            </div>

            <div *ngSwitchCase="false">
                {{ 'cards.offer.bind_supplier.label.no_suggestions_found' | translate }}
            </div>

        </div>

        <div class="flex flex-column">
            <span class="bold mb10">{{'cards.offer.bind_supplier.label.search' | translate}}</span>
            <form-field dialog [noLabel]="true">
                <lxm-select select for="retailerSupplier"
                    [searchable]="true" 
                    [labelFn]="supplierAcLabel"
                    [sortByLabel]="true"
                    url="api/suppliers/ac/">
                </lxm-select>
                <validation-errors for="retailerSupplierId" aliasTo="retailerSupplier"></validation-errors>
            </form-field>
        </div>

        <div class="flex flex-column mt20" *ngIf="!form.value.retailerSupplier?.id">
            <span class="bold mb10">{{'cards.offer.bind_supplier.label.create_new' | translate}}</span>
            <form-field dialog [noLabel]="true">
                <lxm-input class="full-width" input for="shortName" maxlength="5"></lxm-input>
                <validation-errors for="code" aliasTo="shortName"></validation-errors>
            </form-field>
        </div>

    </div>


    <div footer class="space-between">

        <div class="flex flex-row">
          <lxm-button type="primary" *ngIf="form.value.retailerSupplier?.id"
                      label="cards.offer.bind_supplier.action.bind_supplier"
                      (click)="bind()"
                      [loading]="loading"
                      buttonType="submit">
          </lxm-button>
          <lxm-button type="primary" *ngIf="!form.value.retailerSupplier?.id"
                      label="cards.offer.bind_supplier.action.create_new_supplier"
                      (click)="createNew()"
                      [loading]="loading"
                      buttonType="submit">
          </lxm-button>
          <lxm-button type="secondary"
                      class="ml10"
                      label="cards.offer.bind_supplier.action.snooze"
                      (click)="closeDialog()">
          </lxm-button>
        </div>
  
      </div>

</lxm-dialog>
  