<div class="triple-rows" stop-propagation>

  <div class="cell-row" style="position: relative">
    <div
      class="supplier-info-hover-container"
      [popper]="tooltipcontent"
      popperTrigger="hover"
      [popperDelay]="delay"
      [popperPlacement]="position"
      [popperHideOnMouseLeave]="false"
      [popperApplyClass]="(procurementScheme || campaignScheme) ? 'product-chain-info-popover' : null"
      popperAppendTo="app-root">
      <!-- <span *ngIf="mainPriceScheme">T</span> -->
      <span class="product-invalid-sm-icon" *ngIf="hasInvalidValues"></span>
      <span class="product-procurement-sm-icon" *ngIf="procurementScheme"></span>
      <span class="product-campaign-sm-icon" *ngIf="campaignScheme"> </span>
    </div>
  </div>

  <product-scheme-display-price 
    [scheme]="scheme"
    [colored]="colored"
    [pricePrecision]="pricePrecision" 
    [displayPriceField]="displayPriceField" 
    class="cell-row">
  </product-scheme-display-price>

  <div class="cell-row"></div>

</div>

<popper-content
  #tooltipcontent
  (click)="preventPropagation()">

  <!-- INVALID VALUES SECTION -->
  <div class="popover-section" *ngIf="hasInvalidValues" [class.mb20]="procurementScheme || campaignScheme">
    <div class="popover-section-header">
      <span class="product-invalid-icon"></span>
      <span class="popopver-section-header-text ml10"
        >
        {{
          "cards.products.product_price_info_popover.product_has_invalid_values"
            | translate
        }}
        </span>
    </div>
  </div>

  <!-- PROCUREMENT SECTION -->
  <div class="popover-section" *ngIf="procurementScheme"  [class.mb20]="campaignScheme">
    <div class="popover-section-header">
      <span class="product-procurement-icon"></span>
      <span class="popopver-section-header-text ml10"
        >{{procurementScheme.validFrom | formDate}} - {{procurementScheme.validTo | formDate}}</span
      >
    </div>
    <div class="popover-section-content">
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.procurement_price"
              | translate
          }}
        </span>
        <span class="procurement-price">
          {{ procurementPrice | priceValue:pricePrecision:procurementCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.discount"
              | translate
          }}
        </span>
        <span class="chain-info-discount-percentage">
          {{ procurementDiscount | percentValue }}
        </span>
      </div>
    </div>
  </div>

  <!-- CAMPAIGN SECTION -->
  <div class="popover-section" *ngIf="campaignScheme">
    <div class="popover-section-header">
      <span class="product-campaign-icon"></span>
      <span class="popopver-section-header-text ml10">
        {{ campaignScheme.validFrom | formDate }} -
        {{ endDate(campaignScheme.validTo) | formDate }}
      </span>
    </div>
    <div class="popover-section-content">
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.campaign_price" | translate
          }}
        </span>
        <span class="campaign-price">
          {{ campaignPrice | priceValue:pricePrecision:campaignCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.campaign_discount"
              | translate
          }}
        </span>
        <span class="campaign-discount-percentage">
          {{ campaignDiscount | percentValue }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.retailer_price" | translate
          }}
        </span>
        <span>
          {{ mainSchemeMainPrice | priceValue:pricePrecision:mainSchemeCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.discount"
              | translate
          }}
        </span>
        <span class="chain-info-discount-percentage">
          {{ mainSchemeDiscount | percentValue}}
        </span>
      </div>
    </div>
  </div>

</popper-content>
