<div class="field-wrapper label-position--{{labelPosition}}" [class.readonly]="readonly || formGroupDisabled">

  <span class="field-label required-{{required}}" [class.label-top]="labelTop" [class.recommended]="recommended" *ngIf="!noLabel">

    <label [for]="for" *ngIf="label" (click)="(readonly || formGroupDisabled) ? $event.preventDefault() : null"> 
      <span class="form-label" [innerHTML]="labelTranslated"></span> 
      <span class="form-required" *ngIf="isRequired || recommended">*</span>

      <description-popover *ngIf="showDescription" [(showDescription)]="showDescription" [readonly]="readonly" [description]="description" [translationsPath]="translationsPath"></description-popover>

      <!-- <ng-container *ngIf="showDescription">
        <svg-icon class="field-description-icon"
          [key]="readonly ? 'qmarkBlack' : 'qmarkGrey'"
          fontSize="14px"
          [popper]="descriptionContent"
          popperTrigger="hover"
          [popperPlacement]="position"
          [popperPositionFixed]="true"
          popperAppendTo="app-root">
        </svg-icon>
        <popper-content #descriptionContent>
          <div class="description-content" [innerHTML]="descriptionKey | translate"></div>
        </popper-content>
      </ng-container> -->
    </label>
    <ng-content select="[label]"></ng-content>

  </span>

  <div class="field-content">

    <div class="field-data">
      <ng-container *ngIf="isInput" [formGroup]="formGroup">
        <lxm-input class="{{inputClass}}"
                   [style.width]="inputWidth"
                   [type]="inputType"
                   [for]="for"
                   [maxlength]="maxlength"
                   [readonly]="readonly || formGroupDisabled">
        </lxm-input>
      </ng-container>

      <ng-container *ngIf="isTextarea" [formGroup]="formGroup">
        <lxm-textarea [for]="for"
                      [style.width]="inputWidth"
                      [maxlength]="maxlength"
                      [minRows]="minRows"
                      [readonly]="readonly || formGroupDisabled">
        </lxm-textarea>
      </ng-container>

      <ng-content select="[text]"></ng-content>
      <ng-content select="[input]"></ng-content>
      <ng-content select="[toggle]"></ng-content>
      <ng-content select="[select]"></ng-content>
      <ng-content select="[combined]"></ng-content>
      <ng-content select="language-text"></ng-content>
      <ng-content select="[any]"></ng-content>
      <ng-content select="[after]"></ng-content>

    </div>

    <ng-content select="validation-errors"></ng-content>

    <ng-content select="[below]"></ng-content>

  </div>

</div>
