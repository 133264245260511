<div *ngIf="showNoContentText && dataSource.data?.length === 0">
  <no-content image="no-products-inline" text="global.no_products_added" [inline]="true"></no-content>
</div>

<div class="sticky-table" *ngIf="dataSource.data?.length > 0">

  <div class="sticky-table-body full-width overflow-x" [formGroup]="fgInternal">

  <mat-table #table [dataSource]="dataSource" matSort [@.disabled]="false" style="position: relative;">

    <ng-container matColumnDef="offer_product_image" sticky>
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        <image-popover 
          [image]="row.get('imageUrl').value" 
          [thumbnail]="row.get('thumbnail').value" [thumbnailBase64]="true" [thumbnailContentType]="row.get('thumbnailContentType').value"
          [status]="row.get('assortmentStatus').value"></image-popover>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_name" sticky>
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.full_name' | translate"></span>
        &nbsp;
        (<span [innerHTML]="translationsPath + '.brand' | translate"></span>)
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        <span class="flex flex-column">
          <span class="link" (click)="productClick(row.get('productId').value)">
            {{row.get('name').value}} 
            <ng-container *ngIf="row.get('brand').value">
              ({{row.get('brand').value}})
            </ng-container>
          </span>
          <span>
            {{row.get('ean').value}}
          </span>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_supplier">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.supplier' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        {{row.get('supplier').value}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_display">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.display' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        <slide-toggle for="display" [readonly]="readonly"></slide-toggle>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_limit">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.limit' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = dataIndex;" [formGroup]="row">
        <div class="full-width">
          <lxm-input [readonly]="readonly" style="max-width: 72%;" for="limit" [id]="'limit_' + i" [readonly]="readonly"></lxm-input>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_available_from">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.offer_product_available_from' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        {{ row.value.availableFrom | formDate }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_list_price">
      <mat-header-cell *matHeaderCellDef>
        <span class="full-width text-end" [innerHTML]="translationsPath + '.internal_pricelist' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        <div class="full-width">
          <div class="full-width text-end new-price" *ngIf="row.value.validSchemeListPrice">
            {{ row.value.validSchemeListPrice | priceValue }}
          </div>
          <div class="full-width text-end" [class.old-price]="row.value.validSchemeListPrice">
            {{ row.value.listPrice | priceValue }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_main_assortment_price">
      <mat-header-cell *matHeaderCellDef>
        <span class="full-width text-end" [innerHTML]="translationsPath + '.main_assortment_price' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row" class="text-center">
        <div class="full-width">
          <div class="full-width text-end new-price" *ngIf="row.value.validSchemeMainAssortmentPrice">
            {{ row.value.validSchemeMainAssortmentPrice | priceValue }}
          </div>
          <div class="full-width text-end" [class.old-price]="row.value.validSchemeMainAssortmentPrice">
            {{ row.value.mainAssortmentPrice | priceValue }}
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_price_retailer">
      <mat-header-cell *matHeaderCellDef>
        <span class="full-width text-end" [innerHTML]="translationsPath + '.offer_price_retailer_campaign' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
        <div class="text-end">
          {{ row.value.salePrice | priceValue }}
        </div>
      </mat-cell>
    </ng-container>

    
    <ng-container matColumnDef="offer_product_requested_price">
      <mat-header-cell *matHeaderCellDef>
        <span class="full-width text-end" [innerHTML]="translationsPath + '.offer_product_requested_price' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end" style="overflow: visible;">
        <div style="flex:1" class="text-end">
          <lxm-input 
            [readonly]="readonly || row.value.editRequestedPrice === false" 
            for="requestedPriceInput" 
            type="price"
            (keyup.enter)="setRequestedPrice(row)" 
            *ngIf="row.value.editRequestedPrice"></lxm-input>
          <span *ngIf="!row.value.editRequestedPrice">{{ row.value.requestedPrice | priceValue }}</span>
        </div>
        <ng-container *ngIf="!readonly">
          <lxm-button type="transparent" *ngIf="!row.value.editRequestedPrice"
                  [class.hidden]="readonly"
                  [icon]="'edit'"
                  (click)="row.controls['editRequestedPrice'].setValue(!row.value.editRequestedPrice)">
          </lxm-button>
          <div *ngIf=row.value.editRequestedPrice style="height: 34px;">
            <div style="display: flex; z-index: 1;">
              <lxm-button type="transparent" (click)="setRequestedPrice(row)">
                <svg-icon key="accept" fontSize="14px"></svg-icon>
              </lxm-button>
              <lxm-button type="transparent" (click)="discardRequestedPrice(row)">
                <svg-icon key="generateSmall" fontSize="14px"></svg-icon>
              </lxm-button>
            </div>
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_recommended_retail_price">
      <mat-header-cell *matHeaderCellDef>
        <span class="full-width text-end" [innerHTML]="translationsPath + '.offer_recommended_retail_price' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
        {{ row.value.suggestedRetailPrice | priceValue:2 }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_product_retail_price">
      <mat-header-cell *matHeaderCellDef>
        <span class="full-width text-end" [innerHTML]="translationsPath + '.offer_product_retail_price' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
        <div class="flex f-1 text-end" [ngSwitch]="readonly">
          <lxm-input for="retailPrice" *ngSwitchCase="false"></lxm-input>
          <div *ngSwitchCase="true" style="flex: 1" >
            {{ row.value.retailPrice | priceValue:2 }}
          </div>
        </div> 
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="offer_campaign">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.campaign_info' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
        <ng-container [ngSwitch]="readonly">
          <lxm-button 
            *ngSwitchCase="false"
            type="beige"
            (click)="!readonly ? openCampaignDialog(row) : null"
            [icon]="!isEmpty(row.value.campaignInfo || {}) ? 'edit' : 'plus-dark'"
            [label]="!isEmpty(row.value.campaignInfo || {}) ? 'action.edit' : 'action.add'">
          </lxm-button>
          <lxm-button 
            *ngSwitchCase="true"
            type="grey"
            (click)="openCampaignDialog(row)"
            icon="info"
            label="action.view">
          </lxm-button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="request_offer_actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-button type="transparent"
                    [disabled]="row.value.editRequestedPrice"
                    [class.hidden]="readonly"
                    [icon]="row.controls['isDeleted'].value ? 'generate' : 'crossmark-sm'"
                    (click)="!readonly ? removeItem(i) : null">
        </lxm-button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns" class="products-table-head"></mat-header-row>
    <mat-row *matRowDef="let row; columns: columns"
             class="table-row-hover"
             style="cursor:default"
             [attr.id]="row.get('productId').value"
             [ngClass]="{'row-to-delete readonly': row.get('isDeleted').value || row.value.status === OfferProductStatus.Rejected }">
    </mat-row>
  </mat-table>

  </div>

</div>

<div class="flex flex-column full-width">
  <lxm-select *ngIf="!readonly"
    class="mt15"
    width="400px"
    for="newProduct"
    labelField="label"
    [formGroup]="fgInternal"
    [searchable]="true"
    placeholder="cards.offer.products.search_placeholder"
    url="api/products/market/ac/"
    placeholder="cards.offer.products.search_placeholder"
    [acLabelFormat]="formatProductAcLabel"
    [acData]="getProductAcExcludeIds">
  </lxm-select>

  <span class="products-table-total" *ngIf="dataSource.data.length">
    {{translationsPath + '.total' | translate}}: {{dataSource.data.length}}
  </span>
</div>

