<card [fullScreen]="state.isFullScreen">
  <span header-title>{{'cards.clients.clients_list.title' | translate}}</span>
  <div header-actions>
    <div class="button-group">
      <lxm-button [class.active]="isListViewActive(ClientsListViewType.All)"
        label="cards.clients.clients_list.filters.all" (click)="setListView(ClientsListViewType.All)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(ClientsListViewType.Active)"
        label="cards.clients.clients_list.filters.active" (click)="setListView(ClientsListViewType.Active)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(ClientsListViewType.Retailers)"
        label="cards.clients.clients_list.filters.retailers" (click)="setListView(ClientsListViewType.Retailers)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(ClientsListViewType.Partners)"
        label="cards.clients.clients_list.filters.partners" (click)="setListView(ClientsListViewType.Partners)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(ClientsListViewType.Clients)"
        label="cards.clients.clients_list.filters.clients" (click)="setListView(ClientsListViewType.Clients)">
      </lxm-button>
      <lxm-button [class.active]="isListViewActive(ClientsListViewType.Owned)"
        label="cards.clients.clients_list.filters.my_clients" (click)="setListView(ClientsListViewType.Owned)">
      </lxm-button>
    </div>
    <lxm-button type="beige" class="ml15" label="cards.clients.clients_list.actions.add_client" icon="plus-dark"
      routerLink="new" *requiredRights="[UserAction.ManageClients]">
    </lxm-button>
  </div>
  <div body class="sticky-table">

        <div class="sticky-table-body full-width overflow-x">
          <mat-table [dataSource]="dataSource" matSort [@.disabled]="true" [zoomValue]="state.zoom">
            <ng-container matColumnDef="client_image">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let row">
                <image-popover class="selected-image" type="regular" [popperPositionFixed]="false"
                  [image]="row.thumbnail" [imageBase64]="true" [imageContentType]="row.thumbnailContentType"
                  [thumbnail]="row.thumbnailSmall" [thumbnailBase64]="true"
                  [thumbnailContentType]="row.thumbnailContentType" [status]="row.status">
                </image-popover>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="client_name">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header>
                  {{'cards.clients.clients_list.table.client_name' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="flex overflow-ellipsis">
                  {{row.name}}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="client_tags">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.clients.clients_list.table.client_tags' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="flex">
                  <span [class]="getTagClass(tagId)" *ngFor="let tagId of limitTags(row)">
                    {{clientTagsById[tagId]?.value}}
                  </span>
                  <span 
                    class="client-tag-extra-items" 
                    [popper]="tagsTooltipContent"
                    popperTrigger="hover"
                    popperPlacement="right"
                    [popperPositionFixed]="true"
                    popperAppendTo="app-root"
                    [popperHideOnMouseLeave]="true"
                    *ngIf="row.clientTagIds?.length > 2">
                    +{{row.clientTagIds.length - 2}}
                  </span>
                  <popper-content #tagsTooltipContent stop-propagation>
                    <div>
                      <span [class]="getTagClass(tagId)" *ngFor="let tagId of row.clientTagIds">
                        {{clientTagsById[tagId]?.value}}
                      </span>
                    </div>
                  </popper-content>
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="client_reg_code">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header>
                  {{'cards.clients.clients_list.table.reg_code' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{row.regNo}}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="client_address">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header class="flex f-1 justify-start">
                  {{'cards.clients.clients_list.table.address' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{row.address || '-'}}</span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="client_is_retailer">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header class="flex f-1 justify-center">
                  {{'cards.clients.clients_list.table.retailer' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <div class="flex f-1 justify-center">
                  <span [class.check-icon]="row.isSystemClient"></span>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="last_event">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header class="flex f-1 justify-end">
                  {{'cards.clients.clients_list.table.last_event' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="flex f-1 justify-center">
                  {{(row.lastEventDate || row.modifiedAt) | formDate}}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="upcoming_event">
              <mat-header-cell *matHeaderCellDef>
                <span mat-sort-header class="flex f-1 justify-end">
                  {{'cards.clients.clients_list.table.upcoming_event' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="flex f-1 justify-center">
                  {{row.upcomingEventDate ? (row.upcomingEventDate | formDate) : ''}}
                </span>
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
              <span mat-sort-header class="flex f-1 justify-center">
                {{'cards.clients.clients_list.table.status' | translate}}
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="flex f-1 justify-center">
                <span>{{row.status | translateEnum: 'ClientStatus'}}</span>
              </div>
            </mat-cell>
          </ng-container> -->

            <ng-container matColumnDef="client_visible_in_products_list">
              <mat-header-cell *matHeaderCellDef>
                <span class="flex f-1 justify-center">
                  {{'cards.clients.clients_list.table.visible_in_products_list' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" stop-propagation>
                <ng-container>
                  <div class="flex f-1 justify-center" class="no-click" [formGroup]="createRowFormGroup(row)">
                    <slide-toggle for="visibleInProductsList"></slide-toggle>
                  </div>
                </ng-container>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="table.columns" class="products-table-head"></mat-header-row>
            <mat-row *matRowDef="let row; columns: table.columns;" class="table-row-hover"
              routerLink="/clients/{{row.id}}"></mat-row>
          </mat-table>
        </div>

        <no-content 
          heading="cards.clients.clients_list.no_clients_heading" 
          text="cards.clients.clients_list.no_clients_text" 
          image="no-clients" 
          *ngIf="!hasClients">
        </no-content>
        
        <data-table-footer
          [(zoom)]="state.zoom"
          [(isFullScreen)]="state.isFullScreen" 
          (onToggleFullScreen)="state.isFullScreen = $event"
          [localStorageKey]="LOCAL_STORAGE_KEY"
          [isLoading]="isLoading"
          (paginator)="hookPaginator($event)"
          [search]="search"
          (onSearch)="onSearch($event)"
          (onLoad)="onLoad()">
        </data-table-footer>

  </div>
</card>