<guest-language-selector></guest-language-selector>

<div class="activate-account-container" [class.has-password]="hasPassword">

  <card class="card-left" [formGroup]="profileData">
    <span header-title>{{'cards.activate_account.title' | translate }}</span>
    <div body class="space-between mt15 mb15">
        <div class="row" style="min-width:400px">
          <div class="col-12 pr0">

            <form-field label="cards.user_settings.profile.label.user_e_mail" for="email"
              type="input-email" [readonly]="true" [required]="true">
              <validation-errors for="email"></validation-errors>
            </form-field>
            <form-field label="cards.user_settings.profile.label.user_first_name" for="firstName"
              type="input-text" [required]="true">
              <validation-errors for="firstName"></validation-errors>
            </form-field>
            <form-field label="cards.user_settings.profile.label.user_surname" for="lastName"
              type="input-text" [required]="true">
              <validation-errors for="lastName"></validation-errors>
            </form-field>
            <form-field label="cards.user_settings.profile.label.user_id" for="idCode"
              type="input-number" description="cards.user_settings.profile.label_description.user_id">
              <validation-errors for="idCode"></validation-errors>
            </form-field>
            <form-field label="cards.user_settings.profile.label.user_phone_short">
              <span any row class="full-width">
                <lxm-select style="min-width: 125px;" [searchable]="true" [clearable]="false" [options]="phoneCodes"
                  labelField="value" value="id" for="phoneCode">
                </lxm-select>
                <input type="text" class="lxm-input ml10" formControlName="phoneNumberShort" />
              </span>
              <validation-errors for="phoneCode"></validation-errors>
              <validation-errors for="phoneNumberShort"></validation-errors>
              <validation-errors for="phoneNumber"></validation-errors>
            </form-field>

            <form-field label="cards.user_settings.profile.label.user_job_title" for="jobTitle"
              type="input-text">
              <validation-errors for="jobTitle"></validation-errors>
            </form-field>

            <!-- <form-field label="cards.user_settings.profile.label.user_portfolio_description"
                for="portfolioDescription" type="input-text">
                <validation-errors for="portfolioDescription"></validation-errors>
              </form-field>

              <form-field label="cards.user_settings.profile.label.assistants">
                <lxm-select select for="assistants" class="full-width" labelField="label" [searchable]="true"
                  [multiple]="true" [options]="assistantsOptions" compareField="id">
                </lxm-select>
                <validation-errors for="assistants"></validation-errors>
              </form-field> -->

            <ng-container *ngIf="!hasPassword">

              <!-- CHROME AUTOFILL FIX -->
              <input type="text" class="hidden-input" readonly>

              <form-field label="cards.user_settings.profile_password.label.user_password"
                for="newPassword" type="input-password">
                <validation-errors for="newPassword"></validation-errors>
              </form-field>
              <form-field label="cards.user_settings.profile_password.label.user_password_confirm"
                for="newPasswordConfirm" type="input-password">
                <validation-errors for="newPasswordConfirm"></validation-errors>
              </form-field>
            </ng-container>

          </div>
          <div class="col-12 pr0">
            <info-array class="mt20 mb20" *ngIf="hasPassword"
              prelude="cards.activate_account.access_will_be_added_to_existing_account">
            </info-array>

            <div class="flex flex-row justify-center mt25">
              <lxm-button type="primary" buttonType="submit" width="250px" icon="checkmark-white"
                label="cards.activate_account.action.activate" [loading]="saveLoading" (click)="activate()">
              </lxm-button>
            </div>
          </div>
        </div>

    </div>
  </card>

  <div class="card-right logo-no-company" [class.logo-has-company]="imageSource" [class.logo-no-company]="!imageSource">
    <div class="custom-logo-wrapper">
      <img draggable="false" *ngIf="imageSource" [src]="imageSource">
    </div>
  </div>

</div>