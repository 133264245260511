import { Component, ViewChild, AfterViewInit, ChangeDetectorRef, Output, AfterViewChecked } from "@angular/core";
import { IClvTabData } from "src/app/components/_partials/settings/clv-table/clv-table.component";
import { TabOption } from "src/app/components/_layout/tabs/tab-options/tab-option.model";
import { ClvType } from "src/app/enum/clv-type";
import { ProductClvTableComponent } from 'src/app/components/_partials/settings/clv-table/product-management/product-clv-table.component';
import { TabOptionsComponent } from 'src/app/components/_layout/tabs/tab-options/tab-options.component';
import { CLV_TYPE_DATA, LOCAL_STORAGE_KEY } from 'src/app/config';
import { ActivatedRoute } from "@angular/router";
import { AppState } from 'src/app/state/app.state';
import { LxmAppModule } from "src/app/enum";

@Component({
  selector: "product-management-settings-card",
  templateUrl: "./product-management-settings.card.html",
  styleUrls: ['./product-management-settings.card.scss'],
})
export class ProductManagementSettingsCard implements AfterViewInit, AfterViewChecked {
  public TabType = TabType;

  public options: TabOption[];
  @Output()
  public tabType: TabType;

  @ViewChild(TabOptionsComponent, { static: false })
  private _tabOptions: TabOptionsComponent;

  @ViewChild(ProductClvTableComponent, { static: false })
  private _productClvTable: ProductClvTableComponent;

  public initialData;

  public tabLocalStorageKey = LOCAL_STORAGE_KEY.TAB_PRODUCT_MANAGEMENT_SETTINGS;

  constructor(
    private _appState: AppState,
    private _cd: ChangeDetectorRef,
    private _route: ActivatedRoute) {
    this.initialData = this._route?.snapshot?.data?.productManagementData;

    let options = [...PRODUCT_TAB_OPTIONS];

    if (_appState.hasModule(LxmAppModule.Locations)) {
      options.push(
        new TabOption("cards.settings.product_management_settings.action.chain_brands",
          <TabData>{
            type: TabType.Clv,
            data: <IClvTabData>{
              classificatorId: ClvType.ChainBrand,
              controller: "clvs",
              displayedColumns: ["code", "value", "description", "status"],
              translationPrefix: "cards.settings.product_management_settings.wineries"
            }
          })
      );
    }

    if (_appState.hasModule(LxmAppModule.ManageCalendar)) {
      options.push(
        new TabOption("cards.settings.product_management_settings.action.calendar_groups",
        <TabData>{
          type: TabType.Clv,
          data: <IClvTabData>CLV_TYPE_DATA[ClvType.CalendarGroup]
        }),
      );
    }

    if (_appState.hasModule(LxmAppModule.BuyerCrm) || _appState.hasModule(LxmAppModule.SellerCrm)) {
      const index = options.findIndex(x => (<IClvTabData>x.data?.data)?.classificatorId === ClvType.PriceGroup);
      const option = new TabOption("cards.settings.product_management_settings.action.client_tag",
        <TabData>{
          type: TabType.Clv,
          data: <IClvTabData>CLV_TYPE_DATA[ClvType.ClientTag]
        });

      options.splice(index, 0, option);
    }

    this.options = options;
  }

  public ngAfterViewInit(): void {
    this.updateView();
    this.initialData = null;
  }

  public ngAfterViewChecked(): void {
    this.updateView();
  }

  private updateView(): void {
    if (!this._tabOptions) {
      return;
    }

    const option = this._tabOptions.selectedOption;
    if (!option || !option.data) {
      return;
    }

    switch (option.data.type) {
      case TabType.Clv: {
        if (!this._productClvTable || this._productClvTable.classificatorId === option.data.data.classificatorId) {
          break;
        }

        this._productClvTable.title = option.translationKey;
        this._productClvTable.setData(option.data.data);
        break;
      }
      case TabType.SegmentTree: {
        break;
      }
    }

    this._cd.detectChanges();
  }

  public onTabSelect(option: TabOption) {
    if (!option) {
      return;
    }

    this.tabType = option.data.type;
    this.updateView();
  }
}

export enum TabType {
  Clv = 1,
  SegmentTree = 2,
  ContentLanguages = 3
}

export interface TabData {
  type: TabType;
  data: any;
}

const PRODUCT_TAB_OPTIONS: TabOption[] = [
  new TabOption('cards.settings.product_management_settings.action.content_languages',
    {
      type: TabType.ContentLanguages
    }
  ),

  new TabOption("cards.settings.product_management_settings.action.awards",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.Award]
    }),
  new TabOption("cards.settings.product_management_settings.action.brand",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.ProductBrand]
    }),
  new TabOption("cards.settings.product_management_settings.action.codes",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.ProductUnitCodeTypeFormat]
    }),
  new TabOption("cards.settings.product_management_settings.action.product_tree",
    <TabData>{
      type: TabType.SegmentTree
    }),
  new TabOption("cards.settings.product_management_settings.action.manufacturer",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.Manufacturer]
    }),
  new TabOption("cards.settings.product_management_settings.action.logistics_partner",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.LogisticsPartner]
    }),
  new TabOption("cards.settings.product_management_settings.action.wine_region",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.WineRegionOfOrigin]
    }),
  new TabOption("cards.settings.product_management_settings.action.wineries",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.Winery]
    }),
  new TabOption("cards.settings.product_management_settings.action.product_tags",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.ProductTag]
    }),
  new TabOption("cards.settings.product_management_settings.action.price_group",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.PriceGroup]
    }),
  new TabOption("cards.settings.product_management_settings.action.campaign_type",
    <TabData>{
      type: TabType.Clv,
      data: <IClvTabData>CLV_TYPE_DATA[ClvType.CampaignType]
    })
];

