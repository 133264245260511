
<div class="row" [formGroup]="formGroup" style="
  position: sticky;
  top: -20px;
  padding-top: 20px;
  padding-bottom: 10px;
  background: var(--white);
  z-index: 10;">
  <div class="dialog-column-title flex flex-row mb15 full-width">
    <div class="flex flex-row align-center f-1">
      {{'cards.offer.assortment_and_prices_dialog.title_assortment' | translate}}
      <description-popover description="cards.offer.assortment_and_prices_dialog.title_assortment_description" class="ml2"></description-popover>
    </div>
    <div class="flex flex-row f-0">
      <dot-menu class="ml15" [template]="optionsTemplate" position="bottom-start" appendTo="offer-product-assortment" boundaries="offer-product-assortment">
        <ng-template #optionsTemplate>
          <ul>
            <li (click)="openCopyDialog()">
              <lxm-button icon="copy" label="cards.offer.assortment_and_prices_dialog.action.copy_assortment_classes"></lxm-button>
            </li>
          </ul>
        </ng-template>
      </dot-menu>
    </div>
  </div>
  <div class="col-12 col-md-12">

    <form-field
      dialog
      label="cards.offer.assortment_and_prices_dialog.label.status"
      [readonly]="readonly">
      <lxm-select
        select
        for="status"
        [options]="assortmentStatusOptions"
        labelField="value"
        value="id"
        [readonly]="readonly"
      ></lxm-select>
      <validation-errors for="status"></validation-errors>
    </form-field>

    <form-field
      dialog
      label="cards.offer.assortment_and_prices_dialog.label.date_from"
      [readonly]="readonly">
      <div input class="flex flex-row f-1">
        <lxm-datepicker
          class="f-1"
          for="assortmentSchemeValidFrom"
          [disabled]="!readonly && !isStatusSelected"
          [readonly]="readonly"
        ></lxm-datepicker>
        <lxm-button
          *ngIf="isResetValidFromVisible"
          class="ml10"
          type="transparent"
          icon="generate-small"
          (click)="resetToAppropriateDate()">
        </lxm-button>
      </div>
      <validation-errors for="assortmentSchemeValidFrom" aliasTo="assortmentSchemeValidFrom"></validation-errors>
    </form-field>

    <form-field
      dialog
      label="cards.offer.assortment_and_prices_dialog.label.decision"
      description="cards.offer.assortment_and_prices_dialog.label_description.decision"
      *ngIf="isDecisionSelectAvailable"
      [disabled]="!readonly && !isStatusSelected"
      [readonly]="readonly"
      [ngSwitch]="(formGroup.value.offerProductDecisionId ? true : readonly) || isDecisionSelectVisible">
        <lxm-select 
          *ngSwitchCase="true"
          select
          for="offerProductDecisionId"
          [options]="offerProductDecisionOptions"
          value="id"
          [disabled]="!readonly && !isStatusSelected"
          (change)="onDecisionSelect($event)"
          [readonly]="readonly">
          <validation-errors for="offerProductDecisionId"></validation-errors>
        </lxm-select>
        <lxm-button 
          *ngSwitchCase="false" 
          any
          (click)="toggleDecisionSelectVisibility()"
          type="transparent" 
          label="cards.offer.assortment_and_prices_dialog.action.add_to_decision"
          icon="plus-dark">
        </lxm-button>
    </form-field>

    <form-field
      dialog
      label="cards.offer.assortment_dialog.label.assortment_parent"
      *ngIf="tagsByParentTag.length > 0">
      <lxm-select
        select
        labelField="name"
        [options]="parentTags"
        [disabled]="!readonly && !isStatusSelected"
        value="id"
        for="parentTagId"
        [clearable]="false"
        (change)="onParentTagChange($event)"
        [readonly]="readonly"
      ></lxm-select>
    </form-field>
  </div>
</div>

<div class="row" any [ngSwitch]="tagsByParentTag.length > 0">
  <div class="col-12 col-md-12">
    <assortment-group-selector
      *ngSwitchCase="true"
      [disabled]="!readonly && !isStatusSelected"
      for="assortmentGroups"
      [formGroup]="formGroup"
      [groupByTags]="tagsByParentTag"
      [assortmentGroups]="assortmentGroups"
      [assortmentGroupTags]="assortmentGroupTags"
      [assortmentGroupsByTags]="assortmentGroupsByTags"
      [readonly]="readonly">
    </assortment-group-selector>
    <assortment-group-selector
      *ngSwitchCase="false"
      [disabled]="!readonly && !isStatusSelected"
      for="assortmentGroups"
      [formGroup]="formGroup"
      [assortmentGroups]="assortmentGroups"
      [assortmentGroupTags]="assortmentGroupTags"
      [readonly]="readonly">
    </assortment-group-selector>
  </div>
</div>
