<div class="client-discount-item">
    <div class="flex flex-column">
      <span class="discount-item-condition">
        {{data.description}}
      </span>
      <span class="discount-item-type">
        {{data.operator | translateEnum : 'ClientExpiryRuleOperator'}} {{ data.conditionValue1 }} 
        <ng-container *ngIf="data.operator == ClientExpiryRuleOperator.Between || data.operator == ClientExpiryRuleOperator.NotBetween">
          - {{data.conditionValue2}}
        </ng-container>
      </span>
    </div>
    <div class="flex flex-column align-end">
        <span *ngIf="data.type == ClientExpiryRuleType.Percent">{{data.percent}}%</span>
        <span *ngIf="data.type == ClientExpiryRuleType.Value">{{data.value}} {{ 'cards.clients.expiry_rules.label.days' | translate }}</span>
    </div>
</div>