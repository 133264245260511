export const LOCAL_STORAGE_KEY = {
    NAVIGATOR_OPEN: 'isNavigatorOpen',

    CALENDAR_SEARCH_PANEL_OPEN: 'isCalendarSearchPanelOpen',
    OFFERS_SEARCH_PANEL_OPEN: 'isOffersListSearchPanelOpen',
    PRODUCTS_SEARCH_PANEL_OPEN: 'isProductsListSearchPanelOpen',
    CLIENTS_SEARCH_PANEL_OPEN: 'isClientsListSearchPanelOpen',
    SUPPLIERS_SEARCH_PANEL_OPEN: 'isSuppliersListSearchPanelOpen',
    MARKET_SEARCH_PANEL_OPEN: 'isMarketSearchPanelOpen',
    NOTIFICATIONS_SEARCH_PANEL_OPEN: 'isNotificationsSearchPanelOpen',
    ALTERATIONS_SEARCH_PANEL_OPEN: 'isAlterationsSearchPanelOpen',
    PMD_PRODUCTS_LIST_SEARCH_PANEL_OPEN: 'isPmdProductsListSearchPanelOpen',
    PMD_DECISIONS_LIST_SEARCH_PANEL_OPEN: 'isPmdDecisionsListSearchPanelOpen',
    ORDER_PRODUCTS_LIST_SEARCH_PANEL_OPEN: 'isOrderProductsListSearchPanelOpen',
    ORDERS_SEARCH_PANEL_OPEN: 'isOrdersSearchPanelOpen',

    PRODUCT_BASE_INFO_CARD_OPEN: 'isProductBaseInfoCardOpen',
    PRODUCT_CUSTOM_CARD_OPEN: 'isProductCustomCardOpen',
    PRODUCT_ALCOHOL_CARD_OPEN: 'isProductAlcoholCardOpen',
    PRODUCT_SEAFOOD_CARD_OPEN: 'isProductSeafoodCardOpen',
    PRODUCT_MEAT_CARD_OPEN: 'isProductMeatCardOpen',
    PRODUCT_MOVIE_CARD_OPEN: 'isProductMovieCardOpen',
    PRODUCT_MUSIC_CARD_OPEN: 'isProductMusicCardOpen',
    PRODUCT_GAME_CARD_OPEN: 'isProductGameCardOpen',
    PRODUCT_ELECTRONICS_CARD_OPEN: 'isProductElectronicsCardOpen',
    PRODUCT_BOOK_CARD_OPEN: 'isProductBookCardOpen',
    PRODUCT_NUTRITIONAL_CARD_OPEN: 'isProductNutritionalCardOpen',
    PRODUCT_ADDITIONAL_CARD_OPEN: 'isProductAdditionalCardOpen',
    PRODUCT_MEDICATION_CARD_OPEN: 'isProductMedicationCardOpen',

    PRODUCT_ATTRIBUTES_CARD_OPEN: 'isProductAttributesCardOpen',
    PRODUCT_EXPIRY_CARD_OPEN: 'isProductExpiryCardOpen',
    PRODUCT_PACKAGING_CARD_OPEN: 'isProductPackagingCardOpen',
    PRODUCT_IMAGES_CARD_OPEN: 'isProductImagesCardOpen',

    PRODUCT_SUPPLIER_PRICES_CARD_OPEN: 'isProductSupplierPricesCardOpen',

    PRODUCT_CLIENT_PRICES_CARD_OPEN: 'isProductClientPricesCardOpen',
    PRODUCT_PRICELIST_CARD_OPEN: 'isProductPricelistCardOpen',
    PRODUCT_RETAILER_PRODUCT_ASSORTMENT_CARD_OPEN: 'isProductRetailerProductAssortmentCardOpen',
    PRODUCT_SUPPLIER_PRODUCT_ASSORTMENT_CARD_OPEN: 'isProductSupplierProductAssortmentCardOpen',
    PRODUCT_HISTORY_CARD_OPEN: 'isProductHistoryCardOpen',

    PRODUCT_VALIDATION_RETAILERS: 'productCardRetailersToValidate',

    TAB_FIELD_SETTINGS: 'field-settings-tab',
    TAB_PRODUCT_MANAGEMENT_SETTINGS: 'pm-settings-tab',
    TAB_ASSORTMENT_MANAGEMENT_SETTINGS: 'assortment-management-tab',

    PAGE_SIZE_PRODUCTS_LIST: 'PAGE_SIZE_PRODUCTS_LIST',
    PAGE_SIZE_MARKET_PRODUCTS_LIST: 'PAGE_SIZE_MARKET_PRODUCTS_LIST',
    PAGE_SIZE_OFFERS_LIST: 'PAGE_SIZE_OFFERS_LIST',
    PAGE_SIZE_NOTIFICATIONS_LIST: 'PAGE_SIZE_NOTIFICATIONS_LIST',
    PAGE_SIZE_ALTERATIONS_LIST: 'PAGE_SIZE_ALTERATIONS_LIST',
    PAGE_SIZE_CLIENTS_LIST: 'PAGE_SIZE_CLIENTS_LIST',
    PAGE_SIZE_SUPPLIERS_LIST: 'PAGE_SIZE_SUPPLIERS_LIST',
    PAGE_SIZE_LOCATIONS_LIST: 'PAGE_SIZE_LOCATIONS_LIST',
    PAGE_SIZE_LOCATION_OPENING_HOURS: 'PAGE_SIZE_LOCATION_OPENING_HOURS',
    PAGE_SIZE_ASSORTMENT_GROUPS_LIST: 'PAGE_SIZE_ASSORTMENT_GROUPS_LIST',
    PAGE_SIZE_ASSORTMENT_STATUS_LIST: 'PAGE_SIZE_ASSORTMENT_STATUS_LIST',
    PAGE_SIZE_PMD_PRODUCTS_LIST: 'PAGE_SIZE_PMD_PRODUCTS_LIST',
    PAGE_SIZE_PMD_DECISIONS_LIST: 'PAGE_SIZE_PMD_DECISIONS_LIST',
    PAGE_SIZE_PMD_DECISION_PRODUCTS_LIST: 'PAGE_SIZE_PMD_DECISION_PRODUCTS_LIST',
    PAGE_SIZE_PMD_DECISION_SELECT_PRODUCTS_LIST: 'PAGE_SIZE_PMD_DECISION_SELECT_PRODUCTS_LIST',
    PAGE_SIZE_PMD_DECISION_SELECT_PROCUREMENT_PRODUCTS_LIST: 'PAGE_SIZE_PMD_DECISION_SELECT_PROCUREMENT_PRODUCTS_LIST',
    PAGE_SIZE_ROLES_TABLE: 'PAGE_SIZE_ROLES_TABLE',
    PAGE_SIZE_USERS_TABLE: 'PAGE_SIZE_USERS_TABLE',
    PAGE_SIZE_ORDER_CATALOGUE: 'PAGE_SIZE_ORDER_CATALOGUE',
    PAGE_SIZE_ORDERS_LIST: 'PAGE_SIZE_ORDERS_LIST',
    PAGE_SIZE_PURCHASE_ORDERS_LIST: 'PAGE_SIZE_PURCHASE_ORDERS_LIST',
    PAGE_SIZE_SALES_ORDERS_LIST: 'PAGE_SIZE_SALES_ORDERS_LIST',
    PAGE_SIZE_ORDERS_DELIVERY_PLAN: 'PAGE_SIZE_ORDERS_DELIVERY_PLAN',

    PMD_PRODUCTS_LIST_SECTIONS: 'pmdProductsListSections',

    NOTIFICATIONS_PREFER_FULLSCREEN: 'notificationsFs',

    CLIENTS_LIST_VIEW_TYPE: 'clViewType',
    SUPPLIERS_LIST_VIEW_TYPE: 'suplViewType',
    SUPPLIER_OFFERS_LIST_VIEW_TYPE: 'solViewType',
    RETAILER_OFFERS_LIST_VIEW_TYPE: 'rolViewType',
    PMD_DECISIONS_LIST_VIEW_TYPE: 'pmdecViewType',
    ORDERS_PO_LIST_VIEW_TYPE: 'opoViewType',
    ORDERS_SO_LIST_VIEW_TYPE: 'osoViewType',

    PRODUCT_PDF_EXPORT_LANGUAGE: 'pPdfExportLang',
    PRODUCT_PDF_EXPORT_INCLUDE_PRICES: 'pPdfExportInclPrices',
    PRODUCT_PDF_EXPORT_COUNTRY: 'pPdfExportCountry',
    PRODUCT_PDF_EXPORT_CURRENCY: 'pPdfExportCurrency',

    CLONE_PRODUCT_UNITS: 'cloneProductUnits',
    CLONE_PRODUCT_PRICESCHEMES: 'cloneProductPriceSchemes',
    CLONE_PRODUCT_ASSORTMENT: 'cloneProductProductSchemes',
    CLONE_PRODUCT_IMAGES: 'cloneProductImages',

    PURCHASE_ORDER_SUPPLIER_ID: 'poSupplierId',
    PURCHASE_ORDER_CONTACT_ID: 'poContactId',
    PURCHASE_ORDER_LOCATION_ID: 'poLocationId',
    PURCHASE_ORDER_COMPACT_TABLE_VIEW: 'poCompactTableView',

    SALES_ORDER_CLIENT_ID: 'soClientId',
    SALES_ORDER_CONTACT_ID: 'soContactId',
    SALES_ORDER_LOCATION_ID: 'soLocationId',
    SALES_ORDER_COMPACT_TABLE_VIEW: 'soCompactTableView',

    ASSORTMENT_PARENT_TAG_ID: 'assortmentParentTagId',

    RETAILER_DETAILS_COPY_SEGMENT: 'rdcSegment',
    RETAILER_DETAILS_COPY_CODES: 'rdcCodes',
    RETAILER_DETAILS_COPY_TAGS: 'rdcTags',
    RETAILER_DETAILS_COPY_EXTRA_PARAMETERS: 'rdcParameters'
}
