<div class="row overflow-x" [formGroup]="fgInternal" *ngIf="showContent">
  <mat-table [dataSource]="dataSource" >
    <ng-container matColumnDef="expiry_type">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_storage_info.table.type' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <ng-container *ngIf="row.get('target').value?.type === ExpiryTargetType.Public">
          {{ ExpiryTargetType.Public | translateEnum : ExpiryTargetType }}
        </ng-container>
        <ng-container *ngIf="row.get('target').value?.type !== ExpiryTargetType.Public">
          <div class="flex f-1">
            <lxm-select for="target" 
              [searchable]="true"
              [compareWith]="targetCompare"
              labelField="labelKey"
              [options]="expiryTargetsOptions"
              [sortByFn]="expiryTargetSort"
              [readonly]="readonly"
              (change)="calcExpiry(row)">
              <validation-errors [for]="'retailerExpirations['+(i)+'].type'" aliasTo="target" [errorsFormGroup]="formGroup"></validation-errors>
            </lxm-select>
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expiry_days">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_storage_info.table.expiry_days' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-input class="full-width" for="expiryInDays" [readonly]="readonly" (blur)="calcExpiry(row)">
          <validation-errors [for]="'retailerExpirations['+(i)+'].expiryInDays'" aliasTo="expiryInDays" [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="min_expiry_on_delivery">
      <mat-header-cell *matHeaderCellDef>
        {{'cards.products.product_storage_info.table.min_expiry_on_delivery' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-input class="full-width" for="minExpiryOnDelivery" [readonly]="readonly">
          <validation-errors [for]="'retailerExpirations['+(i)+'].minExpiryOnDelivery'" aliasTo="minExpiryOnDelivery" [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expiry_retailer">
      <mat-header-cell *matHeaderCellDef>
        <ng-container>
          {{'cards.products.product_storage_info.table.expiry_days_retailer' | translate}}
        </ng-container>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-input class="full-width" for="expiryForRetailerInDays" [readonly]="readonly">
          <validation-errors [for]="'retailerExpirations['+(i)+'].expiryForRetailerInDays'" aliasTo="expiryForRetailerInDays" [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expiry_to_target">
      <mat-header-cell *matHeaderCellDef>
        <ng-container>
          {{'cards.products.product_storage_info.table.expiry_days_target' | translate}}
        </ng-container>
      </mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
        <lxm-input class="full-width" for="expiryForRetailerInDays" [readonly]="readonly">
          <validation-errors [for]="'retailerExpirations['+(i)+'].expiryForRetailerInDays'" aliasTo="expiryForRetailerInDays" [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-input>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expiry_actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row; let i = index" [formGroup]="row" class="flex-end">
        <lxm-button *ngIf="!readonly && row.get('target').value?.type !== ExpiryTargetType.Public"
                    type="transparent"
                    icon="trash"
                    title="action.remove"
                    padding="8px 0"
                    (click)="removeItem(i)"></lxm-button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: table.columns;" [ngClass]="{'row-to-delete readonly' : row.get('isDeleted').value}"></mat-row>
  </mat-table>

</div>

<div class="row f-1" *ngIf="!showContent">
  <no-content image="no-expiry" text="cards.products.product_storage_info.no_storage_info" [inline]="true"></no-content>
</div>

<div class="row mt10" *ngIf="!readonly && !singleRow">
  <lxm-button type="transparent" icon="plus-dark" label="cards.products.product_storage_info.table.add_new" (click)="addItem()"></lxm-button>
</div>
