<card [formGroup]="form">

  <span header-title>
    {{ 'cards.offer.base_info.title_request_offer' | translate}}
  </span>

  <div header-actions>

    <ng-container>
      <div class="flex align-center" *ngIf="!edit">

        <ng-container *ngIf="isEditable">
          <lxm-button class="ml15" type="green" icon="send" (click)="send()" [loading]="sendingOfferRequest"
            label="cards.offer.base_info.action.request_offer" *requiredRights="[UserAction.ManageReceivedOffers]">
          </lxm-button>
        </ng-container>

        <lxm-button class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"
          *ngIf="offer?.status == OfferStatus.Created || offer?.status == OfferStatus.Negotiating">
        </lxm-button>
      </div>

      <div class="flex align-center" *ngIf="edit">
        <lxm-button class="ml15" type="grey" icon="back" label="action.back"
          (click)="offerId ? toggleEdit() : backToList()">
        </lxm-button>
        <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
          buttonType="submit">
        </lxm-button>
      </div>
    </ng-container>
  </div>

  <div body>
    <card-section>

    <div class="row">
      <div class="col-6 no-padding" [class.col-12]="!offerId">

        <form-field [required]="edit" label="cards.offer.base_info.label.offer_type">
          <lxm-radio any for="offerType" [options]="offerTypeOptions" [readonly]="!edit" class="offer-type"
            discriminator="code" labelField="translatedValue" [isTranslated]="true">
          </lxm-radio>
          <validation-errors for="offerType"></validation-errors>
        </form-field>

        <form-field label="cards.offer.base_info.label.retailer_campaign"
          *ngIf="form.value.offerType?.id == OfferType.Campaign && retailerCampaigns.length"
          [required]="edit">
          <lxm-select select for="retailerCampaign" [options]="retailerCampaigns" labelField="value" [readonly]="!edit">
          </lxm-select>
          <validation-errors for="retailerCampaign"></validation-errors>
        </form-field>

      </div>

      <div class="col-6 no-padding" *ngIf="offerId">

        <form-field label="cards.offer.base_info.label.offer_status">
          <div any>
            <status-indicator [status]="getOfferStatusProgress(offer?.status)"
              [description]="offer?.status | translateEnum : 'OfferRequestStatus'"></status-indicator>
          </div>

        </form-field>

      </div>
    </div>

    <div class="row">
      <div class="col-12 no-padding">

        <form-field label="cards.offer.base_info.label.currency">
          <lxm-select select for="currency"
            [readonly]="!edit || !canEditCurrency"
            [clearable]="false"
            [searchable]="true"
            [sortByLabel]="true"
            [options]="currencyOptions"
            labelField="value"
            compareField="id"
            class="full-width"></lxm-select>
          <validation-errors for="currencyId" aliasTo="currency"></validation-errors>
        </form-field>

      </div>
    </div>

    <div class="row">

      <div class="col-12 no-padding">

        <form-field [required]="edit" label="cards.offer.base_info.label.offer_date"
          [readonly]="!edit">
          <div any class="flex flex-row">
            <div class="date-range" [ngClass]="{'date-range--edit': edit}">
              <lxm-datepicker for="dateFrom"></lxm-datepicker>
              <div class="date-range__separator">-</div>
              <lxm-datepicker for="dateTo"></lxm-datepicker>
            </div>
            <div class="flex flex-row">
              <button class="greyed-button date-period-button mw96 ml15" (click)="setDatePeriod('week')"
                [class.active]="period === 'week'">
                {{'global.period.week' | translate}}
              </button>
              <button class="greyed-button date-period-button mw96 ml15" (click)="setDatePeriod('month')"
                [class.active]="period === 'month'">
                {{'global.period.month' | translate}}
              </button>
              <button class="greyed-button date-period-button mw96 ml15" (click)="setDatePeriod('year')"
                [class.active]="period === 'year'">
                {{'global.period.year' | translate}}
              </button>
              <button class="greyed-button date-period-button mw96 ml15" (click)="setDatePeriod('termless')"
                [class.active]="period === 'termless'">
                {{'global.period.termless' | translate}}
              </button>
            </div>
          </div>
          <validation-errors for="validFrom"></validation-errors>
          <validation-errors for="validTo"></validation-errors>

        </form-field>

        <form-field [required]="edit" [readonly]="!edit"
          label="cards.offer.base_info.label.offer_name" for="name" type="input-text" inputClass="w60p">
          <validation-errors for="name"></validation-errors>
        </form-field>

        <form-field label="cards.offer.base_info.label.offer_description" for="description" type="textarea"
          [readonly]="!edit">
          <validation-errors for="description"></validation-errors>
        </form-field>

      </div>

    </div>

    </card-section>

    <card-section class="mt20">
      <span header-title>{{'cards.offer.products.title' | translate}}</span>

      <div body>

        <request-offer-products-list for="products" [date]="form.get('dateFrom').value"
          [offerType]="form.get('offerType').value?.id" [readonly]="!edit">
        </request-offer-products-list>

      </div>
    </card-section>
  </div>

  <div footer class="flex justify-end" *ngIf="edit">
    <div class="flex align-center">
      <lxm-button class="ml15" type="grey" icon="back" label="action.back"
        (click)="offerId ? toggleEdit() : backToList()">
      </lxm-button>
      <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
        buttonType="submit">
      </lxm-button>
    </div>
  </div>

</card>

<a class="no-display" #download></a>