<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup">
    <span header-title>{{'cards.products.product_validation.title' | translate}}</span>

    <section body>
        <div class="flex">
            <info-array [info]="validationInstructions.text" [prelude]="validationInstructions.prelude"
                class="row mb20">
            </info-array>
        </div>
        <div class="flex f-1">
            <form-field label="cards.products.product_validation.label.retailers" class="full-width">
                <lxm-select select class="full-width" labelField="name" [searchable]="true" for="retailers"
                    [multiple]="true" [selectAllOption]="true" [options]="retailerOptions" [sortByLabel]="true" compareField="id">
                </lxm-select>
                <validation-errors for="retailerIds" aliasTo="retailers"></validation-errors>
                <div below class="flex flex-end mt20">
                    <lxm-button [disabled]="formGroup.get('retailers').value?.length < 1" [loading]="isValidateLoading" type="primary" icon="checkmark-white"
                        label="cards.products.product_validation.action.validate" (click)="validateRetailers()">
                    </lxm-button>
                </div>
            </form-field>
        </div>
        <div class="flex flex-column f-1" *ngIf="showSummary">
            <div class="flex flex-row align-center">
                <span class="bold">{{'cards.products.product_validation.summary.summary' | translate}}</span>
                <span class="ml5" [ngSwitch]="isProductValid">
                    <ng-container *ngSwitchCase="false">
                        {{'cards.products.product_validation.summary.continue_editing' | translate}}
                    </ng-container>
                    <ng-container *ngSwitchCase="true">
                        {{'cards.products.product_validation.summary.product_valid' | translate}}
                    </ng-container>
                </span>
            </div>
            <div class="invalid-fields">
                {{invalidFields}}
            </div>
            <div class="invalid-fields">
                {{invalidUnitFields}}
            </div>
        </div>

    </section>

    <section footer [ngSwitch]="hasSummary">
        <ng-container *ngSwitchCase="false">
            <lxm-button type="secondary" icon="crossmark-sm" label="cards.products.product_validation.action.close"
                (click)="closeDialog()"></lxm-button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <lxm-button type="primary" icon="view-white" label="cards.products.product_validation.action.view_result"
                (click)="closeDialog()"></lxm-button>
            <lxm-button type="secondary" class="ml15" icon="trash"
                label="cards.products.product_validation.action.discard" (click)="resetValidation()"></lxm-button>
        </ng-container>
    </section>

</lxm-dialog>