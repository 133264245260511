<ng-container [ngSwitch]="readonly" [formGroup]="formGroup">
  <mat-form-field combined [style.width]="width" *ngSwitchCase="false">
    <input matInput input 
      [min]="min"
      [max]="max"
      [formControlName]="for"
      [format]="format"
      [id]="inputId || for"
      [placeholder]="placeholder | translate"
      [matDatepicker]="picker"
      autocomplete="off"
      (blur)="blur.emit($event)"
      [disabled]="disabled">
    <mat-datepicker #picker (closed)="blur.emit($event)"
    [disabled]="disabled"></mat-datepicker>
    <mat-datepicker-toggle matSuffix suffix [for]="picker" tabindex="-1" *ngIf="!disabled">
      <span class="date-small-icon pointer" matDatepickerToggleIcon></span>
    </mat-datepicker-toggle>
  </mat-form-field>
  <span class="lxm-input datepicker-readonly full-width" *ngSwitchCase="true">
    {{formGroup.get(for)?.value | formDate: 'L':offset}}
  </span>
</ng-container>

