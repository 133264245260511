<card [fullScreen]="state.isFullScreen">
  <span header-title>{{'cards.products.products_retailer.title' | translate}}</span>
  <div header-actions *nonVisitor>

    <div class="flex align-center" *ngIf="hasProducts">
      <div class="selected-products" [tooltip]="productsTemplate" tooltipApplyClass="stripped" *ngIf="selection.length > 0">
        <span class="selected-count">
          {{(selection.length)}}
        </span>
    </div>
  
    <ng-template #productsTemplate>
      <div class="selected-products-wrapper">
        <div class="flex flex-row space-between align-center mb10">
          <span class="flex flex-row align-center">
            <h2>{{'cards.products.products_supplier.selected_products.selected_products' | translate}}</h2>
            <span class="ml5">
              ({{selection.length}})
            </span>
          </span>
          <lxm-button type="transparent" icon="trash" padding="8px 0" (click)="emptySelectedProducts()">
          </lxm-button>
        </div>
        <table class="selected-products-table">
          <tr class="header-row">
            <th class="select-column"></th>
            <th class="image-column"></th>
            <th class="name-column">
              {{'cards.products.products_supplier.table.full_name' | translate}}
            </th>
            <th class="ean-column">
              {{'cards.products.products_supplier.table.ean' | translate}}
            </th>
          </tr>
          <tr class="product-row" *ngFor="let selection of selection">
            <td class="select-column">
              <mat-checkbox class="selected-checkbox"
                (click)="$event.stopPropagation()" (change)="$event ? toggleSelection(selection) : null"
                [checked]="isProductSelected(selection)">
              </mat-checkbox>
            </td>
            <td class="image-column">
              <image-popover class="selected-image" appendTo="#selected-products-wrapper" type="product"
                [popperPositionFixed]="false" [image]="selection.imageThumbUrl" [thumbnail]="selection.thumbnail"
                [thumbnailBase64]="true" [thumbnailContentType]="selection.thumbnailContentType"
                [status]="selection.status" [isActiveBottom]="selection.activeViaOffer">
              </image-popover>
            </td>
            <td class="name-column">
              <a class="link" href="/market/{{selection.id}}" target="_blank">
                {{selection.fullName}}
              </a>
            </td>
            <td class="ean-column">
              {{selection.ean}}
            </td>
          </tr>
        </table>
      </div>
    </ng-template>

      <lxm-button type="green" class="ml15" label="cards.products.products_retailer.ask_for_offer" icon="plus-dark"
        *ngIf="appState.hasModule(LxmAppModule.ReceiveOffers) && appState.hasRight([UserAction.ManageReceivedOffers])"
        (click)="requestOffer()" [disabled]="selection.length === 0">
      </lxm-button>
      <lxm-button type="beige" class="ml15" icon="excel" (click)="exportXls()"></lxm-button>
    </div>


  </div>

  <div body class="sticky-table">

        <div class="sticky-table-body full-width overflow-x" >
          <mat-table [dataSource]="dataSource" matSort [@.disabled]="true" [zoomValue]="state.zoom">

            <!-- Checkbox Column -->
            <ng-container matColumnDef="select" sticky>
              <mat-header-cell *matHeaderCellDef>
                <div class="flex f-1 justify-center align-center">
                  <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="isAllSelected()"
                    [indeterminate]="!isAllSelected()">
                  </mat-checkbox>
                </div>
              </mat-header-cell>
              <mat-cell *matCellDef="let row" stop-propagation (click)="$event ? toggleSelection(row) : null">
                <div class="flex f-1 justify-center align-center">
                  <mat-checkbox class="table-checkbox" (click)="$event.stopPropagation()"
                    (change)="$event ? toggleSelection(row) : null" [checked]="isProductSelected(row)">
                  </mat-checkbox>
                </div>
              </mat-cell>
            </ng-container>

            <!-- Image Popover Column -->
            <ng-container matColumnDef="image" sticky>
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let row">
                <image-popover [image]="row.imageThumbUrl" [thumbnail]="row.thumbnail" [thumbnailBase64]="true"
                  [thumbnailContentType]="row.thumbnailContentType" [status]="row.status">
                </image-popover>
              </mat-cell>
            </ng-container>

            <!-- Full Name Column -->
            <ng-container matColumnDef="fullName" sticky>
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.products_retailer.table.full_name' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="flex overflow-ellipsis">
                  {{row.fullName}}
                </span>
              </mat-cell>
            </ng-container>

            <!-- EAN Column -->
            <ng-container matColumnDef="ean">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.products_retailer.table.ean' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{row.ean}}</span>
              </mat-cell>
            </ng-container>

            <!-- Brand Column -->
            <ng-container matColumnDef="brand">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.products_retailer.table.brand' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>{{row.brand}}</span>
              </mat-cell>
            </ng-container>

            <!-- Segment Column -->
            <ng-container matColumnDef="segment">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.products_retailer.table.product_tree' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span>
                  {{row.segment}}
                </span>
              </mat-cell>
            </ng-container>

            <!-- Supplier Column -->
            <ng-container matColumnDef="supplier">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.products_retailer.table.supplier' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span class="flex flex-column">
                  <span>{{row.supplier}}</span>
                </span>
              </mat-cell>
            </ng-container>

            <!-- Listed Price Column -->
            <ng-container matColumnDef="product_listed_price">
              <mat-header-cell *matHeaderCellDef>
                <span [innerHTML]="'cards.products.products_retailer.table.listed_price' | translate">
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <div class="triple-rows">
                  <div class="cell-row">
                    <product-price-info-popover
                      [scheme]="row.retailPriceScheme"
                      [pricePrecision]="pricePrecision"
                      [procurementHasPriority]="procurementHasPriority">
                    </product-price-info-popover>
                  </div>
                  <div class="cell-row">
                    <product-scheme-display-price 
                      [scheme]="row.retailPriceScheme"
                      displayPriceField="publicPrice"
                      [pricePrecision]="pricePrecision"
                      [procurementHasPriority]="procurementHasPriority">
                    </product-scheme-display-price>
                  </div>
                  <div class="cell-row">

                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <!-- Price For Retailer Column -->
            <ng-container matColumnDef="product_price_for_retailer">
              <mat-header-cell *matHeaderCellDef>
                <span [innerHTML]="'cards.products.products_retailer.table.price_for_retailer' | translate"></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <market-product-price-info-popover 
                  [scheme]="row.retailPriceScheme"
                  [pricePrecision]="pricePrecision"
                  [procurementHasPriority]="procurementHasPriority">
                </market-product-price-info-popover>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="table.columns" class="products-table-head"></mat-header-row>
            <mat-row *matRowDef="let row; columns: table.columns;" class="table-row-hover"
              routerLink="/market/{{row.id}}"></mat-row>
          </mat-table>
        </div>

      <no-content 
        heading="cards.market.no_products_heading" 
        text="cards.market.no_products_text" 
        image="no-market-products"
        *ngIf="!hasProducts">
      </no-content>

    <data-table-footer
      [localStorageKey]="LOCAL_STORAGE_KEY"
      [isLoading]="isLoading"
      (paginator)="hookPaginator($event)"
      [search]="search"
      (onSearch)="onSearch($event)"
      [(isFullScreen)]="state.isFullScreen" 
      (onToggleFullScreen)="state.isFullScreen = $event"
      [(zoom)]="state.zoom"
      (onLoad)="onLoad()">
    </data-table-footer>

  </div>
</card>

<a class="no-display" #download></a>