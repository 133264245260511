import { Component, OnInit, Input, Optional, HostBinding } from '@angular/core';
import { FormGroup, ControlContainer, AbstractControl } from '@angular/forms';

@Component({
  selector: "lxm-checkbox",
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class LxmCheckboxComponent implements OnInit {
  
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public label: string;
  @Input() public checked: boolean = undefined;
  @Input() public readonly = false;
  @Input() public model: Object;

  private _ref: AbstractControl;

  constructor(@Optional() private _controlContainer: ControlContainer) { }

  public ngOnInit(): void {
    if (this.model) {

    } 
    else if (this.for) {
      if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
        this.formGroup = <FormGroup>this._controlContainer.control;
      }
      this._ref = this.formGroup.get(this.for);
    }
 
  }

  public get isChecked() {
    if (this.checked !== undefined) {
      return this.checked;
    }
    if (this.model) {
      return this.model[this.for];
    }
    if (this.for) {
      return this.formGroup.get(this.for)?.value;
    }
    return false;
  }

  public select(event: any) {
    if (this.readonly) return;
    if (this.model) {
      this.model[this.for] = event.target.checked;
    } 
    else if (this._ref) {
      this._ref.setValue(event.target.checked);
    }
  }
  
}
