<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-12 no-padding">
        <form-field 
          [readonly]="!edit" 
          label="cards.products.product_attributes.label.product_attributes" 
          description="cards.products.product_attributes.label_description.product_attributes"
          for="attributes"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Attributes, form.get('attributes').value?.length > 0) : null">
          <lxm-select select for="attributes" class="full-width" [options]="attributeOptions" value="id"
            labelField="translatedValue" [isTranslated]="true" [searchable]="true" [multiple]="true"
            [sortByLabel]="true" [readonly]="!edit">
          </lxm-select>
          <validation-errors for="attributes"></validation-errors>
        </form-field>

      </div>
    </div>

  </div>

</card-expanding>