<ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="'percent'">
        <svg-icon [key]="changeClass" fontSize="12px"></svg-icon>
        <span class="value">
            {{value | percentValue}}
        </span> 

    </ng-container>

    <ng-container *ngSwitchCase="'absolute'">
        <span class="value">
            {{prefix}} {{value | priceValue}}
        </span> 
    </ng-container>


</ng-container>

