import { Component, Input, Inject, ViewChild, AfterViewInit, ChangeDetectorRef, OnInit, Directive, Injector } from '@angular/core';
import { forkJoin, Subscription, merge, Observable } from 'rxjs';
import { ProductPricelistService, AuthenticationService, ProductValidity } from 'src/app/_services';
import { LxmDialog } from 'src/app/_helpers/dialogs';
import { calculateMargin, calculateDiscount, calculateDiscountNumber, nextSchemeDay } from 'src/app/_helpers/calculate';
import { IPricesInfo, ISupplierBasePriceScheme, ISupplierRetailerPriceScheme, ISupplierPriceSchemeFormData, IRetailPriceSchemeFormData, IVatDto, IPriceSchemeBase } from './product-pricelist.model';
import { ActivatedRoute } from '@angular/router';
import { IClvDto, ICountry, ICountryTranslated, IProductCard, IProductCardFormData, IRetailChain } from '../product-info/product-info.model';
import { Bool, UserAction, ProductCardSection } from 'src/app/enum';
import 'src/app/ext/date';
import { AppState } from 'src/app/state/app.state';
import { appSettings } from 'src/app/app.settings';
import { IDialogDataBase, ProductPricelistDialogBase } from './product-pricelist-dialog-base';
import { ProductCardBase } from '../product-card-base';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { LocalStorage } from 'src/app/_helpers';
import { ProductPricelistBaseSchemeDialog } from './template/base-scheme/base-scheme.dialog';

@Component({
  selector: "product-pricelist-card",
  templateUrl: './product-pricelist.card.html',
  styleUrls: ['./product-pricelist.card.scss']
})
export class ProductPricelistCard extends ProductCardBase<IPricesInfo> implements OnInit {

  public title = 'cards.products.product_pricelist.title';

  public UserAction = UserAction;
  public Bool = Bool;

  public formData: IProductCardFormData;
  public pricesInfo: IPricesInfo;

  public cardSection = ProductCardSection.PriceSchemes;

  public tableSupplier = {
    columns: [
      'base_country',
      'base_valid',
      'base_user',
      'base_manufacturer',
      'base_manufacturerSalesPrice',
      'base_discount',
      'base_buyInPrice',
      'base_logisticsPartner',
      'base_publicSalesPrice',
      'base_vat'
    ]
  };

  public tableRetailer = {
    columns: [
      'base_country',
      'base_valid',
      'base_logisticsPartner',
      'base_publicSalesPrice',
      'base_currency',
      'base_vat'
    ]
  };

  @Input()
  public productId: string;

  public cardValid = true;
  public canEdit: boolean;

  private _loadSupplierPricesSubscription: Subscription;
  private _loadRetailPricesSubscription: Subscription;

  public isOwnProduct = true;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_PRICELIST_CARD_OPEN;

  constructor(
    injector: Injector,
    private _appState: AppState,
    private _cd: ChangeDetectorRef,
    private _dialog: LxmDialog,
    private _productPricelistService: ProductPricelistService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    public productValidity: ProductValidity
  ) {
    super(injector, 'productPricelist', 'productPricelistChanged');
    const productCard = _route.snapshot.data.productCard as IProductCard;
    this.isOwnProduct = productCard.isOwnProduct;
  }

  public get table() {
    return this.tableSupplier;
  }

  public retailChains: { [key: string]: IRetailChain } = {};

  public ngOnInit() {
    super.ngOnInit();
    this.canEdit = this.isOwnProduct && this._authService.hasRight([UserAction.ManagePrices]);
    this.pricesInfo = this._route.snapshot.data.productCard.pricesInfo as IPricesInfo;
    this.formData = this._route.snapshot.data.productCard.formData as IProductCardFormData;
    this.retailChains = this.formData.retailChains?.reduce((seed, x) => ({...seed, [x.id]: x}), {});
    // .sort((a, b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);
  }

  public calculateSupplierDiscount(row: ISupplierBasePriceScheme) {
    return calculateDiscount(row.manufacturerSalesPrice, row.buyInPrice);
  }

  public calculateRetailDiscount(row: ISupplierRetailerPriceScheme) {
    return calculateDiscount(row.publicSalesPrice, row.retailSalesPrice);
  }

  public calculateMargin(row: ISupplierRetailerPriceScheme) {
    const retaillerCountryId = row.retailChain?.country?.id;
    const date = row.validFrom.displayDate;
    const baseScheme = this.pricesInfo.supplierBasePrices
      ?.find(x => x.country.id === retaillerCountryId
        && x.validFrom.displayDate <= date
        && (!x.validTo || x.validTo.displayDate >= date));

    return calculateMargin(row.retailSalesPrice, baseScheme?.buyInPrice);
  }

  public loadSupplierPrices(): void {
    if (this._loadSupplierPricesSubscription) {
      this._loadSupplierPricesSubscription.unsubscribe();
    }

    this._loadSupplierPricesSubscription = this._productPricelistService
      .getSupplierPricesGridData(this.productId)
      .subscribe(result => {
        this.pricesInfo.supplierBasePrices = result;
      }, err => {
        // todo
      });
  }

  public loadRetailPrices(retailChainId: string): void {
    if (this._loadRetailPricesSubscription) {
      this._loadRetailPricesSubscription.unsubscribe();
    }

    // todo - load by retail chain id
    this._loadRetailPricesSubscription = this._productPricelistService
      .getRetailPricesGridData(this.productId, retailChainId)
      .subscribe(result => {
        this.pricesInfo.supplierRetailerPrices[retailChainId] = result;
        this._cd.markForCheck();
      }, err => {
        // todo
      });
  }

  public openBaseSchemeDialog(row?: ISupplierBasePriceScheme) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(ProductPricelistBaseSchemeDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        pricelistCard: this,
        scheme: { ...row } ?? null,
        editable: this.canEdit
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._productPricelistService.getSupplierFormData(row?.id)
        };
        return forkJoin(resolvers);
      }
    });
  }

}

export interface ISupplierDialogData extends IDialogDataBase<ISupplierPriceSchemeFormData, ISupplierBasePriceScheme> {
  editable: boolean;
}
