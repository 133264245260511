<a class="client-offer" href="/offers/{{offer.id}}" target="_blank">
    <div class="flex flex-column">
      <span class="offer-name">
        {{offer.name}}
      </span>
      <span class="offer-user">
        {{offer.createdBy?.name}}
      </span>
    </div>
    <div class="flex flex-column">
      <status-indicator [status]="getStatusProgress(offer.status)"></status-indicator>
      <span>{{offer.confirmedProductCount}}/{{offer.productCount}}</span>
    </div>
</a>