export enum LxmAppModule
{
  Pim = 1,

  BuyerPimExtensions = 3,

  SellerPimExtensions = 5,

  SendOffers = 6,
  ReceiveOffers = 7,
  Locations = 8,

  SellerCrm = 9,
  BuyerCrm = 10,

  CreatePurchaseOrders = 11,
  CreateSalesOrders = 12,

  SendProductUpdates = 13,
        
  ReceiveProductUpdates = 14,
  
  HandleReceivedProductUpdates = 15,

  ViewMarket = 16,
  ViewMarketPrices = 17,

  PurchaseManagerDesktop = 18,

  CreateTender = 19,
  ReceiveTender = 20,

  SendProductData = 21,
  ReceiveProductData = 22,

  BuyerAssortment = 23,

  BuyerPrices = 24,
  
  InviteSuppliers = 25,

  ManageCalendar = 26,

  ViewMarketCalendar = 27,

  Pdf = 28,
  
  EdiPricat = 29,

  Reports = 50,

  Admin = 9999,
}

(LxmAppModule as any).__typeName = 'LxmAppModule';

(LxmAppModule as any).__altTranslationKeys = {
  0: `enums.LxmAppModule.Common`,
};
