import { FormGroup, FormControl, AbstractControlOptions, FormArray } from '@angular/forms';

class FormUtil {

  public transformData(data: any, transforms: { [index: string]: (x?: any) => any } | undefined) {
    const res: any = {};

    if (data) {
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          if (typeof transforms[i] === 'function') {
            res[i] = transforms[i](data[i]);
          } else if (transforms.hasOwnProperty(i)) {
            // skip
          } else {
            res[i] = data[i];
          }
        }
      }
    }

    for (const i in transforms) {
      if (transforms.hasOwnProperty(i) && typeof transforms[i] === 'function' && !res.hasOwnProperty(i)) {
        res[i] = transforms[i]();
      }
    }

    return res;
  }

  public createFormGroup(
    data: any, 
    options?: AbstractControlOptions, 
    mappingOptions?: IReactiveFormsDeepMappingOptions) {
    const { deepMapping, mapKeys, skipKeys } = mappingOptions || {
      deepMapping: false,
      mapKeys: null,
      skipKeys: null
    };

    const controls: any = {};
    for (const i in data) {

      if (data.hasOwnProperty(i)) {
        const value = data[i];

        if (deepMapping) {
          
          if (skipKeys?.includes(i)) {
            controls[i] = new FormControl(value);
            continue;
          }

          if (mapKeys?.length) {
            if (!mapKeys.includes(i)) {
              controls[i] = new FormControl(value);
              continue;
            }
          }

          if (value) {
            if (Array.isArray(value)) {
              controls[i] = this.createFormArray(value, options, mappingOptions);
              continue;
            } 
    
            if (typeof value === 'object') {
                controls[i] = this.createFormGroup(value, options, mappingOptions);
                continue;
            } 
          }

        }

        controls[i] = new FormControl(value);
      }
    }

    return new FormGroup(controls, options);
  }

  public createFormArray(
    array: any[], 
    options?: AbstractControlOptions, 
    mappingOptions: IReactiveFormsDeepMappingOptions = {
      deepMapping: false
  }) {
    return new FormArray(
      array.map(item => {
        if (item && typeof item === 'object') {
          return this.createFormGroup(item, options, mappingOptions);
        }
        return new FormControl(item);
      })
    );
  }

}

export const formUtil = new FormUtil();

export const keep = (x: any) => x;

export function moveItemInFormArray(formArray: FormArray | any, fromIndex: number, toIndex: number): void {
  const dir = toIndex > fromIndex ? 1 : -1;

  const from = fromIndex;
  const to = toIndex;

  const temp = formArray.at(from);
  for (let i = from; i * dir < to * dir; i = i + dir) {
    const current = formArray.at(i + dir);
    formArray.setControl(i, current);
  }
  formArray.setControl(to, temp);
}

export interface IReactiveFormsDeepMappingOptions {
  deepMapping: boolean;
  mapKeys?: string[];
  skipKeys?: string[];
}

