<lxm-dialog [dialogRef]="dialogRef" #dialog>
  <span header-title
    >{{'cards.offer.import_product_dialog.title' | translate}}</span
  >

  <div body>
    <div class="row flex-nowrap">
      <info-array
        [info]="importInstructions.text"
        [prelude]="importInstructions.prelude"
        class="row mb20"
      >
      </info-array>
    </div>

    <div class="row justify-end">
      <lxm-button
        (click)="downloadFruitsVegetablesOfferImportXls()"
        icon="download"
        type="transparent"
        label="cards.offer.import_product_dialog.export_button"
      >
      </lxm-button>
    </div>

    <div class="row">
      <div class="col-12 no-padding">
        <file-drop-box
          (onChange)="uploadFile($event)"
          (onDelete)="deleteAttachment($event)"
          [files]="files"
          [multiple]="false"
          addFileText="cards.offer.import_product_dialog.drag_item_here"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
        </file-drop-box>
      </div>
    </div>

    <div class="row" *ngIf="!isimportInfoValid">
      <div class="col-12 no-padding">
        <span class="error-warning">
          {{'cards.import.import_main.label.incorrect_warning' |
          translate:{number: validationErrorsCount} }}
        </span>
      </div>
      <div class="col-12 no-padding">
        <form-field label="cards.import.import_main.label.errors">
          <span any class="input-padding">
            <span class="view-errors" (click)="viewErrors()"
              >{{'cards.import.import_main.action.show' | translate}}</span
            >
          </span>
        </form-field>
      </div>
    </div>
  </div>

  <section footer>
    <lxm-button
      type="primary"
      label="action.import"
      (click)="import()"
      buttonType="submit"
      [loading]="isFileLoading || isProcessing"
      [disabled]="!fileId || isFileLoading || isProcessing || !isimportInfoValid"
    >
    </lxm-button>
    <lxm-button
      type="secondary"
      label="action.close"
      (click)="closeDialog()"
      class="ml10"
    >
    </lxm-button>
  </section>
</lxm-dialog>

<a class="no-display" #download></a>
