<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" [readonly]="!canEdit" #dialog>
    <span header-title>
      {{ ('cards.settings.assortment_groups.' + (isNew ? 'title_new_item' : 'title_edit')) | translate }}
    </span>

    <div body style="padding-bottom: 40px;">
        <scope translationsPath="cards.settings.assortment_groups">

          <form-field dialog label="code" for="code" type="input-text" [readonly]="!canEdit">
            <validation-errors for="code"></validation-errors>
          </form-field>
          
          <form-field dialog label="sort_order" for="sortOrder" type="input-text" [readonly]="!canEdit">
            <validation-errors for="sortOrder"></validation-errors>
          </form-field>

          <form-field dialog label="name" for="name" type="input-text" [readonly]="!canEdit">
            <validation-errors for="name"></validation-errors>
          </form-field>

          <form-field dialog label="children">
            <lxm-select select for="children" class="full-width" 
                        value="id"
                        labelField="value"
                        [searchable]="true" 
                        [multiple]="true"
                        [readonly]="!canEdit"
                        [options]="childGroupOptions">
            </lxm-select>
            <validation-errors for="children"></validation-errors>
          </form-field>

          <form-field dialog label="suggested_children">
            <lxm-select select for="suggestedChildren" class="full-width" 
                        value="id"
                        labelField="value"
                        [searchable]="true" 
                        [multiple]="true"
                        [readonly]="!canEdit"
                        [options]="childGroupOptions">
            </lxm-select>
            <validation-errors for="children"></validation-errors>
          </form-field>

          <form-field dialog label="description" for="description" type="input-text" [readonly]="!canEdit">
            <validation-errors for="description"></validation-errors>
          </form-field>

          <form-field dialog label="status">
            <lxm-select 
              select 
              for="status" 
              class="full-width"
              value="id"
              [readonly]="!canEdit"
              [searchable]="true"
              [clearable]="false"
              [options]="statuses">
            </lxm-select>
            <validation-errors for="status"></validation-errors>
          </form-field>

        </scope>
    </div>

    <div footer>
      <lxm-button class="mr15" *ngIf="canEdit" type="primary" label="cards.settings.product_management_settings.segments_tree_edit.action.save" (click)="save()" [loading]="saveInProgress" buttonType="submit"></lxm-button>
      <lxm-button type="secondary" label="{{canEdit ? 'cards.settings.product_management_settings.segments_tree_edit.action.cancel' : 'action.close'}}" (click)="closeDialog()"></lxm-button>
    </div>
</lxm-dialog>
