<div class="flex f-1" [formGroup]="form" [class.readonly]="readonly">

  <div class="flex f-1 flex-column" [formArrayName]="for">
    <div class="flex f-1 flex-row align-center mb10"
          *ngFor="let field of formArray.controls; let i = index"
          [formGroupName]="i" [readonly]="readonly">

      <div class="dialog-input-fixed-width-3">
        <lxm-select 
                    for="deposit"
                    [searchable]="true"
                    [options]="depositOptions"
                    value="id"
                    labelField="translatedValue"
                    additionalField="country.name"
                    [isTranslated]="true"
                    [sortByLabel]="true"
                    [sortByFn]="compareDepositOptions">
          <validation-errors [for]="'packageDeposits['+i+'].depositTypeId'" aliasTo="deposit" [errorsFormGroup]="form"></validation-errors>
        </lxm-select>
      </div>

      <div class="dialog-input-fixed-width-1 ml10">
        <combined-input for="amount_pc">
          <span suffix>{{'enums.UnitShort.Piece' | translate}}</span>
          <validation-errors [for]="'packageDeposits['+i+'].amount_pc'" aliasTo="amount_pc" [errorsFormGroup]="form"></validation-errors>
        </combined-input>
      </div>

      <span class="manager-actions ml10">
        <button class="transparent-button p6"
                [class.hidden]="readonly"
                (click)="removeItem(i)">
          <span class="trash-icon"></span>
        </button>
      </span>

    </div>

    <lxm-button 
      class="wmc" 
      padding="8px 0" 
      label="cards.products.product_packaging_edit_basic_unit.action.add_deposit" 
      icon="plus-dark" 
      (click)="addItem()" 
      *ngIf="!readonly">
    </lxm-button>

  </div>

</div>
