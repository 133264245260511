<button class="lxm-button lxm-button--{{type}} justify-{{justify}}"
        [style.padding]="padding"
        [disabled]="disabled || loading"
        (click)="onButtonClick($event)"
        [type]="buttonType"
        [title]="(title || '') | translate"
        [tabIndex]="tabIndex">

  <span *ngIf="icon && iconPosition === 'left'"
        class="{{icon}}-icon"
        [class.disabled]="disabled || loading"
        [class.active]="active"
        [class.button-icon-front]="label">
  </span>

  <span class="lxm-button-text">
      {{labelKey | translate}}
      <ng-content></ng-content>
  </span>

  <span *ngIf="icon && iconPosition === 'right'"
        class="{{icon}}-icon"
        [class.disabled]="disabled || loading"
        [class.active]="active"
        [class.button-icon-after]="label">
  </span>

  <loading-spinner *ngIf="loading" [type]="type"></loading-spinner>

</button>
