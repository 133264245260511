<div class="client-discount-item" target="_blank">
    <div class="flex flex-column">
      <span class="discount-item-condition line-clamp-2">
        {{conditionName}}
      </span>
      <span class="discount-item-type">
        {{typeName}}
      </span>
    </div>
    <div class="flex flex-column align-end">
        <!-- <span class="bold">{{data.orderNr}}</span> -->
        <span>{{data.discountPercent}}%</span>
    </div>
</div>