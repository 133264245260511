<lxm-dialog [dialogRef]="dialogRef" #dialog>
    <span header-title>
        {{ (readonly ? 'cards.offer.assortment_dialog.title' : hasAssortmentId ? 'cards.offer.assortment_dialog.title_edit' : 'cards.offer.assortment_dialog.title_add') | translate }}
    </span>

    <div body [formGroup]="form" class="mb20">

        <div class="dialog-section-sticky">
            <display-product class="dialog-display-product-sticky" *ngIf="data?.product?.productId" [data]="data.product"></display-product>

            <form-field dialog label="cards.offer.assortment_dialog.label.product_payment">
                <product-payment-manager any
                    [readonly]="readonly"
                    [formGroup]="form"
                    [currency]="offerCurrency"
                    [suppliersOptions]="retailerSuppliers">
                </product-payment-manager>
            </form-field>

            <form-field dialog label="cards.offer.assortment_dialog.label.decision" *ngIf="isDecisionSelectAvailable" [readonly]="readonly">
                <lxm-select select for="offerProductDecisionId"
                    [options]="offerProductDecisionOptions"
                    value="id"
                    (change)="onDecisionSelect($event)"
                    [readonly]="readonly"></lxm-select>
                <validation-errors for="offerProductDecisionId"></validation-errors>
            </form-field>

            <form-field dialog label="cards.offer.assortment_dialog.label.date_from" [readonly]="readonly">
                <div input class="flex flex-row align-center">
                    <lxm-datepicker for="dateFrom" [readonly]="readonly"></lxm-datepicker>
                    <lxm-button
                        *ngIf="!readonly && isDecisionSelected && !decisionHasSameDate"
                        class="ml10"
                        type="transparent" 
                        icon="generate-small" 
                        (click)="resetToDecisionDate()">
                    </lxm-button>
                    <ng-container *ngIf="showDateTo">
                        <div class="ml5 mr5">-</div>
                        <lxm-datepicker for="dateTo" [readonly]="readonly"></lxm-datepicker>
                    </ng-container>
                </div>
                <validation-errors for="validFrom" aliasTo="dateFrom"></validation-errors>
                <validation-errors for="validTo" aliasTo="dateTo"></validation-errors>
            </form-field>

            <form-field dialog label="cards.offer.assortment_dialog.label.status" [readonly]="readonly">
                <lxm-select select for="status" [options]="assortmentStatusOptions" labelField="value" value="id" [readonly]="readonly"></lxm-select>
                <validation-errors for="status"></validation-errors>
            </form-field>

            <form-field dialog label="cards.offer.assortment_dialog.label.assortment_parent" *ngIf="tagsByParentTag.length > 0">
                <lxm-select select labelField="name" [options]="parentTags" value="id" for="parentTagId" [clearable]="false" (change)="getTagsBySelectedParentTag()" [readonly]="readonly"></lxm-select>
            </form-field>

        </div>

        <form-field dialog label="cards.offer.assortment_dialog.label.assortment_groups" [readonly]="readonly">
            <div class="row" any [ngSwitch]="tagsByParentTag.length > 0">
                <assortment-group-selector *ngSwitchCase="true"
                  for="assortmentGroups" 
                  [formGroup]="form"
                  [groupByTags]="tagsByParentTag"
                  [assortmentGroups]="assortmentGroups"
                  [assortmentGroupTags]="assortmentGroupTags"
                  [assortmentGroupsByTags]="assortmentGroupsByTags"
                  [readonly]="readonly">
                </assortment-group-selector>
                <assortment-group-selector *ngSwitchCase="false"
                  for="assortmentGroups"
                  [formGroup]="form"
                  [assortmentGroups]="assortmentGroups"
                  [assortmentGroupTags]="assortmentGroupTags"
                  [readonly]="readonly">
              </assortment-group-selector>
              </div>
            <validation-errors for="assortmentGroups"></validation-errors>
        </form-field>

    </div>

    <div footer class="space-between">

        <div class="flex flex-row">
            <lxm-button type="primary" label="action.save" (click)="save()" [loading]="isSaveLoading" *ngIf="!readonly"></lxm-button>
            <lxm-button type="secondary" [class.ml10]="!readonly" label="action.cancel" (click)="closeDialog(null)"></lxm-button>
        </div>

        <div>
            <lxm-button (click)="deleteAssortment()"
                *ngIf="!readonly && hasAssortmentId"
                type="transparent"
                label="action.delete"
                icon="trash"
                class="ml30"
                [loading]="isDeleteLoading"></lxm-button>
        </div>

    </div>

</lxm-dialog>