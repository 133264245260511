<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
  <span header-title>{{ (this.data.schema?.id ? 'cards.products.product_assortment_management_edit.title' : 'cards.products.product_assortment_management_add.title') | translate}}</span>
  <div body style="padding-bottom: 40px;">


    <div class="dialog-section-sticky">
      
      <form-field dialog label="cards.products.product_assortment_management_edit.label.valid" [required]="true">
        <lxm-datepicker combined class="dialog-input-fixed-width-1" for="validFrom"></lxm-datepicker>
        <validation-errors for="validFrom"></validation-errors>
      </form-field>
  
      <form-field dialog label="cards.products.product_assortment_management_edit.label.status" [required]="true">
        <lxm-select select class="dialog-input-fixed-width-2" labelField="value" [options]="assortmentStatuses" value="id" for="status" [clearable]="false"></lxm-select>
        <validation-errors for="status"></validation-errors>
      </form-field>
  
      <form-field dialog label="table.view" *ngIf="tagsByParentTag.length > 0">
        <lxm-select select class="dialog-input-fixed-width-2" labelField="name" [options]="parentTags" value="id" for="parentTagId" [clearable]="false" (change)="getTagsBySelectedParentTag()"></lxm-select>
      </form-field>

    </div>

    <form-field dialog label="cards.products.product_assortment_management_edit.label.assortment_groups">
      <div class="row" any [ngSwitch]="tagsByParentTag.length > 0">
        <assortment-group-selector *ngSwitchCase="true"
          for="assortmentGroups" 
          [formGroup]="form"
          [groupByTags]="tagsByParentTag"
          [assortmentGroups]="assortmentGroups"
          [assortmentGroupTags]="assortmentGroupTags"
          [assortmentGroupsByTags]="assortmentGroupsByTags">
        </assortment-group-selector>
        <assortment-group-selector *ngSwitchCase="false"
          for="assortmentGroups"
          [formGroup]="form"
          [assortmentGroups]="assortmentGroups"
          [assortmentGroupTags]="assortmentGroupTags">
      </assortment-group-selector>
      </div>
    </form-field>
  </div>

  <div footer class="space-between">

    <span class="flex flex-row">
      <lxm-button type="orange" label="cards.products.product_file_management.save_file" [loading]="saveLoading" (click)="save()" buttonType="submit"></lxm-button>
      <lxm-button type="grey" class="ml10" label="cards.products.product_file_management.cancel_file" (click)="closeDialog()"></lxm-button>
    </span>

    <span>
      <lxm-button *ngIf="data.schema?.id" type="transparent" label="action.delete" icon="trash" class="ml30" (click)="deleteScheme()"></lxm-button>
    </span>

  </div>

</lxm-dialog>
