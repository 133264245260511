import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { appSettings } from './app.settings';
import { appRouting, UserSettingsResolve, GuestSettingsResolve } from './app.routing';
import { UsersResolve, UsersFormDataResolve, UserRolesResolve, RolesFormDataResolve } from './resolve/lxm-settings/users.resolve';
import { SettingsFormDataResolve, SettingsProductManagementDataResolve, SettingsFieldDataResolve, AssortmentManagementDataResolve } from './resolve/settings-formdata.resolve';
import { ProductCardResolve, ProductCardFormDataResolve, ProductListFormDataResolve, ProductListCachedFilterDataResolve, ProductListDataResolve, MarketProductListDataResolve, CloneProductCardResolve } from './resolve/products.resolve';
import { OfferCardResolve, OfferCardFormDataResolve, CloneOfferCardResolve, OfferCardFromCalendarEntryResolve, OffersListFormDataResolve, RequestedOffersListDataResolve, RequestedOffersListFormDataResolve, ReceivingOffersListDataResolve, SendingOffersListDataResolve } from './resolve/offers.resolve';

import { HttpClient } from '@angular/common/http';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

import { NgxPopperModule } from 'ngx-popper';
import { ChartsModule } from 'ng2-charts';

// i18n
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// SITE LAYOUTS
import { GuestLayout } from './_layout/guest/guest.layout';
import { HomeLayout } from './_layout/home/home.layout';

// PAGES
import { LoginComponent, VisitorComponent, ActivateAccountComponent, AcceptTenantInviteComponent } from './views/login/login.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { DashboardLayout } from './views/dashboard/dashboard.layout';
import { ProductsLayout } from './views/products/products.layout';
import { OffersLayout, OffersRouter } from './views/offers/offers.layout';
import { OfferLayoutComponent } from './views/offers/offer/offer.layout';
import { ReportsLayout } from './views/reports/reports.layout';
import { SettingsLayout } from './views/settings/settings.layout';
import { UserSettingsLayout } from './views/user-settings/user-settings.layout';
import { LxmSettingsLayout } from './views/lxm-settings/lxm-settings.layout';
import { CompanyProfileLayout } from './views/lxm-settings/company-profile/company-profile.layout';
import { PackageInfoLayout } from './views/lxm-settings/package-info/package-info.layout';
import { UsersLayout } from './views/lxm-settings/users/users.layout';
import { RolesLayout } from './views/lxm-settings/roles/roles.layout';
import { MailSettingsLayout } from './views/lxm-settings/mail-settings/mail-settings.layout';

import { ProductLayout } from './views/product/product.layout';
import { NotFoundComponent } from './views/error/not-found/not-found.component';

// MAIN COMPONENTS
import { NavigatorComponent } from './components/_layout/navigator/navigator.component';

// CARD COMPONENTS
import { WelcomeCard, DashboardCalendarCard, DashboardCalendarOffer } from './cards/dashboard';
import { NotificationsCard, CardNotification } from './cards/dashboard/notifications/notifications.card';
import { OffersSupplierCard } from './cards/offers/supplier/offers-supplier.card';
import { OffersRetailerCardComponent } from './cards/offers/retailer/offers-retailer.card';
import { SellerOffersSearchCardComponent } from './cards/offers/offers-search/seller-offers-search.card';
import { BuyerOffersSearchCardComponent } from './cards/offers/offers-search/buyer-offers-search.card';
import { OfferFilesCardComponent, OfferFilesSectionComponent } from './cards/offers/offer-files/offer-files.card';
import { OfferCommentsCardComponent } from './cards/offers/offer-comments/offer-comments.card';
import { OfferHistoryCardComponent } from './cards/offers/offer-history/offer-history.card';
import { OfferCardComponent, SupplierOfferImportProductDialog } from './cards/offer/offer.card';
import { ReceivingOfferCardComponent } from './cards/offer/receiving-offer.card';
import { BindSupplierDialogComponent } from './cards/offer/dialogs/bind-supplier/bind-supplier.dialog';
import { RequestOfferCardComponent } from './cards/offer/request-offer.card';
import { OfferExportDialogComponent } from './cards/offer/dialogs/export/export-offer.dialog';
import { DashboardOffersCard } from './cards/dashboard/offers/dashboard-offers.card';
import { ProductInfoCard } from './cards/product/product-info/product-info.card';
import { ProductCustomCard } from './cards/product/product-custom/product-custom.card';
import { ProductAdditionalCard } from './cards/product/product-additional/product-additional.card';
import { ProductAttributesCard } from './cards/product/product-attributes/product-attributes.card';
import { ProductExpiryCard } from './cards/product/product-expiry/product-expiry.card';
import { ProductNutritionalCard } from './cards/product/product-nutritional/product-nutritional.card';
import { ProductSeafoodCard } from './cards/product/product-seafood/product-seafood.card';
import { ProductElectronicsCardComponent } from './cards/product/product-electronics/product-electronics.card';
import { ProductMeatCardComponent } from './cards/product/product-meat/product-meat.card';
import { ProductHistoryCardComponent } from './cards/product/product-history/product-history.card';
import { ProductAlcoholCard } from './cards/product/product-alcohol/product-alcohol.card';
import { ProductPackagingCard, ProductPackagingDialog, ProductPackagingDialogBasicUnit, ProductPackagingDialogBulkPackaging, ProductPackagingDialogPallet, ProductPackagingDialogDisplay } from './cards/product/product-packaging/product-packaging.card';
import { ProductImagesCard } from './cards/product/product-images/product-images.card';
import { ProductImageDialogComponent } from './cards/product/product-images/product-image.dialog';
import { SupplierProductAssortmentCard, SupplierProductAssortmentBaseSchemaDialog } from './cards/product/product-assortment-supplier/supplier-product-assortment.card';
import { RetailerProductAssortmentCard, RetailerProductAssortmentBaseSchemaDialog } from './cards/product/product-assortment-retailer/retailer-product-assortment.card';
import { ProductPricelistCard } from './cards/product/product-pricelist/product-pricelist.card';
import { ProductSupplierPricesCardComponent, ProductPricelistSupplierSchemeDialog } from './cards/product/product-pricelist/product-supplier-prices.card';

import { ProductClientPricesCardComponent, ProductPricelistRetailerSchemeDialog } from './cards/product/product-pricelist/product-client-prices.card';
import { UserSettingsCard } from './cards/user-settings/user-settings.card';
import { ActivateAccountCardComponent } from './cards/activate-account/activate-account.card';
import { LxmSettingsNavigatorCard } from './cards/lxm-settings/lxm-settings-navigator/lxm-settings-navigator.card';
import { CompanyProfileCardComponent } from './cards/lxm-settings/company-profile/company-profile.card';
import { PackageInfoCard } from './cards/lxm-settings/package-info/package-info.card';
import { UsersCard } from './cards/lxm-settings/users/users.card';
import { RolesCard } from './cards/lxm-settings/roles/roles.card';
import { MailSettingsCardComponent } from './cards/lxm-settings/mail-settings/mail-settings.card';

import { ProductManagementSettingsCard } from './cards/settings/product-management-settings/product-management-settings.card';
import { OfferSettingsCard } from './cards/settings/offer-settings/offer-settings.card';
import { LxmFieldSettingsCard } from './cards/settings/lxm-field-settings/lxm-field-settings.card';

import { ProductsRetailerCard } from './cards/products/retailer/products-retailer.card';
import { ProductsSupplierCard } from './cards/products/supplier/products-supplier.card';
import { ProductsRetailerExportDialogComponent } from './cards/products/retailer/export-products-retailer.dialog';

// COMPONENTS
import * as components from './components';

import { AddFileDialog } from './dialogs/add-file/add-file.dialog';
import * as clvDialogs from './components/_partials/settings/clv-table/dialogs/classificator-value.dialog';
import { UserDialog } from './components/_partials/lxm-settings/users-table/dialogs/user.dialog';
import { InviteUserDialog } from './components/_partials/lxm-settings/users-table/dialogs/invite.dialog';
import { RoleDialog } from './components/_partials/lxm-settings/roles-table/dialogs/role.dialog';
import { ConfirmationDialog } from './dialogs/confirm/confirm.dialog';
import { SegmentDialog } from './components/_partials/settings/product-segment-tree/dialog/segment.dialog';
import { MessageComponent } from './components/_layout/messages/message/message.component';

import { ProductContentLanguagesTableComponent } from './components/_partials/settings/product-content-languages/product-content-languages.component';
import { ContentLanguageDialogComponent } from './components/_partials/settings/product-content-languages/dialog/content-language.dialog';

import { AssortmentGroupsTableComponent } from './components/_partials/settings/assortment-groups/assortment-groups.component';
import { AssortmentGroupDialogComponent } from './components/_partials/settings/assortment-groups/dialog/assortment-group.dialog';

// SERVICES
import { TitleService, ProductService, PaginatorIntlService, FileService, SignalRService, ExportService, 
  SettingsService, CreateOfferDataService, ProductsSupplierSearchDataService, ProductsRetailerSearchDataService, MarketSearchDataService, OffersSearchDataService, ProductValidity,
  PurchaseOrderDataService, SalesOrderDataService
} from './_services';

// HELPERS
import { RouteListener, RouteNavigator, LxmDialog, LxmMessage, ContentLanguage, LocalStorage, NotificationBuilder } from './_helpers';

// --------------
import { AppComponent } from './app.component';
import { AppState } from './state/app.state';
import { NavigatorState } from './state/navigator.state';
import { SharedModule } from './shared.module';
import { CompanyProfileCardResolve } from './resolve/company-profile-card.resolve';
import { MailSettingsCardResolve } from './resolve/mail-settings-card.resolve';
import { OfferProductProcurementDetailDialogComponent } from './components/_partials/offer-products/dialog/offer-product-procurement-detail/offer-product-procurement-detail.dialog';

import { PackageDataResolve } from './resolve/package-data.resolve';
import { UserProfileCardResolve, ActivateAccountCardResolve } from './resolve/user-profile-card.resolve';
import { DashboardResolve } from './resolve/dashboard.resolve';
import { ProductValidationDialogComponent } from './dialogs';
import { ProductCloneDialogComponent } from './dialogs/product-clone/product-clone.dialog';
import { ProductMovieCard } from './cards/product/product-movie/product-movie.card';
import { ProductBookCard } from './cards/product/product-book/product-book.card';
import { ProductGameCard } from './cards/product/product-game/product-game.card';
import { ProductMusicCard } from './cards/product/product-music/product-music.card';
import { ProductMedicationCard } from './cards/product/product-medication/product-medication.card';
import { MarketLayout } from './views/market/market.layout';
import { MarketSearchCard } from './cards/market/market-search.card';
import { MarketProductsCard } from './cards/market/market-products.card';
import { AssortmentManagementCardComponent } from './cards/settings/assortment-management/assortment-management.card';
import { AssortmentSchemesComponent } from './components/_partials/settings/assortment-schemes/assortment-schemes.component';
import { AssortmentSchemeDialogComponent } from './components/_partials/settings/assortment-schemes/dialog/assortment-scheme.dialog';
import { ScriptService } from './_services/script.service';
import { SelectedOffersComponent } from './cards/offers/retailer/selected-offers.component';
import { AssortmentGroupTagDialog } from './components/_partials/settings/assortment-group-tags/dialog/assortment-group-tag.dialog';
import { AssortmentGroupTagsDialogComponent } from './components/_partials/settings/assortment-schemes/dialog/assortment-group-tags.dialog';
import { OfferProductMassChangesDialogComponent } from './components/_partials/offer-products/dialog/offer-product-mass-changes/offer-product-mass-changes.dialog';
import { OfferProductOrderDetailDialogComponent } from './components/_partials/offer-products/dialog/offer-product-order-detail/offer-product-order-detail.dialog';
import { OfferImportDialogComponent } from './cards/offer/dialogs/import/import-offer.dialog';
import { ProductHeaderCard } from './cards/product/product-header/product-header.card';
import { SupplierProductSearchCard } from './cards/product/product-search/supplier/product-search-supplier.card';
import { RetailerProductsSearchCard } from './cards/product/product-search/retailer/product-search-retailer.card';
import { TranslateEnumPipe } from './pipes';
import { ProductPriceChangesInfoDialogComponent } from './components/_partials/offer-products/dialog/offer-product-price-changes/offer-product-price-changes.dialog';
import { ReceivingOfferProductAssortmentDialog } from './components/_partials/offer-products/dialog/receiving-offer-product-assortment/receiving-offer-product-assortment.dialog';
import { ProductRetailerDetailsDialogComponent } from './components/_partials/offer-products/dialog/receiving-offer-product-retailer-details/receiving-offer-product-retailer-details.dialog';
import { ReceivingOfferProductSupplierPricesDialog } from './components/_partials/offer-products/dialog/receiving-offer-product-supplier-prices/receiving-offer-product-supplier-prices.dialog';
import { ProductCampaignInfoDialogComponent } from './components/_partials/offer-products/dialog/supplier-offer-product-campaign/supplier-offer-product-campaign.dialog';
import { ProductPricelistBaseSchemeDialog } from './cards/product/product-pricelist/template/base-scheme/base-scheme.dialog';
import { ProductPricelistExpirationDialog } from './cards/product/product-pricelist/product-pricelist-expiration.dialog';
import { OfferProductReplacementDialog } from './components/_partials/offer-products/dialog/offer-product-replacement/offer-product-replacement.dialog';
import { RetailerDetailsCopyDialog } from './components/_partials/offer-products/dialog/receiving-offer-product-retailer-details/copy-dialog/retailer-details-copy.dialog';

@NgModule({
  declarations: [
    // APP LAYOUTS
    GuestLayout,
    HomeLayout,

    // MAIN COMPONENTS
    AppComponent,
    LoginComponent,
    VisitorComponent,
    ActivateAccountComponent,
    AcceptTenantInviteComponent,
    ResetPasswordComponent,
    NavigatorComponent,
    components.PageLoaderComponent,
    components.NavigationSpinnerComponent,

    // CONTENT LAYOUTS
    DashboardLayout,
    ProductsLayout,
    MarketLayout,
    OffersLayout,
    OffersRouter,
    OfferLayoutComponent,
    ReportsLayout,
    SettingsLayout,
    UserSettingsLayout,
    LxmSettingsLayout,
    CompanyProfileLayout,
    PackageInfoLayout,
    UsersLayout,
    RolesLayout,
    MailSettingsLayout,

    ProductLayout,
    NotFoundComponent,

    // CARD COMPONENTS
    WelcomeCard,
    NotificationsCard, CardNotification,
    OffersSupplierCard,
    DashboardOffersCard,
    OfferCardComponent,
    ReceivingOfferCardComponent,
    BindSupplierDialogComponent,
    RequestOfferCardComponent,
    OffersRetailerCardComponent, SelectedOffersComponent,
    OfferFilesCardComponent, OfferFilesSectionComponent,
    SellerOffersSearchCardComponent,
    BuyerOffersSearchCardComponent,
    SupplierOfferImportProductDialog,
    OfferExportDialogComponent,
    OfferImportDialogComponent,
    DashboardCalendarCard, DashboardCalendarOffer,

    MarketSearchCard,
    MarketProductsCard,

    ProductsRetailerCard,
    ProductsSupplierCard,
    ProductHeaderCard,
    ProductInfoCard, SupplierProductSearchCard,
    ProductCustomCard, ProductAdditionalCard, ProductAttributesCard,
    ProductExpiryCard, ProductNutritionalCard, ProductSeafoodCard,
    RetailerProductsSearchCard,
    ProductElectronicsCardComponent,
    ProductMeatCardComponent,
    ProductHistoryCardComponent,
    ProductAlcoholCard,
    ProductPackagingCard, ProductPackagingDialog, ProductPackagingDialogBasicUnit, ProductPackagingDialogBulkPackaging,
    ProductPackagingDialogPallet, ProductPackagingDialogDisplay,
    ProductImagesCard,
    ProductImageDialogComponent,
    SupplierProductAssortmentCard, SupplierProductAssortmentBaseSchemaDialog,
    RetailerProductAssortmentCard, RetailerProductAssortmentBaseSchemaDialog,
    ProductPricelistCard, ProductPricelistBaseSchemeDialog, ProductPricelistRetailerSchemeDialog, ProductClientPricesCardComponent,
    ProductSupplierPricesCardComponent, ProductPricelistSupplierSchemeDialog,
    ProductManagementSettingsCard,
    AssortmentManagementCardComponent,
    OfferSettingsCard,
    LxmFieldSettingsCard,
    UserSettingsCard,
    ActivateAccountCardComponent,
    OfferCommentsCardComponent,
    OfferHistoryCardComponent,
    // LxmSettingsCard,
    LxmSettingsNavigatorCard, CompanyProfileCardComponent, PackageInfoCard, UsersCard, RolesCard, MailSettingsCardComponent,
    clvDialogs.ClassificatorValueDialog,
    clvDialogs.ClassificatorValueInfo,
    clvDialogs.ProductBrandInfo,
    clvDialogs.ManufacturerInfo,
    clvDialogs.ProductUnitCodeTypeFormatInfo,
    clvDialogs.LogisticsPartnerInfo,
    clvDialogs.CalendarGroupInfo,
    clvDialogs.ProductTagInfo,
    clvDialogs.AssortmentStatusInfo,
    clvDialogs.PriceGroupInfo,
    clvDialogs.ClientTagInfo,
    clvDialogs.CampaignTypeInfo,
    UserDialog,
    InviteUserDialog,
    RoleDialog,
    SegmentDialog,
    ProductContentLanguagesTableComponent,
    ContentLanguageDialogComponent,
    AssortmentGroupsTableComponent,
    AssortmentGroupDialogComponent,
    AssortmentSchemesComponent,
    AssortmentSchemeDialogComponent,
    AssortmentGroupTagsDialogComponent,
    ProductCampaignInfoDialogComponent,
    ProductPriceChangesInfoDialogComponent,
    OfferProductProcurementDetailDialogComponent,
    OfferProductOrderDetailDialogComponent,
    ProductRetailerDetailsDialogComponent,
    RetailerDetailsCopyDialog,
    ReceivingOfferProductAssortmentDialog,
    ReceivingOfferProductSupplierPricesDialog,
    OfferProductMassChangesDialogComponent,
    ProductsRetailerExportDialogComponent,
    ProductValidationDialogComponent,
    ProductCloneDialogComponent,
    ProductPricelistExpirationDialog,
    ProductMovieCard,
    ProductBookCard,
    ProductGameCard,
    ProductMusicCard,
    ProductMedicationCard,
    AssortmentGroupTagDialog,
    OfferProductReplacementDialog
  ],
  imports: [
    // ANGULAR MODULES
    BrowserModule,
    BrowserAnimationsModule,
    ChartsModule,
    SharedModule.forRoot(),
    NgxPopperModule.forRoot({}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    appRouting
  ],
  providers: [
    ScriptService,
    SettingsService,
    SignalRService,
    AppState,
    // LocaleService,
    NavigatorState,
    TitleService,
    ProductService,
    FileService,
    LxmDialog,
    LxmMessage,
    UserSettingsResolve,
    GuestSettingsResolve,
    UsersResolve, UserRolesResolve, UsersFormDataResolve, RolesFormDataResolve,
    SettingsFormDataResolve,
    SettingsProductManagementDataResolve,
    AssortmentManagementDataResolve,
    SettingsFieldDataResolve,
    ProductsSupplierSearchDataService, ProductsRetailerSearchDataService,
    MarketSearchDataService,
    OffersSearchDataService,
    ProductCardResolve,
    ProductCardFormDataResolve,
    CloneProductCardResolve,
    OfferCardResolve,
    CloneOfferCardResolve,
    OfferCardFromCalendarEntryResolve,
    OfferCardFormDataResolve,
    CreateOfferDataService,
    PurchaseOrderDataService,
    SalesOrderDataService,
    ExportService,
    ProductListFormDataResolve,
    ProductListDataResolve,
    MarketProductListDataResolve,
    ProductListCachedFilterDataResolve,
    OffersListFormDataResolve,
    RequestedOffersListFormDataResolve,
    ReceivingOffersListDataResolve,
    SendingOffersListDataResolve,
    RequestedOffersListDataResolve,
    CompanyProfileCardResolve,
    MailSettingsCardResolve,
    UserProfileCardResolve,
    ActivateAccountCardResolve,
    PackageDataResolve,
    DashboardResolve,
    ContentLanguage,
    ProductValidity,
    NotificationBuilder,
    TranslateEnumPipe,

    RouteListener, RouteNavigator,
    // {
    //   provide: APP_INITIALIZER,
    //   deps: [AppState],
    //   multi: true,
    //   useFactory: (state: AppState) => () => {
    //     return state.initializeApp();
    //   }
    // },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    {
      provide: MatPaginatorIntl,
      useFactory: (translate) => {
        const service = new PaginatorIntlService();
        service.injectTranslateService(translate);
        return service;
      },
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [

    // DIALOGS
    components.DialogComponent,
    ProductPackagingDialog,
    AddFileDialog,
    SupplierOfferImportProductDialog,
    OfferExportDialogComponent,
    OfferImportDialogComponent,
    clvDialogs.ClassificatorValueDialog,
    UserDialog,
    RoleDialog,
    ConfirmationDialog,
    SegmentDialog,
    ContentLanguageDialogComponent,
    SupplierProductAssortmentBaseSchemaDialog,
    ProductPricelistBaseSchemeDialog,
    ProductPricelistRetailerSchemeDialog,
    ProductCampaignInfoDialogComponent,
    ProductPriceChangesInfoDialogComponent,
    OfferProductProcurementDetailDialogComponent,
    OfferProductOrderDetailDialogComponent,
    ProductRetailerDetailsDialogComponent,
    RetailerDetailsCopyDialog,
    ReceivingOfferProductAssortmentDialog,
    ReceivingOfferProductSupplierPricesDialog,
    OfferProductMassChangesDialogComponent,
    ProductImageDialogComponent,
    ProductsRetailerExportDialogComponent,
    ProductValidationDialogComponent,
    ProductCloneDialogComponent,
    ProductPricelistExpirationDialog,
    AssortmentGroupTagDialog,


    // MISC COMPONENTS
    MessageComponent,
    components.TextDataTableFilterComponent,
    components.DropdownDataTableFilterComponent,
    clvDialogs.ClassificatorValueInfo,
    clvDialogs.ProductBrandInfo,
    clvDialogs.ManufacturerInfo,
    clvDialogs.ProductUnitCodeTypeFormatInfo,
    clvDialogs.LogisticsPartnerInfo,
    components.LxmTreeNodeComponent,
    components.LxmTreeHeaderComponent
  ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, appSettings.i18nPath, `.json?v-${appSettings.version}`);
}
