<form class="card-container">

  <section class="card-header">

    <div class="card-header-title-wrapper">
      <lxm-breadcrumb *ngIf="breadcrumbs"></lxm-breadcrumb>

      <div class="card-title" [class.mt25]="breadcrumbs">

        <span class="status-circle-container">
          <status-circle *ngIf="valid !== null" [status]="valid" enum="ValidType"></status-circle>
        </span>
        {{titleValue | translate}}
        <ng-content select="[header-title]"></ng-content>
      </div>
      <ng-content select="[header-title-after]"></ng-content>
    </div>

    <div class="card-header-actions-wrapper">
      <ng-content select="[header-actions]"></ng-content>
    </div>

  </section>

  <section class="card-content">
    <ng-content select="[body]"></ng-content>
    <ng-content select="[tab-group]"></ng-content>
  </section>

  <section class="card-footer">
    <ng-content select="[footer]"></ng-content>
  </section>


</form>