<table [formGroup]="fgInternal" class="full-width">
  <tbody [formArrayName]="for">

    <tr *ngFor="let field of formArray?.controls; let i = index" class="price-row" [formGroupName]="i" [class.deleted]="field.value.deleted" style="position: relative;">

      <td class="col-price-no-vat" *ngIf="pricesWithoutVat">
        <lxm-input input class="f-1" for="priceWithoutVat" [inputId]="for" [readonly]="readonly || field.value.deleted" [currency]="field.value.currency" type="price" [pricePrecision]="digits" (blur)="blur.emit($event)">
          <validation-errors [for]="for + '[' + i + '].priceWithoutVat'" [errorsFormGroup]="formGroup" aliasTo="priceWithoutVat"></validation-errors>
        </lxm-input>
      </td>

      <td class="col-price">
        <lxm-input for="price" [inputId]="for" class="f-1" [readonly]="readonly || field.value.deleted" [currency]="field.value.currency" type="price" [pricePrecision]="digits" (blur)="blur.emit($event)">
            <validation-errors [for]="for + '[' + i + '].price'" [errorsFormGroup]="formGroup" aliasTo="price"></validation-errors>
        </lxm-input>
      </td>

      <td class="col-currency">
        <lxm-select for="currency"
          class="f-1"
          style="padding: 0;"
          [clearable]="false"
          [options]="field.value.currencyOptions"
          labelField="value"
          compareField="id"
          (blur)="blur.emit($event)"
          [readonly]="isCurrencyReadonly(field, i)"
        >
          <validation-errors [for]="for + '[' + i + '].currencyId'" [errorsFormGroup]="formGroup" aliasTo="currencyId"></validation-errors>
         </lxm-select>
      </td>

      <td style="padding: 0 10px; position: absolute; left: 100%;" [class.hidden]="readonly || formArray.controls.length < 2 || i === 0">
        <lxm-button type="transparent" [icon]="field.value.deleted ? 'generate' : 'trash'" padding="8px 0" (click)="removeRow(i)"></lxm-button>
      </td>

    </tr>

    <tr *ngIf="pricesWithoutVat && formArray.controls.length > 0">
      <td>
        <label class="greyed" for="priceWithoutVat">{{'global.vat_excluded' | translate}}</label>
      </td>
      <td>
        <label class="greyed" for="price">{{'global.vat_included' | translate}}</label>
      </td>
    </tr>

    <tr class="btn-add-currency-wrapper" *ngIf="!readonly && formArray && currencyOptions && formArray.length < currencyOptions.length">
      <td width="100%">
        <lxm-button icon="plus-small" type="transparent" (click)="addRow()" padding="0">{{ 'components.currency_price.label.add_currency' | translate }}</lxm-button>
      </td>
    </tr>

  </tbody>
</table>