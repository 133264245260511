<scope>

  <card-section>
    <span header-title style="font-size: 24px; font-weight: bold;">
      {{'cards.lexi_market_settings.lxm_settings_subscription.title' | translate }}
    </span>
    <span header-actions>
      <lxm-button type="beige" class="ml15" icon="save" label="action.save" [loading]="savePackageLoading" (click)="savePackage()" *ngIf="!activeTo"></lxm-button>
      <lxm-button type="beige" class="ml15" icon="save" label="cards.lexi_market_settings.lxm_settings_subscription.action.reactivate_account" [loading]="savePackageLoading" (click)="savePackage()" *ngIf="activeTo"></lxm-button>
    </span>
    <scope body translationsPath="cards.lexi_market_settings.lxm_settings_subscription" [formGroup]="form">


      <form-field label="current_plan">
        <div any>

          Lexi.Market {{ currentPlan?.type }}
          <span *ngIf="currentAnnual">
            ({{ 'cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription' | translate }})
          </span>

        </div>
      </form-field>

      <form-field label="subscription_selection">
        <div any class="full-width">
          <div style="margin-bottom: 30px;">
            <slide-toggle for="annual" yes="cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription" no="cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription"></slide-toggle>
            <small>
              * {{'cards.lexi_market_settings.lxm_settings_subscription.label.annual_subscription_disclaimer' | translate}}
            </small>
          </div>
          <package-info *ngFor="let package of packages"
                        [data]="package"
                        [period]="form.value.annual ? 'annual' : 'monthly'"
                        [isRequested]="requestedPlanId == package.id"
                        for="activePackageId"
                        class="form-package-info">
          </package-info>
        </div>
      </form-field>

      <form-field label="subscription_usage">
        <div any class="full-width" class="row mt15">
          <div class="f-1">
            <lxm-progress-bar [progress]="productsActivePercent">
              <span bottomLeft>
                {{(productsActive || 0) + ' ' + ('global.active_products_suffix' | translate)}}
                <ng-container *ngIf="productCapacity">
                  / {{productCapacity}}
                </ng-container>
              </span>
            </lxm-progress-bar>
          </div>
          <div class="f-1" style="display: flex; align-items: center; justify-content: center;">
            <ng-container *ngIf="activeTo">
              {{ 'cards.lexi_market_settings.lxm_settings_subscription.label.account_active_to' | translate }} {{ activeTo | formDate }}
            </ng-container>
            <ng-container *ngIf="!activeTo">
              <lxm-button type="grey" label='.action.cancel_subscription' (click)="cancelSubscription()"></lxm-button>
            </ng-container>
          </div>
        </div>
      </form-field>

      <form-field>
        <div any>
          <a class="link" [href]="termsLink" target="_blank">{{'cards.lexi_market_settings.lxm_settings_subscription.label.read_terms' | translate}}</a>
        </div>
      </form-field>

    </scope>
  </card-section>

</scope>
