<div class="flex f-1" [formGroup]="formGroup" [class.readonly]="readonly">

  <div class="flex f-1 flex-column" [formArrayName]="for">
    <div class="flex f-1 flex-row align-center mb10"
          *ngFor="let field of formArray.controls; let i = index"
          [formGroupName]="i" [readonly]="readonly">

      <div class="dialog-input-fixed-width-3">
        <lxm-select for="material"
                    [searchable]="true"
                    [options]="materialOptions"
                    value="id"
                    labelField="translatedValue"
                    [clearable]="false"
                    [isTranslated]="true"
                    [sortByLabel]="true">
          <validation-errors [for]="'packageMaterials['+i+'].materialTypeId'" aliasTo="material" [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-select>

      </div>

      <div class="dialog-input-fixed-width-1 ml10">
        <combined-input for="amount_g">
          <span suffix>{{'enums.UnitShort.Gram' | translate}}</span>
          <validation-errors [for]="'packageMaterials['+i+'].amount_g'" aliasTo="amount_g" [errorsFormGroup]="formGroup"></validation-errors>
        </combined-input>
      </div>

      <lxm-checkbox 
        class="ml10" 
        for="isReusable" 
        label="cards.products.product_packaging_edit_transport_unit.label.package_material_reusable" 
        [readonly]="readonly">
      </lxm-checkbox>

      <span class="manager-actions ml10">
        <button class="transparent-button p6"
                [class.hidden]="readonly"
                (click)="removeItem(i)">
          <span class="trash-icon"></span>
        </button>
      </span>

    </div>

    <lxm-button type="transparent"
                class="new-code-button"
                padding="8px 0"
                icon="plus-dark"
                label="cards.products.product_packaging_edit_transport_unit.action.add_material"
                (click)="addItem()">
    </lxm-button>

  </div>

</div>
