<div class="retailer-info-hover-container" stop-propagation [popper]="tooltipcontent"
popperTrigger="hover"
[popperDelay]="delay"
[popperPlacement]="position"
[popperHideOnMouseLeave]="false"
[popperApplyClass]="(procurementScheme || campaignScheme) ? 'product-chain-info-popover' : null"
popperAppendTo="app-root" style="position: relative">

  <div class="flex flex-row">
    <span class="product-invalid-sm-icon" *ngIf="hasInvalidValues"></span>
    <span class="product-procurement-sm-icon" *ngIf="procurementScheme"></span>
    <span class="product-campaign-sm-icon" *ngIf="campaignScheme"> </span>
    <span class="product-price-group-sm-icon" *ngIf="hasMainPriceSchemePriceGroups || hasProcurementSchemePriceGroups || hasCampaignSchemePriceGroups"></span>
  </div>

</div>

<popper-content
  #tooltipcontent
  (click)="preventPropagation()">

  <!-- INVALID VALUES SECTION -->
  <div class="popover-section" *ngIf="hasInvalidValues" [class.mb20]="procurementScheme || campaignScheme">
    <div class="popover-section-header">
      <span class="product-invalid-icon"></span>
      <span class="popopver-section-header-text ml10"
        >
        {{
          "cards.products.product_price_info_popover.product_has_invalid_values"
            | translate
        }}
        </span>
    </div>
  </div>

  <ng-container *ngIf="procurementHasPriority && procurementScheme">
    <ng-container 
      *ngTemplateOutlet="procurement">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="campaignScheme">
    <ng-container 
      *ngTemplateOutlet="campaign">
    </ng-container>
  </ng-container>

  <ng-container 
    *ngTemplateOutlet="retailPrice">
  </ng-container>
  
  <ng-container *ngIf="!procurementHasPriority && procurementScheme">
    <ng-container 
      *ngTemplateOutlet="procurement">
    </ng-container>
  </ng-container>

</popper-content>

 <!-- CAMPAIGN SECTION -->
<ng-template #campaign>
  <div class="popover-section mb20" [class.mb20]="retailPrice || (procurementScheme && !procurementHasPriority)">
    <div class="popover-section-header">
      <span class="product-campaign-icon"></span>
      <span class="popopver-section-header-text ml10">
        {{ campaignScheme.validFrom | formDate }} -
        {{ endDate(campaignScheme.validTo) | formDate }}
      </span>
    </div>
    <div class="popover-section-content">
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.campaign_retail_price" | translate
          }}
        </span>
        <span class="campaign-price">
          {{ campaignSchemeRetailPrice | priceValue:2:campaignCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.campaign_price" | translate
          }}
        </span>
        <span class="campaign-price">
          {{ campaignPrice | priceValue:pricePrecision:campaignCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.campaign_discount"
              | translate
          }}
        </span>
        <span class="campaign-discount-percentage">
          {{ campaignDiscount | percentValue }}
        </span>
      </div>

      <div class="popover-section-row flex space-between" *ngFor="let group of campaignScheme?.priceGroups">
        <span class="mr20">
          {{ group.priceGroupName }}:
        </span>
        <span>
          {{ group.price | priceValue:2:group.currency?.value }}
        </span>
      </div>

    </div>
  </div>
</ng-template>

 <!-- RETAIL PRICE SECTION -->
 <ng-template #retailPrice>
  <div class="popover-section" [class.mb20]="procurementScheme && !procurementHasPriority">
    <div class="popover-section-header">
      <span class="product-main-price-icon"></span>
      <span class="popopver-section-header-text ml10">
        <ng-container *ngIf="mainPriceScheme">
          {{ mainPriceScheme?.validFrom | formDate }} -
          {{ mainPriceScheme?.validTo | formDate }}
        </ng-container>
        <ng-container *ngIf="!mainPriceScheme">
          {{"cards.products.product_price_info_popover.no_main_price_agreement" | translate}}
        </ng-container>
      </span>
    </div>
    <div class="popover-section-content">
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.retail_price" | translate
          }}
        </span>
        <span class="retail-price">   
          <product-scheme-display-price 
            [scheme]="{ mainPriceScheme: scheme.mainPriceScheme }"
            [pricePrecision]="2"
            [displayPriceField]="retailPriceField">
          </product-scheme-display-price>
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.retailer_price" | translate
          }}
        </span>
        <span>
          {{ mainSchemePrice | priceValue:pricePrecision:mainSchemeCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.discount"
              | translate
          }}
        </span>
        <span>
          {{ mainSchemeDiscount | percentValue }}
        </span>
      </div>

      <div class="popover-section-row flex space-between" *ngFor="let group of mainPriceScheme?.priceGroups">
        <span class="mr20">
          {{ group.priceGroupName }}:
        </span>
        <span>
          {{ group.price | priceValue:2:group.currency?.value }}
        </span>
      </div>

    </div>
  </div>
</ng-template>

<!-- PROCUREMENT SECTION -->
<ng-template #procurement>
  <div class="popover-section" [class.mb20]="procurementHasPriority">
    <div class="popover-section-header">
      <span class="product-procurement-icon"></span>
      <span class="popopver-section-header-text ml10"
        >{{procurementScheme.validFrom | formDate}} - {{procurementScheme.validTo | formDate}}</span
      >
    </div>
    <div class="popover-section-content">
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.retail_price" | translate
          }}
        </span>
        <span class="procurement-price">
          {{ procurementSchemeRetailPrice | priceValue:2:procurementCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.procurement_price"
              | translate
          }}
        </span>
        <span class="procurement-price">
          {{ procurementPrice | priceValue:pricePrecision:procurementCurrency?.value }}
        </span>
      </div>
      <div class="popover-section-row flex space-between">
        <span class="mr20">
          {{
            "cards.products.product_price_info_popover.discount"
              | translate
          }}
        </span>
        <span class="chain-info-discount-percentage">
          {{ procurementDiscount | percentValue }}
        </span>
      </div>
      
      <div class="popover-section-row flex space-between" *ngFor="let group of procurementScheme?.priceGroups">
        <span class="mr20">
          {{ group.priceGroupName }}:
        </span>
        <span>
          {{ group.price | priceValue:2:group.currency?.value }}
        </span>
      </div>

    </div>
  </div>


</ng-template>