<ng-container [formGroup]="formGroup" [ngSwitch]="readonly">

  <ng-container *ngSwitchDefault [ngSwitch]="type">

    <input *ngSwitchDefault class="lxm-input {{inputClass}}"
      placeholder="{{placeholder | translate}}"
      [type]="type"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [disabled]="disabled"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      (blur)="blur.emit($event)"
      #input />

    <input *ngSwitchCase="'price'" class="lxm-input {{inputClass}}"
      placeholder="{{placeholder | translate}}"
      [type]="type"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [disabled]="disabled"
      (blur)="blur.emit($event)"
      mask="separator.{{pricePrecision}}" 
      prefix="{{currencyPrefix}} " 
      decimalMarker=","
      #input/>


    <input *ngSwitchCase="'numeric'" class="lxm-input {{inputClass}}"
      placeholder="{{placeholder | translate}}"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [disabled]="disabled"
      [allowNegativeNumbers]="true"
      (blur)="blur.emit($event)"
      mask="separator.{{digits}}"
      decimalMarker=","
      #input/>



    <input *ngSwitchCase="'percent'" class="lxm-input {{inputClass}}"
      placeholder="{{placeholder | translate}}"
      [formControlName]="for"
      [id]="id"
      [maxlength]="maxlength"
      [autocomplete]="autocomplete"
      [pattern]="pattern"
      [disabled]="disabled"
      (blur)="blur.emit($event)"
      mask="separator.{{digits}}"
      prefix="% "
      decimalMarker=","
      [allowNegativeNumbers]="true"
      #input/>


  </ng-container>

  <ng-container *ngSwitchCase="true">
    <span class="lxm-input input-readonly full-width" [ngClass]="inputClass">
      {{inputValue}}
    </span>
  </ng-container>

  <ng-content></ng-content>

</ng-container>
