<div class="tab-content">
  <card-section>
    <span header-title>
      {{'cards.settings.assortment_management.action.assortment_schemes' | translate}}
    </span>
    <div header-actions *ngIf="canEdit">
      <div class="flex">
        <lxm-button type="beige" class="ml15" icon="plus-dark"
          label="cards.settings.assortment_management.action.add_scheme" (click)="openDialog()">
        </lxm-button>
      </div>
    </div>
  </card-section>
</div>

<div class="ml15 mb20" *ngIf="!hasSchemes">

  <!-- No schemes defined. add new scheme -->

</div>

<div *ngIf="hasSchemes" style="border-radius: 3px;">

  <div class="container" [formGroup]="form" [class.edit-tags]="editTags">
    <div class="container-2">
      <div class="sections-container">
        <div class="schemes-header">
          <div class="col--id sticky-left flex-column"
            style="border-right: 1px solid var(--dirty-purple-15); justify-content: flex-start; align-items: flex-start; z-index: 10;">
            <div class="scheme-select-wrapper">

              <div style="position: relative" [popper]="schemeslist" popperTrigger="click" popperPlacement="bottom"
                [popperPositionFixed]="true" [popperHideOnMouseLeave]="false" [popperShowOnStart]="false"
                popperAppendTo="app-root" (popperOnShown)="toggleSchemeSelection(true)" [popperPreventOverflow]="false"
                (popperOnHidden)="toggleSchemeSelection(false)">
                <div class="current-scheme">
                  <span class="current-scheme-text mr5">
                    {{(currentScheme?.validFrom | formDate) + ' - ' + (currentScheme?.validTo | formDate)}}
                  </span>
                  <toggle-arrow class="ml12" [toggled]="showSchemeSelection"></toggle-arrow>
                </div>
              </div>

              <popper-content #schemeslist class="scheme-category-wrapper" (click)="$event.stopPropagation()">
                <div class="scheme-category"
                  *ngFor="let schemeCategory of schemesCategorized | keyvalue; let i = index; trackBy: trackById">
                  <div class="scheme-category-header flex flex-row space-between"
                    (click)="toggleSchemeCategoryExpand(schemeCategory.key)">
                    <span>
                      {{schemeCategory.key}}
                    </span>
                    <toggle-arrow class="ml12" [toggled]="isSchemeCategoryExpanded(schemeCategory.key)">
                    </toggle-arrow>
                  </div>
                  <expander [trigger]="isSchemeCategoryExpanded(schemeCategory.key)">
                    <div *ngFor="let scheme of schemeCategory.value">
                      <lxm-button padding="15px 25px" justify="start"
                        [label]="(scheme.validFrom | formDate) + ' - ' + (scheme.validTo | formDate)"
                        [type]="isAssortmentSchemeSelected(scheme) ? 'beige' : 'transparent'"
                        (click)="selectScheme(scheme)">
                      </lxm-button>
                    </div>
                  </expander>

                </div>
              </popper-content>

              <div *ngIf="canEdit" class="flex flex-row">

                <ng-container *ngIf="edit">
                  <lxm-button class="ml15" type="transparent" icon="back" (click)="toggleEdit()"></lxm-button>
                  <lxm-button class="ml15" type="transparent" icon="save" [loading]="saveLoading" (click)="save()">
                  </lxm-button>
                </ng-container>

                <ng-container *ngIf="editTags">
                  <lxm-button class="ml15" type="transparent" icon="back" (click)="toggleEditTags()"></lxm-button>
                </ng-container>

                <dot-menu *ngIf="!edit && !editTags" [template]="optionsTemplate">
                </dot-menu>

                <ng-template #optionsTemplate>
                  <ul>
                    <li *ngIf="canEdit && currentScheme" (click)="openDialog(currentScheme)">
                      <lxm-button icon="edit" label="cards.settings.assortment_management.action.edit_scheme"></lxm-button>
                    </li>
                    <li *ngIf="hasSchemes" (click)="toggleEdit()">
                      <lxm-button icon="edit" label="cards.settings.assortment_management.action.edit_table"></lxm-button>
                    </li>
                    <li *ngIf="hasSchemes" (click)="toggleEditTags()">
                      <lxm-button icon="tag" label="cards.settings.assortment_management.action.add_tags"></lxm-button>
                    </li>
                  </ul>
                </ng-template>

              </div>

            </div>

            <div class="filter-wrapper">
              <lxm-select 
                class="full-width mt10" 
                for="groupFilter" 
                [options]="assortmentGroupFilterOptions" 
                label="name" 
                [searchable]="true"
                [multiple]="true" 
                placeholder="cards.settings.assortment_management.filters.assortment_group"
                (change)="filterAssortmentGroups()">
              </lxm-select>
              <lxm-select 
                class="full-width mt10" 
                for="segmentFilter" 
                [options]="segmentTreeSnapshot" 
                [labelFn]="segmentFilterLabenFn"
                [searchable]="true"
                [multiple]="true" 
                placeholder="cards.settings.assortment_management.filters.product_tree"
                (change)="filterSegments()">
              </lxm-select>
            </div>

          </div>
          <div *ngFor="let i of assortmentGroups | keyvalue: assortmentGroupsSort; trackBy: trackById" class="field-name">
            <div>
              <span [title]="i.value.name">
                {{ i.value.name }}
              </span>
              <br />
              <span style="color: #999; white-space: nowrap;">
                <ng-template [ngTemplateOutlet]="assortmentGroupNameTemplateRef"
                  [ngTemplateOutletContext]="{ assortmentGroup: i.value }">
                </ng-template>
              </span>
            </div>
          </div>
        </div>

      </div>
      <div id="tree-container">
        <div *ngFor="let l1 of segmentTree; trackBy: trackById">
          <div class="row tree-row">
            <div class="col col--id sticky-left" (click)="setOpen(l1.id)">
              <span class="item-l1">
                <span class="item-text">
                  <toggle-arrow class="ml12" [toggled]="isOpen(l1.id)" [class.hidden]="l1.children?.length < 1">
                  </toggle-arrow>
                  <span class="ml5">
                    {{ l1.code }} {{ l1.name }}
                  </span>
                </span>
                <span class="item-child-length">({{l1.children.length}})</span>
              </span>
            </div>
            <div class="col col--cb"
              *ngFor="let i of assortmentGroups | keyvalue: assortmentGroupsSort; trackBy: trackById"
              (click)="selectSegment($event, l1, i.value)" [class.marked]="isMarked(l1, i.value)">
              <div class="col-content">
                <label class="checkbox-container">
                  <input type="checkbox" [readonly]="!edit" [checked]="isChecked(l1, i.value)"
                    (click)="readonly ? null : onCheckboxChange($event, l1, i.value)" />
                  <span class="checkmark" [ngClass]="setCssClasses(l1, i.value)"></span>
                </label>
                <div style="float: left">
                  <tag 
                    *ngFor="let tag of getTags(l1, i.value)" 
                    [name]="tag.name" 
                    [color]="tag.color">
                  </tag>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="isOpen(l1.id)">

            <ng-container *ngFor="let l2 of l1.children; trackBy: trackById">
              <div class="row tree-row">
                <div class="col col--id sticky-left" (click)="setOpen(l2.id)">
                  <span class="item-l2">
                    <span class="item-text">
                      <toggle-arrow class="ml12" [toggled]="isOpen(l2.id)" [class.hidden]="l2.children?.length < 1">
                      </toggle-arrow>
                      <span class="ml5">
                        {{ l2.code }} {{ l2.name }}
                      </span>
                    </span>
                    <span class="item-child-length">({{l2.children.length}})</span>
                  </span>
                </div>
                <div class="col col--cb"
                  *ngFor="let i of assortmentGroups | keyvalue: assortmentGroupsSort; trackBy: trackById"
                  (click)="selectSegment($event, l2, i.value)" [class.marked]="isMarked(l2, i.value)">
                  <div class="col-content">
                    <label class="checkbox-container">
                      <input type="checkbox" [readonly]="!edit" [checked]="isChecked(l2, i.value)"
                        (click)="onCheckboxChange($event, l2, i.value)" />
                      <span class="checkmark" [ngClass]="setCssClasses(l2, i.value)"></span>
                    </label>
                    <div style="float: left">
                      <tag *ngFor="let tag of getTags(l2, i.value)" [name]="tag.name" [color]="tag.color">
                      </tag>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="isOpen(l2.id)">
                <ng-container *ngFor="let l3 of l2.children; trackBy: trackById">
                  <div class="row tree-row">
                    <div class="col col--id sticky-left" (click)="setOpen(l3.id)">
                      <span class="item-l3">
                        <span class="item-text">
                          <toggle-arrow class="ml12" [toggled]="isOpen(l3.id)" [class.hidden]="l3.children?.length < 1">
                          </toggle-arrow>
                          <span class="ml5">
                            {{ l3.code }} {{ l3.name }}
                          </span>
                        </span>
                        <span class="item-child-length">({{l3.children.length}})</span>
                      </span>
                    </div>
                    <div class="col col--cb"
                      *ngFor="let i of assortmentGroups | keyvalue: assortmentGroupsSort; trackBy: trackById"
                      (click)="selectSegment($event, l3, i.value)" [class.marked]="isMarked(l3, i.value)">
                      <div class="col-content">
                        <label class="checkbox-container">
                          <input type="checkbox" [readonly]="!edit" [checked]="isChecked(l3, i.value)"
                            (click)="onCheckboxChange($event, l3, i.value)" />
                          <span class="checkmark" [ngClass]="setCssClasses(l3, i.value)"></span>
                        </label>
                        <div style="float: left">
                          <tag *ngFor="let tag of getTags(l3, i.value)" [name]="tag.name" [color]="tag.color">
                          </tag>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="isOpen(l3.id)">
                    <ng-container *ngFor="let l4 of l3.children; trackBy: trackById">
                      <div class="row tree-row">
                        <div class="col col--id sticky-left" (click)="setOpen(l4.id)">
                          <span class="item-l4">
                            <span class="item-text">
                              <toggle-arrow class="ml12" class="hidden"></toggle-arrow>
                              <span class="ml5">
                                {{ l4.code }} {{ l4.name }}
                              </span>
                            </span>
                            <span></span>
                          </span>
                        </div>
                        <div class="col col--cb"
                          *ngFor="let i of assortmentGroups | keyvalue: assortmentGroupsSort; trackBy: trackById"
                          (click)="selectSegment($event, l4, i.value)" [class.marked]="isMarked(l4, i.value)">
                          <div class="col-content">
                            <label class="checkbox-container">
                              <input type="checkbox" [readonly]="!edit" [checked]="isChecked(l4, i.value)"
                                (click)="onCheckboxChange($event, l4, i.value)" />
                              <span class="checkmark" [ngClass]="setCssClasses(l4, i.value)"></span>
                            </label>
                            <div style="float: left">
                              <tag *ngFor="let tag of getTags(l4, i.value)" [name]="tag.name" [color]="tag.color">
                              </tag>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                </ng-container>
              </ng-container>

            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="selection-indicator" *ngIf="editTags || selected.length">
  <div class="flex flex-row">
    <span class="selected-count">
      {{selected.length}}
    </span>
    <div class="flex flex-column">
      <span>{{'cards.settings.assortment_management.add_tags_popup.fields_selected' | translate}}</span>
      <span style="color: var(--dirty-purple-70);">
        {{'action.press' | translate}} <span class="emphasized-key">esc</span> {{'cards.settings.assortment_management.add_tags_popup.to_remove_selection' | translate}}</span>
    </div>
  </div>
  <div>
    <lxm-button type="beige" icon="plus-dark" label="cards.settings.assortment_management.action.add_tags" (click)="openTagsDialog()"
      [disabled]="!selected.length"></lxm-button>
  </div>
</div>

<ng-template #assortmentGroupNameTemplateRef let-assortmentGroup="assortmentGroup">

  <ng-container *ngFor="let child of assortmentGroup?.children">
    <span [title]="assortmentGroups[child]?.name">
      {{ assortmentGroups[child]?.name }}
    </span>

    <ng-template [ngTemplateOutlet]="assortmentGroupNameTemplateRef"
      [ngTemplateOutletContext]="{ assortmentGroup: assortmentGroups[child] }">
    </ng-template>
  </ng-container>

</ng-template>