
<table [formGroup]="fgInternal" class="full-width" *ngIf="formArray?.controls?.length">
  <thead>
    <tr>
      <th class="col-group">
        <span [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.price_group' | translate"></span>
      </th>
      <th class="col-markup">
        <span [innerHTML]="('cards.products.product_pricelist_retailer_supplier.table.' + (markupMethod == 1 ? 'coverage' : 'margin')) | translate"></span>
      </th>
      <th class="col-price">
        <span [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.retail_price_without_vat' | translate"></span>
      </th>
      <th class="col-price">
        <span [innerHTML]="'cards.products.product_pricelist_retailer_supplier.table.retail_price' | translate"></span>
      </th>
    </tr>
  </thead>
  <tbody [formArrayName]="for">

    <ng-container *ngFor="let group of formArray?.controls; let g = index">
      <tr *ngFor="let retailPrice of group.controls?.['retailPrices']?.controls; let i = index" class="price-row" style="position: relative;">

        <td class="col-group" [formGroup]="group">
          <div class="td-content" *ngIf="i === 0" [ngSwitch]="readonly">
            <ng-container *ngSwitchCase="false">
              <lxm-select select for="priceGroup" [readonly]="readonly" labelField="value" [searchable]="true"
              (change)="onPriceGroupOptionChange($event, g)" [options]="availablePriceGroupOptions">
            </lxm-select>
            </ng-container>
            <ng-container *ngSwitchCase="true">
              {{group.get('priceGroup').value?.value}}
            </ng-container>
          </div>
          <div class="flex flex-row align-center justify-end alt-text" *ngIf="i > 0" style="height: var(--input-height); padding: var(--input-padding); padding-left: 0;">
            {{retailPrice.value.currency?.value}}
          </div>
        </td>
        <td class="col-markup" [formGroup]="retailPrice">
          <div class="td-content">
            <lxm-input input for="margin" type="percent" digits="1" class="f-1" [readonly]="readonly">
              <validation-errors for="margin"></validation-errors>
            </lxm-input>
          </div>
        </td>
        <td class="col-price" *ngIf="pricesWithoutVat" [formGroup]="retailPrice">
          <div class="td-content">
            <lxm-input input class="f-1" for="priceWithoutVat" [inputId]="for" [readonly]="readonly" [currency]="retailPrice.value.currency" type="price" [pricePrecision]="digits">
              <validation-errors [for]="'priceScheme.' + for + '[' + g + ']' + '.retailPrices' + '[' + i + '].priceWithoutVat'" [errorsFormGroup]="formGroup" aliasTo="priceWithoutVat"></validation-errors>
            </lxm-input>
          </div>
        </td>
        <td class="col-price" [formGroup]="retailPrice">
          <div class="td-content">
            <lxm-input for="price" class="f-1" [readonly]="readonly" [currency]="retailPrice.value.currency" type="price" [pricePrecision]="digits">
              <validation-errors [for]="'priceScheme.' + for + '[' + g + ']' + '.retailPrices' + '[' + i + '].price'" [errorsFormGroup]="formGroup" aliasTo="price"></validation-errors>
            </lxm-input>
          </div>
        </td>
      </tr>
    </ng-container>
      
  </tbody>
</table>

<div class="row" *ngIf="!readonly && availablePriceGroupOptions?.length">
    <lxm-button label="action.add_price_group" icon="plus-dark" (click)="addPriceGroup()" padding="0"></lxm-button>
</div>
