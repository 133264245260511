<ng-container [formGroup]="formGroup" [readonly]="!canEdit">

  <form-field dialog label="cards.settings.clv.common.table.code" for="code" type="input-text">
    <validation-errors for="code"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.clv.common.table.value" for="value" type="input-text" [required]="canEdit">
    <validation-errors for="value"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.product_management_settings.manufacturers_edit.label.legal_form" for="legalForm" type="input-text" [recommended]="true">
    <validation-errors for="legalForm"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.clv.common.table.description" for="description" type="input-text">
    <validation-errors for="description"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.clv.common.table.status">
    <lxm-select select for="status" class="select w40p"
      [formGroup]="formGroup" 
      [options]="statuses"
      value="id">
    </lxm-select>
    <validation-errors for="status"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.product_management_settings.manufacturers_edit.label.gln" for="gln" type="input-text"  [recommended]="true">
    <validation-errors for="gln"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.product_management_settings.manufacturers_edit.label.country" formGroupName="address" [recommended]="true">
    <lxm-select select
      [options]="countryOptions"
      [isTranslated]="true"
      labelField="translatedName"
      [sortByLabel]="true"
      [searchable]="true"
      for="country">
    </lxm-select>
    <validation-errors for="country"></validation-errors>
    <validation-errors for="countryId"></validation-errors>
  </form-field>

  <form-field dialog formGroupName="address" label="cards.settings.product_management_settings.manufacturers_edit.label.county" for="county" type="input-text" [recommended]="true">
    <validation-errors for="county"></validation-errors>
  </form-field>

  <form-field dialog formGroupName="address" label="cards.settings.product_management_settings.manufacturers_edit.label.city" for="city" type="input-text" [recommended]="true">
    <validation-errors for="city"></validation-errors>
  </form-field>

  <form-field dialog formGroupName="address" label="cards.settings.product_management_settings.manufacturers_edit.label.street" for="street" type="input-text" [recommended]="true">
    <validation-errors for="street"></validation-errors>
  </form-field>

  <form-field dialog formGroupName="address" label="cards.settings.product_management_settings.manufacturers_edit.label.number" for="nr" type="input-text" [recommended]="true">
    <validation-errors for="nr"></validation-errors>
  </form-field>

  <form-field dialog formGroupName="address" label="cards.settings.product_management_settings.manufacturers_edit.label.postal_code" for="postalCode" type="input-text" [recommended]="true">
    <validation-errors for="postalCode"></validation-errors>
  </form-field>
  
  <form-field dialog label="cards.settings.product_management_settings.manufacturers_edit.label.homepage" for="homepage" type="input-text">
    <validation-errors for="description"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.product_management_settings.manufacturers_edit.label.email" for="email" type="input-text">
    <validation-errors for="description"></validation-errors>
  </form-field>

  <form-field dialog label="cards.settings.product_management_settings.manufacturers_edit.label.phone_number">
    <span any row class="full-width">
        <lxm-select class="dialog-input-fixed-width-1" [searchable]="true"
            [options]="phoneCodes" labelField="value" value="id" for="phoneCode">
        </lxm-select>
        <input type="text" class="lxm-input ml10" formControlName="phoneNumberShort" />
    </span>
    <validation-errors for="phoneCodeId" aliasTo="phoneCode"></validation-errors>
    <validation-errors for="phoneNumberShort"></validation-errors>
    <validation-errors for="phoneNumber"></validation-errors>
  </form-field>
  
</ng-container>