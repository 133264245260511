<div class="flex flex-row f-1 justify-end mb10" *ngIf="showMassChangesDialogButton">
  <lxm-button label="cards.offer.mass_changes.button" type="transparent" icon="edit" (click)="openMassChangesDialog()" [disabled]="!selection?.length"></lxm-button>
</div>

<div *ngIf="showNoContentText && dataSource.data?.length === 0">
  <no-content
    image="no-products-inline"
    text="global.no_products_added"
    [inline]="true"
  ></no-content>
</div>


<div class="sticky-table" *ngIf="dataSource.data?.length > 0">
  <div class="sticky-table-body full-width overflow-x" [formGroup]="fgInternal">
    <mat-table #table
      [dataSource]="dataSource"
      multiTemplateDataRows
      style="position: relative;"
    >
      <!--<ng-container matColumnDef="offer_product_expander">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <span *ngIf="row.get('shops').value.length > 0"
              class="expanded-row-icon"
              [class.active]="expandedElements.includes(row.get('productId').value)"
              (click)="toggleExpandRow(row.get('productId').value)">
        </span>
      </mat-cell>
    </ng-container>-->

    <ng-container matColumnDef="replacement_indicator" sticky>
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <ng-container [ngSwitch]="row.value.replacementForProductId ? true : false">
          <div class="replacement-indicator-container replacement" *ngSwitchCase="true">
            <span class="replacement-indicator-horizontal"></span>
            <span class="replacement-indicator-vertical"></span>
          </div>
          <ng-container *ngSwitchCase="false">
            <div class="replacement-indicator-container replaced" *ngIf="isRowReplaced(row)">
              <span class="replacement-indicator-horizontal"></span>
              <span class="replacement-indicator-vertical">
              </span>
            </div>
          </ng-container>
        </ng-container>
      </mat-cell>
    </ng-container>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="offer_product_select" [sticky]="!readonly">
        <mat-header-cell *matHeaderCellDef [hidden]="readonly">
          <div class="flex f-1 justify-center align-center">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="isAllSelected()"
              [indeterminate]="!isAllSelected()"
            >
            </mat-checkbox>
          </div>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          stop-propagation
          (click)="$event ? toggleSelection(row) : null"
          [hidden]="readonly"
        >
          <div class="flex f-1 justify-center align-center">
            <mat-checkbox
              class="table-checkbox"
              (click)="$event.stopPropagation()"
              (change)="$event ? toggleSelection(row) : null"
              [checked]="isItemSelected(row)"
            >
            </mat-checkbox>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_image" sticky>
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <!-- <image-popover
            [image]="row.get('imageUrl').value"
            [thumbnail]="row.get('thumbnail').value"
            [thumbnailBase64]="true"
            [thumbnailContentType]="row.get('thumbnailContentType').value"
            [status]="row.get('assortmentStatus').value"
          ></image-popover> -->
          <image-popover
          [image]="row.get('imageUrl').value"
          [thumbnail]="row.get('thumbnail').value"
          [thumbnailBase64]="true"
          [thumbnailContentType]="row.get('thumbnailContentType').value"
          [statusText]="row.value.retailerAssortment?.status?.value"
          [color]="row.value.retailerAssortment?.status?.color ?? undefined"
        ></image-popover>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_name" sticky>
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="translationsPath + '.full_name' | translate"
          ></span>
          &nbsp; (<span
            [innerHTML]="translationsPath + '.brand' | translate"
          ></span
          >)
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <span class="flex flex-column">
            <span
              class="line-clamp-2"
              [class.link]="canViewProducts"
              (click)="productClick(row.get('productId').value)"
            >
              {{ row.get("name").value }}
              <ng-container *ngIf="row.get('brand').value">
                ({{ row.get("brand").value }})
              </ng-container>
            </span>
            <span class="alt-text">
              {{ row.get("ean").value }}
            </span>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_display">
        <mat-header-cell *matHeaderCellDef>
          <span [innerHTML]="translationsPath + '.display' | translate"></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <slide-toggle for="display" [readonly]="readonly"></slide-toggle>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_limit">
        <mat-header-cell *matHeaderCellDef>
          <span [innerHTML]="translationsPath + '.limit' | translate"></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="full-width">
            <lxm-input
              [readonly]="readonly"
              style="max-width: 72%;"
              for="limit"
              [id]="'limit_' + i"
              [readonly]="readonly"
            ></lxm-input>
          </div>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="offer_product_brand">
      <mat-header-cell *matHeaderCellDef>
        <span [innerHTML]="translationsPath + '.brand' | translate"></span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        {{row.get('brand').value}}
      </mat-cell>
    </ng-container> -->

      <ng-container matColumnDef="offer_product_available_from">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              translationsPath + '.offer_product_available_from' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" [ngSwitch]="isRowReplaced(row) || row.value.isSuspended">
          <div class="flex f-1 flex-row" *ngSwitchCase="true" [ngSwitch]="row.value.isSuspended">
            <ng-container *ngSwitchCase="true">
              <span class="suspend-product-icon mr8"></span>
              <span style="flex: 0; word-break: normal;">
                {{'cards.offer.products.table.text.product_suspended' | translate}}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <span class="product-replacement-out-icon mr8"></span>
              <span style="flex: 0; word-break: normal;">
                {{'cards.offer.products.table.text.product_replaced' | translate}}
              </span>
            </ng-container>
          </div>
          <div class="flex flex-row" *ngSwitchDefault>
            {{ row.value.availableFrom | formDate }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_list_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="translationsPath + '.internal_pricelist' | translate"
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="full-width triple-rows f-1">
            <div
              class="cell-row text-end new-price"
              *ngIf="row.value.validSchemeListPrice"
            >
              {{ row.value.validSchemeListPrice | priceValue }}
            </div>
            <div
              class="cell-row text-end"
              [class.old-price]="row.value.validSchemeListPrice"
            >
              {{ row.value.listPrice | priceValue }}
            </div>
            <div class="cell-row alt-text text-end">
              {{ calculateUnitPrice(row.value.listPrice, row.value) | priceValue:pricePrecision:displayCurrency?.value }}{{ calculateUnitPrice(row.value.listPrice, row.value) ? '/' + ( row.value.contentUnitId | translateEnum:'UnitShort') : null }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_buy_in_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="translationsPath + '.buy_in_price' | translate"
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="triple-rows f-1">
            <div class="cell-row">
              {{ row.value.listPrice | priceValue: 3:currency?.code }}
            </div>
            <div class="cell-row alt-text">
              {{
                calculateMargin(row.value.salePrice, row.value.listPrice)
                  | percentValue
              }}
            </div>
            <div class="cell-row bold">
              {{ row.value.salePrice | priceValue: 3:currency?.code }}
            </div>
            <div class="cell-row alt-text">
              {{ calculateUnitPrice(row.value.salePrice, row.value) | priceValue:pricePrecision:displayCurrency?.value }}{{ calculateUnitPrice(row.value.salePrice, row.value) ? '/' + ( row.value.contentUnitId | translateEnum:'UnitShort') : null }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_main_assortment_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="
              translationsPath + '.main_assortment_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="text-center">
          <div class="full-width">
            <div
              class="full-width text-end new-price"
              *ngIf="row.value.validSchemeMainAssortmentPrice"
            >
              {{ row.value.validSchemeMainAssortmentPrice | priceValue }}
            </div>
            <div
              class="full-width text-end"
              [class.old-price]="row.value.validSchemeMainAssortmentPrice"
            >
              {{ row.value.mainAssortmentPrice | priceValue }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_retailer">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="translationsPath + '.offer_price_retailer_campaign' | translate"
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
          <div class="text-end">
            {{ row.value.salePrice | priceValue }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_main_assortment_price_campaign_with_types">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="translationsPath + '.main_assortment_price_campaign_with_types' | translate"
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="flex flex-column f-1">
            <div class="text-end">
              {{ (row.get('mainAssortmentListPrice').value | priceValue:3:row.get('mainAssortmentCurrency').value?.value) || '-' }}
            </div>
            <div class="alt-text text-end">
              <div class="cell-row f-1">
                {{ calculateMargin(row.get('mainAssortmentPrice').value, row.get('mainAssortmentListPrice').value) | percentValue }}
              </div>
            </div>
            <div class="flex flex-row align-center text-end" style="flex-wrap: wrap">
              <div class="bold">
                {{ (row.get('mainAssortmentPrice').value | priceValue:3:row.get('mainAssortmentCurrency').value?.value) || '-' }}
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_retailer_campaign_with_types">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="translationsPath + '.offer_price_retailer_campaign_with_types' | translate"
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
          <div class="flex flex-column f-1">
            <div class="text-end">
              {{ (row.get('mainAssortmentListPrice').value | priceValue:3:row.get('mainAssortmentCurrency').value?.value) || '-' }}
            </div>
            <div class="alt-text text-end">
              <div class="cell-row f-1">
                {{ calculateMargin(row.get('salePrice').value, row.get('mainAssortmentPrice').value) | percentValue }}
              </div>
            </div>
            <div class="flex flex-row align-center text-end" style="flex-wrap: wrap">
              <div class="bold">
                {{ (row.get('salePrice').value | priceValue:3:row.get('mainAssortmentCurrency').value?.value) || '-' }}
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_requested_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="
              translationsPath + '.offer_product_requested_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [formGroup]="row"
          class="flex-end"
          style="overflow: visible;"
        >
          <div class="triple-rows">
            <div class="flex flex-row align-center">
              <div
                class="cell-row f-1 text-end"
                [ngSwitch]="row.value.editRequestedPrice"
              >
                <lxm-input
                  [readonly]="readonly"
                  for="requestedPriceInput"
                  type="price"
                  [currency]="currency"
                  [pricePrecision]="3"
                  (keyup.enter)="setRequestedPrice(row)"
                  *ngSwitchCase="true"
                ></lxm-input>
                <span *ngSwitchCase="false">{{
                  row.value.requestedPrice | priceValue: 3:currency?.code
                }}</span>
              </div>
              <div
                *ngIf="!readonly"
                [class.no-display]="
                  row.value.status === OfferProductStatus.Rejected
                "
              >
                <lxm-button
                  type="transparent"
                  *ngIf="!row.value.editRequestedPrice"
                  [icon]="'edit'"
                  width="34px"
                  height="34px"
                  (click)="
                    row.controls['editRequestedPrice'].setValue(
                      !row.value.editRequestedPrice
                    )
                  "
                >
                </lxm-button>
                <div
                  *ngIf="row.value.editRequestedPrice"
                  style="width: 34px; height: 34px;"
                >
                  <div style="position: absolute; display: flex; z-index: 1;">
                    <lxm-button
                      type="transparent"
                      (click)="setRequestedPrice(row)"
                      width="34px"
                      height="34px"
                      icon="accept"
                    >
                    </lxm-button>
                    <lxm-button
                      type="transparent"
                      (click)="discardRequestedPrice(row)"
                      width="34px"
                      height="34px"
                      icon="undo"
                    >
                    </lxm-button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="cell-row alt-text justify-end"
              style="min-width: 60px;padding-left: 6px; height: 0; line-height: 1em; align-items: baseline;"
              *ngIf="!row.value.editRequestedPrice"
            >
              {{
                calculateRequestedPriceMarkup(
                  row.value.retailPrice,
                  row.value.requestedPrice,
                  row.value.vat?.numericValue
                ) | percentValue
              }}
            </div>
            <div class="cell-row alt-text justify-end">
              {{ calculateUnitPrice(row.value.requestedPrice, row.value) | priceValue:pricePrecision:displayCurrency?.value }}{{ calculateUnitPrice(row.value.requestedPrice, row.value) ? '/' + ( row.value.contentUnitId | translateEnum:'UnitShort') : null }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="offer_product_requested_price_markup">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        <div class="alt-text" *ngIf="!row.value.editRequestedPrice">
          {{ calculateRequestedPriceMarkup(row.value.retailPrice, row.value.requestedPrice, row.value.vat?.numericValue) | percentValue }}
        </div>
      </mat-cell>
    </ng-container> -->

      <ng-container matColumnDef="offer_product_recommended_retail_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="
              translationsPath + '.offer_recommended_retail_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
          <div class="text-end triple-rows">
            <div class="cell-row"></div>
            <div class="cell-row text-end">
              {{ row.value.suggestedRetailPrice | priceValue: 2:currency?.code }}
            </div>
            <div class="cell-row alt-text text-end">
              <ng-container *ngIf="row.value.suggestedRetailPrice">
                {{ calculateUnitPrice(row.value.suggestedRetailPrice, row.value) | priceValue:pricePrecision:displayCurrency?.value }}{{ calculateUnitPrice(row.value.suggestedRetailPrice, row.value) ? '/' + ( row.value.contentUnitId | translateEnum:'UnitShort') : null }}
              </ng-container>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_retail_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="
              translationsPath + '.offer_product_retail_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
          <div
            class="flex flex-column f-1 text-end"
            style="min-width: 120px;max-width: 120px;"
          >
            <lxm-input
              class="f-1"
              for="retailPrice"
              type="price"
              [currency]="currency"
              [pricePrecision]="2"
              [readonly]="
                readonly || row.value.status === OfferProductStatus.Rejected
              "
            >
            </lxm-input>
            <div
              class="alt-text justify-end"
              style="height: 0; line-height: 1em; align-items: baseline;"
            >
              {{
                calculateRetailPriceMarkup(
                  row.value.retailPrice,
                  row.value.salePrice,
                  row.value.vat?.numericValue
                ) | percentValue
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_retail_price_without_vat">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width text-end"
            [innerHTML]="
              translationsPath + '.offer_product_retail_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row" class="flex-end">
          <div
            class="flex flex-column f-1 text-end"
            style="min-width: 120px;max-width: 120px;"
          >
            <lxm-input
              class="f-1"
              for="retailPriceWithoutVat"
              type="price"
              [pricePrecision]="2"
              [currency]="currency"
              [readonly]="
                readonly || row.value.status === OfferProductStatus.Rejected
              "
            ></lxm-input>
            <div
              class="alt-text justify-end"
              style="height: 0; line-height: 1em; align-items: baseline;"
            >
              {{
                calculateRetailPriceMarkup(
                  row.value.retailPriceWithoutVat,
                  row.value.salePrice,
                  row.value.vat?.numericValue
                ) | percentValue
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="offer_product_retail_price_markup">
      <mat-header-cell *matHeaderCellDef>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [formGroup]="row">
        <div class="alt-text">
          {{ calculateRetailPriceMarkup(row.value.retailPrice, row.value.salePrice, row.value.vat?.numericValue) | percentValue }}
        </div>
      </mat-cell>
    </ng-container> -->

      <ng-container matColumnDef="offer_campaign">
        <mat-header-cell *matHeaderCellDef>
          <!-- <span
            [innerHTML]="translationsPath + '.campaign_info' | translate"
          ></span> -->
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="flex f-1 justify-center">
            <lxm-button
              type="grey"
              (click)="openCampaignDialog(row)"
              [icon]="hasExistingCampaignInfo(row.value || {}) ? 'campaign-info-filled' : 'campaign-info-empty'"
              [title]="
                hasExistingCampaignInfo(row.value || {})
                  ? 'cards.offer.products.table.action.campaign_info_edit_dialog'
                  : 'cards.offer.products.table.action.campaign_info_view_dialog'"
            >
            </lxm-button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_type">
        <mat-header-cell *matHeaderCellDef>
          <span class="full-width" [innerHTML]="'cards.offer.products.table.offer_product_campaign_type' | translate">
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <lxm-select
            for="campaignType"
            [options]="campaignTypes"
            [searchable]="true"
            [sortByLabel]="true"
            [readonly]="true"
            labelField="value"
            class="full-width">
            <!-- <validation-errors [for]="'products['+(i)+'].campaignTypeId'" aliasTo="campaignType"
            [errorsFormGroup]="formGroup" [showErrorText]="false"></validation-errors> -->
          </lxm-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_recommended_retail_price_and_retail_price_discount">
        <mat-header-cell *matHeaderCellDef>
          <span class="full-width" [innerHTML]="'cards.offer.products.table.offer_product_campaign_recommended_retail_price_and_retail_price_discount' | translate">
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="flex flex-column f-1">
            <div class="text-end">
              {{ getSuggestedRetailPriceWithoutVat(row) | priceValue }}
              +{{ 'cards.offer.products.table.text.vat' | translate }}
            </div>
            <div class="flex flex-row align-center text-end" style="flex-wrap: wrap">
              {{ row.get("suggestedRetailPrice").value | priceValue }}
            </div>
            <div class="alt-text text-end">
              <div class="cell-row f-1" [ngSwitch]="getDiscountCampaignWithType(row)?.type">
                <ng-container *ngSwitchCase="'percent'">
                  -{{ getDiscountCampaignWithType(row)?.value | percentValue }}
                </ng-container>
                <ng-container *ngSwitchCase="'price'">
                  -{{ getDiscountCampaignWithType(row)?.value | priceValue }}
                </ng-container>
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_retail_price_with_vat">
        <mat-header-cell *matHeaderCellDef>
          <span class="full-width text-center"
            [innerHTML]="'cards.offer.products.table.offer_product_campaign_retail_price_with_vat' | translate">
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="flex flex-column f-1">
            <div class="text-end">
              {{ row.get("retailPriceWithoutVat").value | priceValue }}
              +{{ 'cards.offer.products.table.text.vat' | translate }}
            </div>
            <div class="text-end">
              <lxm-input for="retailPrice"
                class="f-1"
                type="price"
                [currency]="currency"
                [pricePrecision]="2"
                [readonly]="readonly || row.value.status === OfferProductStatus.Rejected"
              ></lxm-input>
            </div>
            <div class="alt-text justify-end">
              {{
                calculateRetailPriceMarkup(
                  row.value.retailPrice,
                  row.value.salePrice,
                  row.value.vat?.numericValue
                ) | percentValue
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_campaign_retail_price_info">
        <mat-header-cell *matHeaderCellDef>
          <span class="full-width text-center"
            [innerHTML]="'cards.offer.products.table.offer_product_campaign_retail_price_info' | translate">
          </span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="text-end triple-rows">
            <div class="cell-row full-width text-end">
                {{ getValidRetailerProductBuyInPriceScheme(row)?.priceWithoutVat | priceValue }}
                +{{ 'cards.offer.products.table.text.vat' | translate }}
            </div>
            <div class="cell-row full-width text-end">
              {{ getValidRetailerProductBuyInPriceScheme(row)?.price | priceValue }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_segment">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width"
            [innerHTML]="
              translationsPath + '.offer_product_segment' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="full-width">
            {{ row.get("segment").value?.name | formValue }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_storage_conditions">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width"
            [innerHTML]="
              translationsPath + '.offer_product_storage_conditions' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="full-width">
            {{ row.get("storageConditions").value | systemClvName | formValue }}
            <ng-container
              *ngIf="
                row.get('storageMinTemp').value &&
                row.get('storageMaxTemp').value
              "
            >
              , {{ row.get("storageMinTemp").value }} ...
              {{ row.get("storageMaxTemp").value }} °C
            </ng-container>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_transport_unit_ean">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width"
            [innerHTML]="
              translationsPath + '.offer_product_transport_unit_ean' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="full-width">
            {{ row.get("transportUnitDefaultEan").value | formValue }}
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_pallet_type">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width"
            [innerHTML]="
              translationsPath + '.offer_product_pallet_type' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="full-width">
            {{ row.get("palletType").value | systemClvName | formValue }}
          </div>
        </mat-cell>
      </ng-container>

      <!-- price changes -->
      <ng-container matColumnDef="offer_product_price_change_date">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              translationsPath + '.offer_product_price_change_date' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="flex flex-row align-center">
            <div class="flex-row f-1">
              <lxm-datepicker
                for="priceChangeDate"
                [readonly]="
                  readonly || row.value.status === OfferProductStatus.Rejected
                "
              ></lxm-datepicker>
            </div>
            <div
              class="flex flex-column justify-center align-center ml5"
              *ngIf="getActiveCampaignPriceScheme(row) != null"
            >
              <span
                class="campaign-icon"
                [tooltip]="campaignTemp"
                tooltipApplyClass="product-popover"
              ></span>
              <ng-template #campaignTemp>
                <div class="flex flex-column">
                  <div class="product-popover-row">
                    <type-indicator [typeId]="OfferType.Campaign">
                    </type-indicator>
                    <span class="timeframe">
                      {{
                        getActiveCampaignPriceScheme(row)?.validFrom | formDate
                      }}
                      -
                      {{
                        getActiveCampaignPriceScheme(row)?.validTo | formDate
                      }}
                    </span>
                  </div>
                  <div class="product-popover-row space-between">
                    <span class="mr5">
                      {{
                        "cards.offer.products.table.campaign_price" | translate
                      }}:
                    </span>
                    <span>
                      {{
                        getActiveCampaignPriceScheme(row)?.retailSalesPrice
                          | priceValue
                      }}
                    </span>
                  </div>
                  <div class="product-popover-row space-between">
                    <span class="mr5">
                      {{
                        "cards.offer.products.table.discount_percentage"
                          | translate
                      }}:
                    </span>
                    <span class="alt-text">
                      {{
                        calculateMargin(
                          getActiveCampaignPriceScheme(row)?.retailSalesPrice,
                          getActiveCampaignPriceScheme(row)?.publicSalesPrice
                        ) | percentValue
                      }}
                    </span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_current_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              translationsPath + '.offer_product_current_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="triple-rows f-1">
            <div class="cell-row">
              {{
                (row.get("mainAssortmentListPrice").value
                  | priceValue
                    : 3
                    : row.get("mainAssortmentCurrency").value?.code) || "-"
              }}
            </div>
            <div class="cell-row alt-text">
              {{
                calculateMargin(
                  row.get("mainAssortmentPrice").value,
                  row.get("mainAssortmentListPrice").value
                ) | percentValue
              }}
            </div>
            <div class="cell-row bold">
              {{
                (row.get("mainAssortmentPrice").value
                  | priceValue
                    : 3
                    : row.get("mainAssortmentCurrency").value?.code) || "-"
              }}
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_new_buy_in_price">
        <mat-header-cell *matHeaderCellDef>
          <span
            [innerHTML]="
              translationsPath + '.offer_product_new_buy_in_price' | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <div class="triple-rows f-1">
            <div class="cell-row">
              {{
                (row.get("listPrice").value | priceValue: 3:currency?.code) ||
                  "-"
              }}
            </div>
            <div class="cell-row alt-text">
              {{
                calculateMargin(
                  row.get("salePrice").value,
                  row.get("listPrice").value
                ) | percentValue
              }}
            </div>
            <div
              class="cell-row flex flex-row align-center"
              style="flex-wrap: wrap"
            >
              <div class="bold">
                {{
                  (row.get("salePrice").value | priceValue: 3:currency?.code) ||
                    "-"
                }}
                <ng-container
                  *ngIf="calculatePriceChange(row) != null"
                ></ng-container>
                ({{ calculatePriceChange(row) | percentValue }})
              </div>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_change_reason">
        <mat-header-cell *matHeaderCellDef>
          <span
            class="full-width"
            [innerHTML]="
              translationsPath + '.offer_product_price_change_reason'
                | translate
            "
          ></span>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [formGroup]="row">
          <lxm-select
            for="priceChangeReason"
            [options]="priceChangeReasons"
            [searchable]="true"
            [sortByLabel]="true"
            [readonly]="true"
            labelField="translatedValue"
            [isTranslated]="true"
            class="full-width"
          >
          </lxm-select>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_price_change_note">
        <mat-header-cell *matHeaderCellDef>
          <!-- <span [innerHTML]="translationsPath + '.offer_product_price_change_note' | translate"></span> -->
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="flex f-1 justify-end">
            <lxm-button
              type="grey"
              width="34px"
              height="34px"
              [icon]="getPriceChangeNoteIcon(row)"
              title="cards.offer.products.table.action.price_change_note"
              (click)="openPriceChangeDialog(row)"
            >
            </lxm-button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_product_procurement_note">
        <mat-header-cell *matHeaderCellDef>
          <!-- <span [innerHTML]="translationsPath + '.offer_product_price_change_note' | translate"></span> -->
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row">
          <div class="flex f-1 justify-end">
            <lxm-button
              (click)="openProcurementDialog(row)"
              type="grey"
              width="34px"
              height="34px"
              [icon]="getProcurementIcon(row)"
              title="cards.offer.products.table.action.procurement_note_dialog"
              [disabled]="!showProcurementDialogButton(row)"
            >
            </lxm-button>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="offer_details">
        <!-- <mat-header-cell *matHeaderCellDef [hidden]="!hasBuyerPimExtensions" [class.orderable]="canOrder"></mat-header-cell> -->
        <mat-header-cell
          *matHeaderCellDef
          [hidden]="!hasBuyerPimExtensions"
        ></mat-header-cell>
        <!-- <mat-cell *matCellDef="let row; let i = dataIndex" [formGroup]="row" [hidden]="!hasBuyerPimExtensions"  [class.orderable]="canOrder"> -->
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          [hidden]="!hasBuyerPimExtensions"
        >
          <!-- <lxm-button
            (click)="openRetailerSupplierPricesDialog(row)"
            type="grey"
            class="mr5"
            width="34px"
            height="34px"
            [icon]="getRetailerSupplierPricesIcon(row.value)"
            title="cards.offer.products.table.action.retailer_supplier_prices_dialog"
            [disabled]="!showRetailerSupplierPricesDialogButton(row)"
          >
          </lxm-button> -->

          <lxm-button
            (click)="openRetailerDetailsDialog(row)"
            type="grey"
            class="mr5"
            width="34px"
            height="34px"
            [icon]="getRetailerDetailsIcon(row.value)"
            title="cards.offer.products.table.action.retailer_details_dialog"
            [disabled]="!showRetailerDetailsDialogButton(row)">
          </lxm-button>


          <!-- (click)="openAssortmentDialog(row)" -->

          <!-- <lxm-button
            (click)="openAssortmentDialog(row)"
            type="grey"
            class="mr5"
            width="34px"
            height="34px"
            [icon]="getAssortmentSchemeIcon(row.value)"
            title="cards.offer.products.table.action.assortment_dialog"
            [disabled]="!showAssortmentDialogButton(row)"
          >
          </lxm-button> -->

          <lxm-button
            (click)="openProductAssortmentAndPricesDialog(row)"
            type="grey"
            class="mr5"
            width="34px"
            height="34px"
            [icon]="getAssortmentAndPricesIcon(row.value)"
            title="cards.offer.products.table.action.assortment_dialog"
            [disabled]="!showAssortmentDialogButton(row)"
          >
          </lxm-button>

          <!-- <lxm-button 
          *ngIf="canOrder"
          (click)="openOrderDialog(row)"
          type="grey"
          class="mr5"
          width="34px"
          height="34px"
          icon="product-order-details"
          [disabled]="!showOrderDialogButton(row)">
        </lxm-button> -->
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          [hidden]="readonly"
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let row; let i = dataIndex"
          [formGroup]="row"
          [hidden]="readonly"
        >
          <ng-container [ngSwitch]="row.value.accepted">

            <ng-container *ngSwitchCase="true">
              <lxm-button
                class="mr5"
                [disabled]="row.value.editRequestedPrice"
                width="34px"
                height="34px"
                type="dark-green"
                icon="accept-white">
              </lxm-button>
              <lxm-button
                class="mr5"
                [disabled]="row.value.editRequestedPrice"
                width="34px"
                height="34px"
                type="grey"
                icon="generate"
                (click)="acceptProduct(row)">
              </lxm-button>

            </ng-container>

            <ng-container *ngSwitchDefault [ngSwitch]="row.value.rejected">

              <ng-container *ngSwitchCase="true">
                <lxm-button
                  class="mr5"
                  [disabled]="row.value.editRequestedPrice"
                  width="34px"
                  height="34px"
                  type="salmon"
                  icon="x-white">
                </lxm-button>
                <lxm-button
                  class="mr5"
                  [disabled]="row.value.editRequestedPrice"
                  width="34px"
                  height="34px"
                  type="grey"
                  icon="generate"
                  (click)="rejectProduct(row)">
                </lxm-button>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <lxm-button
                  class="mr5"
                  [disabled]="row.value.editRequestedPrice"
                  width="34px"
                  height="34px"
                  type="grey"
                  icon="accept"
                  (click)="acceptProduct(row)">
                </lxm-button>
                <lxm-button
                  class="mr5"
                  [disabled]="row.value.editRequestedPrice"
                  width="34px"
                  height="34px"
                  type="grey"
                  icon="crossmark-lg"
                  (click)="rejectProduct(row)">
                </lxm-button>

              </ng-container>
                <!-- <lxm-button
                  class="mr5"
                  [disabled]="row.value.editRequestedPrice"
                  width="34px"
                  height="34px"
                  type="grey"
                  [icon]="
                    row.controls['rejected'].value ? 'generate' : 'crossmark-lg'
                  "
                  (click)="rejectProduct(row)">
                </lxm-button> -->


            </ng-container>

          </ng-container>
          <!-- <lxm-button
            class="mr5"
            [disabled]="row.value.editRequestedPrice"
            width="34px"
            height="34px"
            type="grey"
            [icon]="row.value.accepted ? 'accept-green' : 'accept'"
            (click)="acceptProduct(row)"
          >
          </lxm-button> -->
          <!-- <lxm-button
            class="mr5"
            [disabled]="row.value.editRequestedPrice"
            width="34px"
            height="34px"
            type="grey"
            [icon]="
              row.controls['rejected'].value ? 'generate' : 'crossmark-lg'
            "
            (click)="rejectProduct(row)"
          >
          </lxm-button> -->
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="columns"
        class="products-table-head"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: columns; let i = dataIndex;"
        class="table-row-hover"
        style="cursor:default"
        [attr.id]="row.get('productId').value"
        [ngClass]="getRowCssClass(row, i)"
        [class.expanded-row]="
          expandedElements.includes(row.get('productId').value)
        "
      >
      </mat-row>
    </mat-table>
  </div>
</div>

<div class="flex flex-column full-width" *ngIf="showTotal">
  <span class="products-table-total" *ngIf="dataSource.data.length">
    {{ translationsPath + ".total" | translate }}: {{ dataSource.data.length }}
  </span>
</div>
