import { Component, Input, Optional, Host, OnInit, SimpleChanges } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { ScopeComponent } from '../../_layout/card/card.component';
import { FormGroup, ControlContainer, FormControl } from '@angular/forms';
import { ReadonlyDirective } from 'src/app/directives';
import { FieldRequiredType } from 'src/app/enum';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormFieldComponent implements OnInit {

  public FieldRequiredType = FieldRequiredType;

  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public label: string;
  @Input() public labelTop = false;
  @Input() public labelPosition = 'left';
  @Input() public type: string;
  @Input() public required: FieldRequiredType | boolean = FieldRequiredType.None;
  @Input() public recommended = false;
  @Input() public readonly = false;
  @Input() public noLabel = false;
  @Input() public inputWidth = '100%';
  @Input() public inputClass: string;
  @Input() public maxlength: string;
  @Input() public minRows: string;

  @Input() public description: string;

  @Input() public position: string = 'auto';
  @Input() public trigger: string = 'click';

  public translationsPath: string;

  public formGroupDisabled = false;

  public labelKey: string;
  // public descriptionKey: string;

  public labelTranslated: string;
  // public descriptionTranslated: string;

  private _languageChangeSubscription: Subscription;

  public showDescription = false;

  constructor(
    @Optional() @Host() private _scope: ScopeComponent,
    @Optional() @Host() private _readonlyQuery: ReadonlyDirective,
    @Optional()  private _controlContainer: ControlContainer,
    private _translateService: TranslateService) {

  }

  public get isInput() {
    return ['input-text', 'input-password', 'input-number', 'input-tel', 'input-email'].indexOf(this.type) >= 0;
  }

  public get isTextarea() {
    return this.type === 'textarea';
  }

  public get control() {

    if (!this.formGroup || !this.for) {
      return undefined;
    }

    return this.formGroup.get(this.for) as FormControl;
  }

  public get errors() {
    if (!this.control) {
      return [];
    }
    return this.control.errors;
  }

  public get inputType() {
    return this.type.replace("input-", "");
  }

  public get isRequired() {
    return this.required === true;
  }

  public getLabelKey() {
    if (!this.label) {
      return;
    }
    if (this.translationsPath) {
      this.labelKey = this.translationsPath + '.label.' + this.label;
    } else {
      this.labelKey = this.label;
    }
    if (this.labelKey) {
      this.labelTranslated = this._translateService.instant(this.labelKey);
    }
  }

  // public getDescriptionKey() {
  //   if (!this.description) {
  //     return;
  //   }
  //   if (this.translationsPath) {
  //     this.descriptionKey = this.translationsPath + '.label_description.' + this.description;
  //   } else {
  //     this.descriptionKey = this.description ?? '';
  //   }
  //   if (this.descriptionKey) {
  //     this.descriptionTranslated = this._translateService.instant(this.descriptionKey);
  //   }
  // }

  private _getTranslations() {
    this.getLabelKey();
    // this.getDescriptionKey();
  }

  public ngOnInit(): void {
    if (this._scope) {
      this.translationsPath = this._scope.translationsPath;
    }

    if (this._readonlyQuery && this._readonlyQuery.readonly) {
      this.readonly = this._readonlyQuery.readonly;
    }

    if (this._controlContainer && this._controlContainer.control instanceof FormGroup) {
      this.formGroup = <FormGroup>this._controlContainer.control;
    }

    this._getTranslations();
    this.showDescription = this.description ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { label, description } = changes || {};
    if (label) {
      if (!label.firstChange) {
        this.getLabelKey();
      }
    }
    // if (description) {
    //   if (!description.firstChange) {
    //     this.getDescriptionKey();
    //   }
    // }
  }

  ngAfterContentInit() {
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe(x => this._getTranslations());
    // this.showDescription = this.descriptionTranslated ? true : false;
  }

  ngOnDestroy() {
    if (this._languageChangeSubscription) {
      this._languageChangeSubscription.unsubscribe();
    }
  }

}
