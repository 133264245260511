<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup">
  <span header-title>
    {{ 'cards.offer.assortment_and_prices_dialog.copy_dialog.title' | translate }}
  </span>

  <div body>

    <section class="product-section">

      <div class="product" *ngIf="targetProduct">
        <display-product [data]="targetProduct"></display-product>
      </div>
  
      <div class="product" *ngIf="hasCopyProduct">
        <display-product [data]="copyProduct"></display-product>
        <lxm-button type="grey" icon="trash" (click)="removeCopyProduct()"></lxm-button>
      </div>

    </section>

    <section class="flex flex-column mt20">
      
      <info-array prelude="cards.offer.assortment_and_prices_dialog.copy_dialog.info_text"></info-array>
      <lxm-select 
        class="mt15 ml30" 
        width="400px" 
        for="copyProductAc" 
        labelField="label" 
        [searchable]="true" 
        placeholder="orders.order.products.search_placeholder" 
        url="api/products/ac/" 
        [acLabelFormat]="productAcLabel"
        [acData]="getProductAcExcludeIds">
      </lxm-select>

      <ng-container [ngSwitch]="hasCopyProductData">
    
        <ng-container *ngSwitchCase="false">
          <card-message type="message" class="mt30" *ngIf="copyProductHasNoData">
            {{'cards.offer.assortment_and_prices_dialog.copy_dialog.message.no_assortment_classes' | translate}}
          </card-message>
        </ng-container>

        <ng-container *ngSwitchCase="true">

          <mat-table [dataSource]="dataSource" class="mt30">
            <ng-container matColumnDef="valid">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.offer.assortment_and_prices_dialog.copy_dialog.table.valid' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index">
                {{row.validFrom?.displayDate | formDate}}
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.offer.assortment_and_prices_dialog.copy_dialog.table.status' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index" [ngSwitch]="row.status ? true : false">
                <div class="flex flex-row align-center">
                  <ng-container *ngSwitchCase="true">
                    <status-circle [status]="'color-' + row.status?.color" class="mr5"></status-circle>
                    <span>{{row.status?.value}}</span>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    -
                  </ng-container>
                </div>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="assortment_class">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.offer.assortment_and_prices_dialog.copy_dialog.table.assortment_classes' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row; let i = index">
                {{joinAssortmentGroupNames(row)}}
              </mat-cell>
            </ng-container>
        
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </mat-table>

        </ng-container>
    
    </ng-container>

    </section>


  </div>

  <div footer>
    <lxm-button
      type="primary"
      label="action.save"
      (click)="save()"
      buttonType="submit"
      *ngIf="!readonly"
    ></lxm-button>
    <lxm-button
      type="secondary"
      [class.ml15]="!readonly"
      label="action.cancel"
      (click)="closeDialog()"
    ></lxm-button>
  </div>
</lxm-dialog>
