<card>
  <span header-title>{{ title }}</span>
  <section header-actions>
    <a class="card-header-action" [routerLink]="link">{{'cards.dashboard_offers.action' | translate}}</a>
  </section>
  <section body>
    <div class="card-offers-header flex f-0 flex-row space-between">
      <div class="flex f-1 flex-row align-center">
        <span class="card-offers-header-text">{{'cards.dashboard_offers.awaiting_confirmation' | translate}}</span>
        <span class="card-offers-header-value ml30">{{awaitingConfirmation}}</span>
      </div>
      <div class="flex f-1 flex-row align-center">
        <span class="card-offers-header-text">{{'cards.dashboard_offers.active_today' | translate}}</span>
        <span class="card-offers-header-value ml30">{{data?.activeCount || 0}}</span>
      </div>
    </div>
    <div class="card-offers-content flex-column mt15" [formGroup]="controls" [ngSwitch]="hasOffers">
      <ng-container *ngSwitchCase="true">
        <div class="flex flex-row space-between align-center">
          <strong style="color: #5E5357">YTD</strong>
          <lxm-select for="offerType" [options]="offerTypeOptions" labelField="name" value="id" [clearable]="false" style="width: auto; min-width: 200px;"></lxm-select>
        </div>

        <div id="chart-container" [ngSwitch]="type">
          <div id="barChartContainer" class="full-width mt25" style="min-height: 350px;" *ngSwitchCase="'out'">
            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
              [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
            </canvas>
          </div>
          <div id="pieChartContainer" class="full-width mt25" *ngSwitchCase="'in'">
            <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
              [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend">
            </canvas>
          </div>
          <span class="chart-loader" [class.hidden]="!showLoader"></span>
        </div>
      </ng-container>

      <no-content 
        text="cards.dashboard_offers.no_entries" 
        image="no-graph-offers"
        iconStyle="margin-right: -15px;"
        *ngSwitchCase="false">
      </no-content>

    </div>
  </section>
</card>