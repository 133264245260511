import { Component, Output, EventEmitter, OnInit, Input, AfterViewInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { DataTableComponent } from 'src/app/components';
import { DataTableService } from 'src/app/_services';
import { Observable } from 'rxjs';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS, LOCAL_STORAGE_KEY } from 'src/app/config';
import { LocalStorage } from 'src/app/_helpers';
import { KeyCode, OfferKind } from 'src/app/enum';
import { IOffersListState, OffersListViewType } from './offers-list.state';
import { OfferType } from '../../enum';

@Component({
  template: ''
}) // Necessary for proper injection
export abstract class OffersListBase<T extends { id: string, status: number }, TData, TState extends IOffersListState>
  extends DataTableComponent<T, TData> implements OnInit, AfterViewInit {

  public LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY.PAGE_SIZE_OFFERS_LIST;

  public OffersListViewType = OffersListViewType;

  @Input()
  public kind: OfferKind;

  @Output()
  public loaded: EventEmitter<boolean> = new EventEmitter();

  public selection: any[] = [];
  public currentPageSelected: string[] = [];

  private _activeRowsCount = 0;

  @Input()
  public search: Observable<any>;
  public searchData: any;

  public searchUrl = '/offers/search';

  protected _state: TState;

  constructor(
    private _location: Location,
    _dataService: DataTableService<T, TData>,
    _cd: ChangeDetectorRef
  ) {
    super(_dataService, _cd);
  }

  public isAllSelected() {
    return this.currentPageSelected.length === this._activeRowsCount;
  }

  public  isAcceptedSelectionType(typeId) {
    if (!typeId || typeId === OfferType.Procurement) {  // Temporarily forbid procurement exports until api support is available
      return false;
    }
    if (!this.selection.length) {
      return 'all';
    }
    return this.selection[0].type?.id === typeId;;
  }

  public masterToggle() {
    if(this.isAllSelected()) {
      this.dataSource.data.forEach(row => {
          const index = this._getSelectionOfferIndex(row);
          if (index > -1) {
            this.selection.splice(index, 1);
          }
      })
    } else {
      this.dataSource.data.forEach((row: any) => {
        const typeId = row.type?.id;
        if (this.isAcceptedSelectionType(typeId) && !this.isOfferSelected(row)) {
          this.selection.push(row);
        }
      });
    }
    this._updateCurrentPageSelection();
  }

  protected _updateCurrentPageSelection(data = this.dataSource.data) {
    this.currentPageSelected = [];
    data?.forEach(row => {
      this.isOfferSelected(row) ? this.currentPageSelected.push(row.id) : null;
    });
    this._activeRowsCount = data ? data.filter((row: any) => this.isAcceptedSelectionType(row.type?.id)).length : 0;
  }

  public ngOnInit() {
    super.ngOnInit();

    this.setListFilter();

    this.dataSource.data$.subscribe(x => {
      this._updateCurrentPageSelection(x);
    });

    this.loadAfterViewInit = this.search === null;

    // this.dataSource.searchKey$.subscribe(x => {
    //   if (x) {
    //     this._location.replaceState(`${this.searchUrl}/${x}`);
    //   }
    // });

    this.dataSource.loading$.subscribe((val: boolean) => {
      this._state.isSearchLoading = val;
    })
  }

  public get isLoading() {
    return this._state.isSearchLoading;
  }

  public onSearch(searchData) {
    this.searchData = searchData;
  }

  public onLoad() {
    this.load();
  }

  public get hasOffers() {
    return this.dataSource?.data?.length > 0 ? true : false;
  }

  protected getData() {
    return this.searchData;
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(e) {
    if (this.isLoading) return;
    if (e.ctrlKey) {
      if (e.keyCode === KeyCode.LEFT) {
        e.preventDefault();
        this.paginator.previousPage();
      }
      if (e.keyCode === KeyCode.RIGHT) {
        e.preventDefault();
        this.paginator.nextPage();
      }
    }
  }

  public get listViewType(): OffersListViewType {
    return this._state.listViewType;
  }

  protected _getSelectionOfferIndex(row): number {
    return this.selection.findIndex(x => x.id === row.id);
  }

  protected _getCurrentPageOfferIndex(row): number {
    return this.currentPageSelected.findIndex(x => x === row.id);
  }

  public isOfferSelected(row): boolean {
    return this.selection.find(x => x.id === row.id) ? true : false;
  }

  public toggleSelection(row): void {
    if (!this.isAcceptedSelectionType(row.type?.id)) {
      return;
    }

    const index = this._getSelectionOfferIndex(row);
    if (index < 0) {
      this.selection.push(row);
      this.currentPageSelected.push(row.id);
    } else {
      this.selection.splice(index, 1);
      const i = this._getCurrentPageOfferIndex(row);
      if (i > -1) {
        this.currentPageSelected.splice(i, 1);
      }
    }
  }

  public emptySelectedOffers() {
    this.selection = [];
    this.currentPageSelected = [];
  }

  public isListViewActive(view: OffersListViewType) {
    return this._state.listViewType == view;
  }

  public setListView(toSet: OffersListViewType) {
    if (this.isLoading || toSet === this.listViewType) return;

    this.setListFilter(toSet);

    this.resetPaginator();

    this._state.setListViewType(toSet, this.kind);
    
    this.load();
  }
  
  public setListFilter(viewType: OffersListViewType = this.listViewType) {
    const filter = this._state.getListFilter(viewType);
    this.customFilter = filter;
    this._state.listFilter = filter;
  }

}

