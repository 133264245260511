import { Component, Input, ChangeDetectorRef, OnInit, Injector } from '@angular/core';
import { formatNumber } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { forkJoin, Subscription, merge, Observable } from 'rxjs';
import { ProductPricelistService, AuthenticationService, ProductValidity } from 'src/app/_services';
import { LxmDialog } from 'src/app/_helpers/dialogs';
import { calculateMargin, calculateDiscount, calculateDiscountNumber, nextSchemeDay } from 'src/app/_helpers/calculate';
import { IPricesInfo, ISupplierBasePriceScheme, ISupplierRetailerPriceScheme, IRetailPriceSchemeFormData } from './product-pricelist.model';
import { ActivatedRoute } from '@angular/router';
import { IClvDto, IProductCard, IProductCardFormData, IRetailChain } from '../product-info/product-info.model';
import { Bool, UserAction, ProductCardSection, asArray, ProductPriceSchemeType, asObject } from 'src/app/enum';
import { numberUtil } from 'src/app/util/number-util';
import moment from 'moment-timezone';
import { locale } from 'src/app/_services';
import 'src/app/ext/date';
import { AppState } from 'src/app/state/app.state';
import { appSettings } from 'src/app/app.settings';
import { IDialogDataBase, ProductPricelistDialogBase } from './product-pricelist-dialog-base';
import { LxmMessage } from 'src/app/_helpers';
import { ProductCardBase } from '../product-card-base';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ProductPricelistExpirationDialog } from './product-pricelist-expiration.dialog';
import { ProductHeaderState } from '../product-header/product-header.state';

@Component({
  selector: "product-client-prices-card",
  templateUrl: './product-client-prices.card.html',
  styleUrls: ['./product-pricelist.card.scss']
})
export class ProductClientPricesCardComponent extends ProductCardBase<IPricesInfo> {

  public title = 'cards.products.product_pricelist.title_clientprices';

  public UserAction = UserAction;
  public Bool = Bool;

  public formData: IProductCardFormData;
  public pricesInfo: IPricesInfo;
  public retailersWithPrices = [];

  public cardSection = ProductCardSection.PriceSchemes;

  public tableSupplier = {
      columns: [
      'list_user',
      'list_valid',
      'list_scheme_type',
      'list_publicSalesPrice',
      'list_discount',
      'list_retailSalesPrice',
      'list_margin',
      'list_approved',
      'list_actions'
    ]
  };

  @Input()
  public productId: string;

  public expanded = true;

  public cardValid = true;
  public canEdit: boolean;

  private _loadSupplierPricesSubscription: Subscription;
  private _loadRetailPricesSubscription: Subscription;

  public productCard: IProductCard;

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_CLIENT_PRICES_CARD_OPEN;

  constructor(
    injector: Injector,
    private _appState: AppState,
    private _cd: ChangeDetectorRef,
    private _dialog: LxmDialog,
    private _productPricelistService: ProductPricelistService,
    private _route: ActivatedRoute,
    private _authService: AuthenticationService,
    public productValidity: ProductValidity,
    public productState: ProductHeaderState,
    private _msg: LxmMessage
  ) {
    super(injector, 'productClientPrices', 'productClientPricesChanged');

    this.productCard = _route.snapshot.data.productCard as IProductCard;
  }

  public get table() {
    return this.tableSupplier;
  }

  public get retailPrices() {
    return this.pricesInfo?.supplierRetailerPrices;
  }

  public getRetailersWithPrices() {
    this.retailersWithPrices = this.pricesInfo?.supplierRetailerPrices
      ? Object.keys(this.pricesInfo?.supplierRetailerPrices)
        .map(x => ({ id: x, name: this.retailChains[x].name }))
        .sort((a, b) => a.name > b.name ? 1 : -1)
      : [];
  }

  public retailChains: { [key: string]: IRetailChain } = {};

  public ngOnInit() {
    super.ngOnInit();
    this.canEdit = this._authService.hasRight([UserAction.ManagePrices]);
    this.pricesInfo = this._route.snapshot.data.productCard.pricesInfo as IPricesInfo;
    this.formData = this._route.snapshot.data.productCard.formData as IProductCardFormData;
    this.retailChains = this.formData.retailChains?.reduce((seed, x) => ({...seed, [x.id]: x}), {});
    this.getRetailersWithPrices();
  }

  public calculateSupplierDiscount(row: ISupplierBasePriceScheme) {
    return calculateDiscount(row.manufacturerSalesPrice, row.buyInPrice);
  }

  public calculateRetailDiscount(row: ISupplierRetailerPriceScheme) {
    return calculateDiscount(row.publicSalesPrice, row.retailSalesPrice);
  }

  public calculateMargin(row: ISupplierRetailerPriceScheme) {
    const retailerCountryId = row.retailChain?.country?.id;
    const date = row.validFrom.displayDate;
    const baseScheme = this.pricesInfo.supplierBasePrices
      ?.find(x => x.country.id === retailerCountryId
        && x.validFrom.displayDate <= date
        && (!x.validTo || x.validTo.displayDate >= date));

    let buyInPrice = baseScheme?.buyInPrice;

    if (baseScheme) {
      if (row.publicSalesPriceCurrency?.id && row.publicSalesPriceCurrency.id != this._appState.defaultCurrency.id) {

        var p1 = baseScheme.publicSalesPrices.find(x => x.currency.id == this._appState.defaultCurrency.id)?.price;
        var p2 = baseScheme.publicSalesPrices.find(x => x.currency.id == row.publicSalesPriceCurrency?.id)?.price;

        var rate = p2 / p1;
        buyInPrice = buyInPrice*rate;
      }
    }

    return calculateMargin(row.retailSalesPrice, buyInPrice);
  }

  public loadSupplierPrices(): void {
    if (this._loadSupplierPricesSubscription) {
      this._loadSupplierPricesSubscription.unsubscribe();
    }

    this._loadSupplierPricesSubscription = this._productPricelistService
      .getSupplierPricesGridData(this.productId)
      .subscribe(result => {
        this.pricesInfo.supplierBasePrices = result;
      }, err => {
        console.error(err);
        this._msg.error({
          message: 'cards.products.product_pricelist.table.load_failed'
        })
      });
  }

  public loadRetailPrices(retailChainId: string): void {
    if (this._loadRetailPricesSubscription) {
      this._loadRetailPricesSubscription.unsubscribe();
    }

    this._loadRetailPricesSubscription = this._productPricelistService
      .getRetailPricesGridData(this.productId, retailChainId)
      .subscribe(result => {
        this.pricesInfo.supplierRetailerPrices[retailChainId] = result;
        this.getRetailersWithPrices();
        this._cd.markForCheck();
      }, err => {
        console.error(err);
        this._msg.error({
          message: 'cards.products.product_pricelist.table.load_failed'
        })
      });
  }

  public openRetailerSchemeDialog(row?: ISupplierRetailerPriceScheme, retailChain?: IRetailChain) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(ProductPricelistRetailerSchemeDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        pricelistCard: this,
        retailChain: retailChain,
        scheme: row ? { ...row } : null,
        editable: this.canEdit
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._productPricelistService.getRetailFormData(row?.id)
        };
        return forkJoin(resolvers);
      }
    });
  }

  public openPricelistExpirationDialog(row?: ISupplierRetailerPriceScheme) {
    if (!this.canEdit) {
      return;
    }

    this._dialog.open(ProductPricelistExpirationDialog, {
      ...appSettings.DIALOG_SIZES.M,
      data: {
        pricelistCard: this,
        scheme: row ? { ...row } : null,
        editable: this.canEdit,
        product: this.productState.displayProductData
      }
    }, res => {
      if (res) {
        row.validTo = {
          editableDate: res,
          displayDate: moment(res).toDate()
        }
      }
    });
  }
}

@Component({
  selector: "product-pricelist-retailer-scheme-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './template/retailer-scheme.dialog.html'
})
export class ProductPricelistRetailerSchemeDialog extends ProductPricelistDialogBase<IRetailDialogData, IRetailPriceSchemeFormData, ISupplierRetailerPriceScheme> {

  public saveLoading = false;
  public currencies: IClvDto[] = [];

  public ProductPriceSchemeType = ProductPriceSchemeType;
  
  public schemeTypeOptions = [
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Main),
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Campaign),
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Procurement)
  ];

  private _basePriceScheme: ISupplierBasePriceScheme;
  private _clientPriceScheme: ISupplierRetailerPriceScheme;

  public override get nextDay() {

    if (this._clientPriceScheme?.id == this.schemeId) {
      return nextSchemeDay();  
    }

    const minDate = this._clientPriceScheme?.validFrom.displayDate;
    return nextSchemeDay(minDate);
  }

  protected createForm(): FormGroup {

    var clientControl = new FormControl();
    var currencyControl = new FormControl();
    var schemeTypeControl = new FormControl();
    var validFromControl = new FormControl();

    validFromControl.valueChanges.subscribe(date => {
      this._updateClientPriceScheme(clientControl.value?.id, date);
    });

    clientControl.valueChanges.subscribe(client => {
      this._updateClientPriceScheme(client?.id, validFromControl.value);

      if (client && client.acceptedCurrencies) {
        this.currencies = this.data.formData.currencies.filter(x => client.acceptedCurrencies.indexOf(x.value) >= 0);
      }
      else {
        this.currencies = this.data.formData.currencies;
      }

      let defaultCurrency = this._appState.defaultCurrency.value;
      if (client && client.defaultCurrency) {
        defaultCurrency = client.defaultCurrency;
      }
      if (this._clientPriceScheme) {
        defaultCurrency = this._clientPriceScheme.publicSalesPriceCurrency.value;
      }

      var currencyValue = this.data.formData.currencies.find(x => x.value == defaultCurrency);
      currencyControl.setValue(currencyValue);

      if (!this._initialPatch && this.form.value.discount !== true) {
        this._updateDiscountPercentFromRules();
      }

      validFromControl.setValue(this.nextDay);
    });

    schemeTypeControl.valueChanges.subscribe((schemeType: ProductPriceSchemeType) => {

      if(this._initialPatch) {
        return;
      }

      if (schemeType === ProductPriceSchemeType.Campaign || schemeType == ProductPriceSchemeType.Procurement) {
        this.form.get("discount").setValue(null);

        if (this.form.value.retailChain?.id && this.data.pricelistCard.pricesInfo.supplierRetailerPrices) {

          const date = moment.parseZone(this.form.get('validFrom').value).tz(this._timeZone, true);
          var scheme = this.data.pricelistCard.pricesInfo.supplierRetailerPrices[this.form.value.retailChain?.id]
            ?.find(x => moment.parseZone(x.validFrom.displayDate) <= date && (!x.validTo || moment.parseZone(x.validTo.displayDate) >= date));

          if (scheme) {
            this.form.get('publicSalesPrice').setValue(scheme.publicSalesPrice);
          }
        }
      }

      if (schemeType === ProductPriceSchemeType.Main) {
        this._getCountryPrices();
        this._updateDiscountPercentFromRules();
      }

    });

    return new FormGroup({
      validFrom: validFromControl,
      validTo: new FormControl(),
      schemeType: schemeTypeControl,
      retailSalesPrice: new FormControl(),
      publicSalesPrice: new FormControl(),
      currency: currencyControl,
      discount: new FormControl(),
      retailChain: clientControl,
    });
  }

  protected onLoad(): void {
    super.onLoad();
    this.currencies = this.data.formData.currencies;
  }

  public get schemeId() {
    return this.data?.scheme?.id;
  }

  public get scheme() {
    return this.data?.scheme;
  }

  public get editable() {
    return this.data?.editable;
  }

  public save(): void {
    this.saveLoading = true;
    const f = this.form.value;
    const req = {
      productId: this.data.pricelistCard?.productId,
      chainId: f.retailChain?.id,
      validFrom: f.validFrom,
      validTo: f.validTo,
      publicSalesPrice: f.publicSalesPrice,
      publicSalesPriceCurrencyId: f.currency?.id,
      retailSalesPrice: f.retailSalesPrice,
      schemeType: f.schemeType
    };

    this._productPricelistService
      .saveRetailPrice(this.schemeId, req)
      .result(this.form, () => {
        this.data.pricelistCard.loadRetailPrices(f.retailChain?.id);
        this.closeDialog();
        this.saveLoading = false;
      }, err => {
        this.saveLoading = false;
      });

  }

  public deleteScheme(): void {
    this.deleteSchemeInternal(
      id => this._productPricelistService.deleteRetailPrice(id),
      () => this.data.pricelistCard.loadRetailPrices(this.data.scheme.retailChain?.id)
    );
  }

  protected normalizeData(scheme: ISupplierRetailerPriceScheme): any {
    const result = <any>scheme || {};
    result.validFrom = scheme?.validFrom.editableDate || this.nextDay;
    result.validTo = scheme?.validTo?.editableDate;
    result.discount = calculateDiscountNumber(scheme?.publicSalesPrice, scheme?.retailSalesPrice);
    result.retailChain = this.retailChains.find(x => x.id == scheme?.retailChain?.id);
    return result;
  }

  protected bindCalculations(): void {
    this._bindCountryPrices();
    this._bindPublicSalesPrice();
    this._bindRetailPrice();
  }

  public get retailerCountryId() {
    return this.data.retailChain?.country?.id;
  }

  public get margin() {

    let buyInPrice = this._basePriceScheme?.buyInPrice;

    const currency = this.form.value.currency;
    if (currency?.id && currency.id != this._appState.defaultCurrency.id) {

      if (this._basePriceScheme) {
        var p1 = this._basePriceScheme.publicSalesPrices.find(x => x.currency.id == this._appState.defaultCurrency.id)?.price;
        var p2 = this._basePriceScheme.publicSalesPrices.find(x => x.currency.id == currency.id)?.price;
        var rate = p2 / p1;
        buyInPrice = buyInPrice*rate;
      }
      else {
        buyInPrice = null;
      }
    }

    return calculateMargin(
      this.form.get('retailSalesPrice').value,
      buyInPrice);
  }

  private _bindCountryPrices(): void {
    this._updateBasePriceScheme();
    // this._updateClientPriceScheme();
    merge(
      this.form.get('retailChain').valueChanges,
      this.form.get('currency').valueChanges,
      this.form.get('validFrom').valueChanges
    ).subscribe(val => {
        this._updateBasePriceScheme();
        // this._updateClientPriceScheme();
        this._getCountryPrices();
      });
  }

  private _updateDiscountPercentFromRules() {
    const clientId = this.form.value.retailChain?.id;
    if (clientId) {
      this._productPricelistService.getDefaultDiscountPercent(this.data.pricelistCard?.productId, clientId)
        .subscribe(x => {
          this.form.get("discount").setValue(x);
        });
    }
  }

  private _updateBasePriceScheme() {
    const retailerCountryId = this.form.get('retailChain').value?.country?.id;
    const date = moment.parseZone(this.form.get('validFrom').value).tz(this._timeZone, true);
    let existingBasePrice = null;
    if (retailerCountryId && date) {
      existingBasePrice = this.data.pricelistCard.pricesInfo.supplierBasePrices
      ?.find(x => x.country.id === retailerCountryId
        && moment.parseZone(x.validFrom.displayDate) <= date
        && (!x.validTo || moment.parseZone(x.validTo.displayDate) >= date));
    }
    this._basePriceScheme = existingBasePrice;
  }

  private _updateClientPriceScheme(clientId: string, dateValue: string) {
    const date = moment.parseZone(dateValue).tz(this._timeZone, true);
    this._clientPriceScheme = this.data.pricelistCard.pricesInfo.supplierRetailerPrices[clientId]
      ?.find(x => moment.parseZone(x.validFrom.displayDate) <= date && (!x.validTo || moment.parseZone(x.validTo.displayDate) >= date));
  }

  private _getCountryPrices() {
    const currency = this.form.get('currency').value;
    let publicSalesPrice = null;
    if (this._basePriceScheme) {
      publicSalesPrice = this._basePriceScheme.publicSalesPrices.find(x => x.currency.id == currency.id)?.price;
    }

    this.form.patchValue({
      publicSalesPrice: publicSalesPrice
    });
  }

  private _bindPublicSalesPrice(): void {
    merge(
      this.form.get('publicSalesPrice').valueChanges,
      this.form.get('discount').valueChanges,
    )
      .subscribe(() => {
        const publicSalesPrice = numberUtil.toNumber(this.form.get('publicSalesPrice').value);
        const discountPercent = numberUtil.toNumber(this.form.get('discount').value);

        if (!numberUtil.isNumber(publicSalesPrice) || !numberUtil.isNumber(discountPercent)) {
          return;
        }

        this.form.patchValue({
          retailSalesPrice: formatNumber(publicSalesPrice * (1 - discountPercent / 100), locale, '1.0-3')
        }, {
          emitEvent: false
        });
      });
  }

  private _bindRetailPrice(): void {
    this.form.get('retailSalesPrice').valueChanges
      .subscribe(val => {
        val = numberUtil.toNumber(val);
        const publicSalesPrice = numberUtil.toNumber(this.form.get('publicSalesPrice').value);

        if (!numberUtil.isNumber(val) || !numberUtil.isNumber(publicSalesPrice) || !publicSalesPrice) {
          return;
        }

        this.form.patchValue({
          discount: calculateDiscountNumber(publicSalesPrice, val)
        }, {
          emitEvent: false
        });
      });
  }
}

interface IRetailDialogData extends IDialogDataBase<IRetailPriceSchemeFormData, ISupplierRetailerPriceScheme> {
  retailChain: IRetailChain;
  editable: boolean;
}
