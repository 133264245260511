<div class="card-calendar-offer-container">
  <div class="flex f-1 flex-column ml5">
    <div class="calendar-offer-group">
      <span>{{data.name | translatedValue}}</span>
    </div>
    <div class="calendar-offer-name">
      <span>{{data.group?.value}}</span>
    </div>
    <div class="calendar-offer-date-container">
      <type-indicator [type]="offerType"></type-indicator>
      <span class="calendar-offer-date">{{data.validFrom | formDate}} - {{data.validTo | formDate}}</span>
    </div>
  </div>
  <div class="flex flex-column align-end">
    <time-diff [date]="data.offerDueDate"></time-diff>
    <span class="calendar-offer-retailer">
      {{data.retailer.name}}
    </span>
  </div>
</div>
