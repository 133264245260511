<card [alignSections]="true">
  <span header-title>{{'cards.dashboard_market.title' | translate}}</span>
  <section header-actions>
    <a class="card-header-action" routerLink="/calendar">{{'cards.dashboard_market.action' | translate}}</a>
  </section>
  <section body [ngSwitch]="hasEntries">
    <div class="flex-column" *ngSwitchCase="true">
      <dashboard-calendar-offer 
        class="table-row-hover" 
        *ngFor="let entry of data" 
        routerLink="/calendar"
        [queryParams]="{startDate: date.toQueryDate(entry.validFrom), entryId: entry.id}"
        [data]="entry">
      </dashboard-calendar-offer>
    </div>

    <no-content 
      text="cards.dashboard_market.no_entries" 
      image="no-calendar-entries"
      *ngSwitchCase="false">
    </no-content>

  </section>
</card>
