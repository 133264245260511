<form class="card-container" [class.expanded]="expanded">

  <section class="card-header">
    <div class="card-header-title-wrapper">
      <div class="expansion-card-toggle" (click)="toggleExpand()">
        <div class="card-title">
          <span class="status-circle-container">
            <status-circle *ngIf="valid !== null" [status]="valid" enum="ValidType"></status-circle>
          </span>
          <ng-content select="[header-title]"></ng-content>
          <toggle-arrow class="ml12" [toggled]="expanded"></toggle-arrow>
          <!-- <span class="expansion-toggle-arrow" [class.expanded]="expanded"></span> -->
        </div>
      </div>
    </div>
    <span class="card-header-actions-wrapper">
      <ng-content select="[header-actions]" *ngIf="expanded"></ng-content>
    </span>
  </section>

  <expander class="card-content" [trigger]="expanded">
    <ng-content select="[body]"></ng-content>
  </expander>

  <section class="card-footer">
    <ng-content select="[footer]"></ng-content>
  </section>

</form>
