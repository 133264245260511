<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup">
  <!-- <span header-title>{{'cards.products.sync_products_dialog.title' | translate}}</span> -->

  <div *ngIf="dialogImage" header-image class="{{dialogImage}}"></div>

  <!-- <div body>
      <div>{{data.message | translate}}</div>
      <div *ngIf="data.template"
            [innerHTML]="data.template">
      </div>
      <ng-container *ngTemplateOutlet="templateRef">
      </ng-container>
    </div> -->

  <div body>

    <p class="dialog-title mt5">{{'cards.products.sync_products_dialog.title' | translate}}</p>
    <p [innerHTML]="'cards.products.sync_products_dialog.description' | translate">
    </p>

    <div class="flex flex-column mt20">
      <form-field dialog label="cards.products.sync_products_dialog.label.suppliers">
        <lxm-select select for="suppliers" value="id" labelField="name" [options]="suppliersOptions" [clearable]="true"
          [searchable]="true" [multiple]="true">
        </lxm-select>
      </form-field>
    </div>

  </div>

  <section footer>
    <lxm-button type="primary" label="action.sync" (click)="syncProducts()" [loading]="isLoading"></lxm-button>
    <lxm-button type="secondary" class="ml10" label="action.cancel" (click)="closeDialog()"></lxm-button>
  </section>

</lxm-dialog>