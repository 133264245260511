<div *ngIf="showInput" class="pac-card" [class.hidden]="hideInput" id="pac-card" [formGroup]="formGroup">
    <div id="pac-container">
        <lxm-gmap-input inputId="pac-input" class="full-width" placeholder="components.google_maps.location_search_placeholder" [readonly]="readonly" for="gmap_address_text" googlePlaceFor="gmap_address"></lxm-gmap-input>
    </div>
</div>
<div id="map"></div>
<div id="infowindow-content">
    <span id="place-name" class="title"></span><br />
    <span id="place-address"></span>
</div>
