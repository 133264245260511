<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup" #dialog>
    <span header-title [ngSwitch]="isNew">
      <div class="flex flex-column" *ngSwitchCase="true">
        {{'cards.settings.product_management_settings.segments_tree.action.add_child_segment' | translate}}
        <span class="open-segment" *ngIf="isNew && data.parentSegment?.name">
          {{data.parentSegment.code}} {{data.parentSegment.name}}
        </span>
      </div>
      <div class="flex flex-column" *ngSwitchCase="false">
        {{'cards.settings.product_management_settings.segments_tree_edit.title' | translate}}
        <span class="open-segment">
          {{formGroup.get('code').value}} {{formGroup.get('name').value}}
        </span>
      </div>
    </span>

    <div body style="padding-bottom: 40px;">
        <scope translationsPath="cards.settings.product_management_settings.segments_tree_edit">

            <form-field dialog label="code" type="input-text" for="code" [required]="true">
                <validation-errors for="code"></validation-errors>
            </form-field>

            <form-field dialog label="name" type="input-text" for="name" [required]="true">
                <validation-errors for="name"></validation-errors>
              </form-field>

            <form-field dialog label="description" type="input-text" for="description">
                <validation-errors for="description"></validation-errors>
              </form-field>

            <form-field dialog label="status">
                <lxm-select select for="status" class="full-width restricted-height" 
                            value="id"
                            [readonly]="!isParentActive" 
                            [searchable]="true" 
                            [options]="statuses"
                            [clearable]="false">
                </lxm-select>
                <validation-errors for="status"></validation-errors>
            </form-field>

            <form-field dialog label="canAssignToProduct" *ngIf="!hasChildren">
                <slide-toggle toggle [formGroup]="formGroup" for="canAssignToProduct"></slide-toggle>
                <validation-errors for="canAssignToProduct"></validation-errors>
            </form-field>

            <form-field dialog label="gpcBricks" *ngIf="formGroup.value.canAssignToProduct">
              <lxm-select select for="gpcBricks" class="full-width" 
                            labelField="title"
                            [searchable]="true"
                            [multiple]="true"
                            url="api/clvac/product-level-gs1-segments/">
                </lxm-select>
              <validation-errors for="gpcBricks"></validation-errors>
          </form-field>
        </scope>
    </div>

    <div footer>
      <lxm-button type="primary" label="cards.settings.product_management_settings.segments_tree_edit.action.save" (click)="save()" buttonType="submit" [loading]="actionLoading.save"></lxm-button>
      <lxm-button type="secondary" class="ml15" label="cards.settings.product_management_settings.segments_tree_edit.action.cancel" (click)="closeDialog()"></lxm-button>
    </div>
</lxm-dialog>
