<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
    <span header-title>{{'cards.lexi_market_settings.lxm_settings_user_role_edit.title' | translate}}</span>

    <div body>

        <scope translationsPath="cards.lexi_market_settings.lxm_settings_user_role_edit">

            <form-field dialog label="user_role_name" type="input-text" for="name" [required]="true">
                <validation-errors for="name"></validation-errors>
            </form-field>

            <form-field dialog label="user_role_status" [required]="true">
                <lxm-select select class="full-width" for="status" [searchable]="true" [options]="statuses" value="id"></lxm-select>
                <validation-errors for="status"></validation-errors>
            </form-field>

            <form-field dialog label="user_role_permissions" [recommended]="true">
              <div any>
                <div *ngFor="let mg of userActionsList.controls" [formGroup]="mg">

                  <div class="app-module-name">{{ mg.value.module | translateEnum: 'LxmAppModule' }}</div>

                  <div class="app-module-rights">

                    <div *ngFor="let i of mg.get('actions').controls" [formGroup]="i" class="flex flex-row align-center user-action-row">
                      <ng-container *ngIf="!i.value.hidden">
                        <div>
                          <mat-checkbox (change)="toggleSelection(i)" [checked]="i.value.selected || isPreReq(i.value.userAction)" [disabled]="isPreReq(i.value.userAction)">{{ i.value.userAction | translateEnum: 'UserAction' }}</mat-checkbox>
                        </div>
                        <div class="prereq comma-delimited-list">
                          <span *ngFor="let pr of i.value.prerequisites">
                            {{ pr | translateEnum: 'UserAction' }}
                          </span>
                        </div>

                      </ng-container>
                    </div>

                  </div>
                  
                </div>
                <validation-errors for="userActions"></validation-errors>
              </div> 
            </form-field>
        </scope>


    </div>

    <div footer class="space-between">

      <div class="flex flex-row">
        <lxm-button
          class="mr10"
          type="primary" 
          (click)="save()"
          [loading]="saveLoading" 
          buttonType="submit" 
          label="cards.lexi_market_settings.lxm_settings_user_role_edit.action.save">
        </lxm-button>
        <lxm-button 
          type="secondary" 
          (click)="closeDialog()"
          label="cards.lexi_market_settings.lxm_settings_user_role_edit.action.cancel">
        </lxm-button>
      </div>

      <div class="flex flex-row">
        <lxm-button *ngIf="data.id"
                    type="transparent"
                    icon="trash"
                    [loading]="deleteLoading"
                    label="cards.lexi_market_settings.lxm_settings_user_role_edit.action.remove_role"
                    (click)="delete()">
        </lxm-button>
      </div>

    </div>

</lxm-dialog>
