import { Component, Input, Optional } from "@angular/core";
import { ControlContainer, FormGroup } from "@angular/forms";
import { IClvDto } from "src/app/cards/product/product-info/product-info.model";
import { EditableListBase } from "src/app/components/_partials/editable-list-base";
import { OfferProductStatus, UserAction } from "src/app/enum";
import { TranslateEnumPipe, TranslatedValuePipe } from "src/app/pipes";
import { ContentLanguage } from "src/app/_helpers";
import { calculateMargin, calculateUnitPrice } from "src/app/_helpers/calculate";
import { numberUtil } from "src/app/util/number-util";

@Component({
  template: ""
})
export abstract class OfferProductsListBase extends EditableListBase {

  public UserAction = UserAction;
  public OfferProductStatus = OfferProductStatus;

  protected abstract get _defaultItem(): any;
  protected abstract _getNormalizedItem(item): any;

  @Input()
  public get columns() {
    return [];
  };

  @Input() public offerId: string;
  @Input() public date: Date;
  @Input() public currency: IClvDto;
  @Input() public readonly = true;
  @Input() public canAddProducts = true;
  @Input() public showTotal = true;
  @Input() public showNoContentText = true;
  @Input() public canViewPrices = false;
  @Input() public isReceivingOrder = false;
  @Input() public isOfferSent = false;
  @Input() public procurementItemId: string;

  constructor(
    @Optional() controlContainer: ControlContainer,
    public contentLanguage: ContentLanguage
    ) {
    super(controlContainer);
  }

  protected isRowDeleted(row: FormGroup): boolean {
    return row.value.isDeleted;
  }

  protected isRowInvalid(row: FormGroup): boolean {
    return !row.value.isValid;
  }

  public getRowReplacement(row: FormGroup) {
    const { productId } = row.value;
    if (productId) {
      return this.dataSource.data.find(x => x.value.replacementForProductId === productId);
    }
    return null;
  }

  public getRowReplacementIndexes(row: FormGroup) {
    const { productId } = row.value;
    if (productId) {
      const indexes = [];
      this.dataSource.data.forEach(item => {
        const replacementForProductId = item.value.replacementForProductId;
        if (replacementForProductId === productId) {
          indexes.push(replacementForProductId);
        }
      })
      return indexes
    }
    return null;
  }

  public getRowReplacements(row: FormGroup) {
    const { productId } = row.value;
    if (productId) {
      return this.dataSource.data.filter(x => x.value.replacementForProductId === productId);
    }
    return null;
  }

  public isRowReplaced(row: FormGroup): boolean {
    const { replacementForProductId, productId } = row.value || {};
    if (!replacementForProductId && productId) {
      return this.getRowReplacement(row) ? true : false;
    }
    return false;
  }

  public getReplacementRow(row: FormGroup) {
    const { replacementForProductId } = row.value;
    if (replacementForProductId) {
      return this.dataSource.data.find(x => x.value.productId === replacementForProductId);
    }
    return null;
  }

  public isRowReplacement(row) {
    const { replacementForProductId } = row.value;
    return replacementForProductId ? true : false;
  }

  public isRowLastReplacement(index: number) {
    return this.dataSource.data[index + 1]?.value?.replacementForProductId ? false : true;
  }

  public isRowSuspended(row: FormGroup): boolean {
    return row.value.isSuspended;
  }

  protected getRowCssClass(row: any, index: number) {
    return {
      'row-to-delete readonly': this.isRowDeleted(row),
      'invalid': this.isRowInvalid(row),
      'row-suspended': this.isRowSuspended(row),
      "row-replaced": this.isRowReplaced(row),
      "row-replacement": this.isRowReplacement(row),
      "row-group-end": this.isRowLastReplacement(index)
    };
  }

  public removeItem(index: number) {

    const item = this._arr.controls[index];
    console.log(index, item);

    const isNew = item.get("isNew").value;
    const isReplacement = item.get("replacementForProductId").value ? true : false;

    if (isNew || isReplacement) {
      this._arr.removeAt(index);
    } else {
      super.removeItem(index);
    }

    this._updateTable();
  }

  public formatProductAcLabel = (item: any) => {
    const segments = [];
    if (item.ean) {
      segments.push(item.ean);
    }
    if (item.supplierCode) {
      segments.push(item.ean ? `(${item.supplierCode})` : item.supplierCode);
    }
    segments.push(this.contentLanguage.get(item.name));

    return segments.join(' ');
  }

  protected calculateDiscount(salePrice, listPrice) {
    return numberUtil.round(calculateMargin(salePrice, listPrice) * -100, 3);
  }

  protected calculateRowMargin(row) {
    return calculateMargin(row.salePrice, row.calculatedSelfPriceInForeignCurrency || row.selfPrice);
  }

  protected calculateRowValidSchemeMargin(row) {
    return calculateMargin(row.salePrice, row.validSchemeCalculatedSelfPriceInForeignCurrency || row.validSchemeSelfPrice);
  }

  protected calculateMargin(salesPrice, selfPrice) {
    return calculateMargin(salesPrice, selfPrice);
  }

  protected hasValidSchemePrice(validSchemePrice, price) {
    return validSchemePrice && validSchemePrice != price;
  }

  protected calculateUnitPrice(price: number, row: any){
    return calculateUnitPrice(price, row.contentUnitId, row.drainedWeightInGrams, row.netContent);
  }

}
