<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
    <div header-title class="flex flex-column">
        <span>{{'cards.offer.import_dialog.title' | translate}}</span>
    </div>

    <div body>
        <div class="row">
            <div class="col-12 no-padding">
                <form-field dialog label="table.supplier">
                    <lxm-select select for="supplier" [options]="supplierOptions" [labelFn]="supplierLabel" [close]="closeSuppliersSelectSubject.asObservable()" [searchable]="true">
                        <lxm-button 
                        *requiredRights="[UserAction.ViewSuppliers]"
                        header 
                        label="action.add_new" 
                        icon="plus-dark"
                        (click)="onAddNewSupplierClick()">
                      </lxm-button>
                    </lxm-select>
                    <validation-errors for="supplierId" aliasTo="supplier"></validation-errors>
                </form-field>
                <form-field dialog label="table.representative">
                    <lxm-select select for="supplierContact" [options]="supplierContactOptions" [labelFn]="userLabel" [searchable]="true">
                    </lxm-select>
                    <validation-errors for="supplierContactId" aliasTo="supplierContact"></validation-errors>
                </form-field>

                <form-field dialog label="table.offer_type">
                    <lxm-radio any for="offerType" [options]="offerTypes" class="offer-type">
                    </lxm-radio>
                    <validation-errors for="offerTypeId" aliasTo="offerType"></validation-errors>
                </form-field>

                <form-field dialog label="table.currency">
                    <lxm-select select for="currency" [options]="currencyOptions" [labelFn]="currencyLabel" [clearable]="false" [readonly]="currencyOptions.length == 1" [searchable]="true">
                    </lxm-select>
                    <validation-errors for="currencyId" aliasTo="currency"></validation-errors>
                </form-field>

                <form-field dialog label="table.purchase_manager">
                    <lxm-select select for="purchaseManager" [options]="purchaseManagerOptions" [labelFn]="userLabel" [searchable]="true">
                    </lxm-select>
                    <validation-errors for="purchaseManagerId" aliasTo="purchaseManager"></validation-errors>
                </form-field>
            </div>
        </div>
        <div class="separator"></div>

        <div class="row">
            <div class="col-12 no-padding">
                <file-drop-box 
                    (onChange)="uploadFile($event)"
                    (onDelete)="deleteFile($event)"
                    [files]="files"
                    [multiple]="false"
                    addFileText="cards.calendar.dialogs.import.drag_item_here"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <ng-container validation-errors>
                        <validation-errors for="fileId"></validation-errors>
                    </ng-container>
              </file-drop-box>
            </div>

        </div>

        <card-message any type="error" *ngIf="invalidProducts">
            <ng-container *ngIf="!validationResults">
                {{'cards.offer.import_dialog.label.offer_import_error_no_csv_data' | translate}}
            </ng-container>
            <ng-container *ngIf="validationResults">
                {{'cards.offer.import_dialog.label.offer_import_error_report' | translate}}
                <span class="view-errors generic-hover"
                (click)="viewErrors()">{{'action.view' | translate}}</span>
            </ng-container>
        </card-message>

    </div>

    <div footer class="space-between">
        <div class="flex flex-row">
            <lxm-button
                class="mr10"
                type="primary" 
                label="Impordi"
                (click)="import()"
                buttonType="submit"
                [loading]="isFileLoading || isProcessing"
                [disabled]="!fileId || isFileLoading || isProcessing">
            </lxm-button>
          <lxm-button
            type="secondary"
            class="ml10"
            label="action.close"
            (click)="closeDialog()">
          </lxm-button>
        </div>
      </div>
</lxm-dialog>