<div [formGroup]="form" [readonly]="!canEdit">

  <div class="row" *ngIf="hasSupplierPim && isOwnProduct">
    <div class="col-6 no-padding">
      <form-field dialog for="retailChain" label="cards.products.product_packaging_edit_transport_unit.label.retailer" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.RetailChain, form.get('retailChain').value) : null">
        <lxm-select select class="full-width" for="retailChain" [searchable]="true" [options]="formData.retailChains"
          [sortByLabel]="true">
        </lxm-select>
        <validation-errors for="retailChain"></validation-errors>
        <validation-errors for="retailChainId"></validation-errors>
      </form-field>
    </div>
    <div class="col-6 no-padding"></div>
  </div>

  <div class="row">
    <div class="col-6 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_ordering.label"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_ordering" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsOrderUnit, form.get('isOrderUnit').value) : null">
        <slide-toggle toggle for="isOrderUnit"></slide-toggle>
        <validation-errors for="isOrderUnit"></validation-errors>
      </form-field>
    </div>
    <div class="col-6 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_is_inventory_unit.label"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_is_inventory_unit" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsInventoryUnit, form.get('isInventoryUnit').value) : null">
        <slide-toggle toggle for="isInventoryUnit"></slide-toggle>
        <validation-errors for="isInventoryUnit"></validation-errors>
      </form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 no-padding">

    </div>
  </div>

  <div class="row">
    <div class="col-12 no-padding">
      <form-field dialog labelTop="true" label="cards.products.product_packaging_edit_transport_unit.label.unit_code"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_code" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Codes, form.get('codes').value) : null">
        <product-code-manager any for="codes" [readonly]="!canEdit" small="true"
          [codeTypes]="codeTypes"></product-code-manager>
      </form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 no-padding">
      <form-field dialog labelTop="true" label="cards.products.product_packaging_edit_transport_unit.label.unit_tare"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_tare" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Tares, form.get('tares').value) : null">
        <tare-manager any for="tares" [readonly]="!canEdit" [tareOptions]="formData.tareTypes"></tare-manager>
      </form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 no-padding">
      <form-field dialog labelTop="true"
        label="cards.products.product_packaging_edit_transport_unit.label.package_material"
        description="cards.products.product_packaging_edit_transport_unit.label_description.package_material"
        [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PackageMaterials, form.get('packageMaterials').value) : (canEdit ? FieldRequiredType.Recommended : null)">
        <package-material-manager any for="packageMaterials" [readonly]="!canEdit"
          [materialOptions]="formData.packageMaterialTypes"></package-material-manager>
      </form-field>
    </div>
  </div>

  <div class="separator"></div>

  <div class="row">

    <div class="col-6 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_contained" for="subUnit"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_contained"
        [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.SubUnit, form.get('subUnit').value) : canEdit">
        <lxm-select select class="full-width" for="subUnit" [options]="subUnits" [clearable]="false">
          <validation-errors for="subUnitId" aliasTo="subUnit"></validation-errors>
        </lxm-select>
      </form-field>
    </div>

    <div class="col-6 no-padding justify-center">
      <form-field dialog for="subUnitCount" label="cards.products.product_packaging_edit_transport_unit.label.unit_count" description="cards.products.product_packaging_edit_transport_unit.label_description.unit_count" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.SubUnitCount, form.get('subUnitCount').value) : canEdit">
        <combined-input any class="dialog-input-fixed-width-1" for="subUnitCount" >
          <!-- <span suffix>{{'enums.UnitShort.Piece' | translate}}</span> -->
          <validation-errors for="subUnitCount"></validation-errors>
        </combined-input>
      </form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-6 no-padding">

      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_net_weight"
        for="netWeight_g"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_net_weight" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.NetWeight, form.get('netWeight_g').value) : null">
        <combined-input any class="dialog-input-fixed-width-1" for="netWeight_g" [readonly]="true">
          <span suffix>{{'enums.UnitShort.Gram' | translate}}</span>
          <validation-errors for="netWeight_g"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_gross_weight"
        for="grossWeight_g"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_gross_weight" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.GrossWeight, form.get('grossWeight_g').value) : null">
        <combined-input combined class="dialog-input-fixed-width-1" for="grossWeight_g" [readonly]="true">
          <span suffix>{{'enums.UnitShort.Gram' | translate}}</span>
          <validation-errors for="grossWeight_g"></validation-errors>
        </combined-input>
      </form-field>

    </div>

    <div class="col-6 no-padding">

      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_height" for="height_mm"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_height"
        [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Height, form.get('height_mm').value) : canEdit">
        <combined-input combined class="dialog-input-fixed-width-1" for="height_mm">
          <span suffix>{{'enums.UnitShort.Millimeter' | translate}}</span>
          <validation-errors for="height_mm"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_width" for="width_mm"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_width"
        [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Width, form.get('width_mm').value) : canEdit">
        <combined-input combined class="dialog-input-fixed-width-1" for="width_mm">
          <span suffix>{{'enums.UnitShort.Millimeter' | translate}}</span>
          <validation-errors for="width_mm"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_length" for="length_mm"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_length"
        [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Length, form.get('length_mm').value) : canEdit">
        <combined-input combined class="dialog-input-fixed-width-1" for="length_mm">
          <span suffix>{{'enums.UnitShort.Millimeter' | translate}}</span>
          <validation-errors for="length_mm"></validation-errors>
        </combined-input>
      </form-field>
    </div>

  </div>

  <div class="row" *ngIf="!subUnitHasNetAndGrossWeight">
    <div class="col-12 no-padding">
      <card-message type="error">
        {{ 'cards.products.product_packaging.sub_unit_invalid_warning' | translate}}
      </card-message>
    </div>
  </div>

  <div class="row" *ngIf="packageCapacityCheckFailed">
    <div class="col-12 no-padding">
      <card-message type="warning">
        {{ packageCapacityCheckError.value | translate: packageCapacityCheckError.args }}
      </card-message>
    </div>
  </div>

  <div class="separator"></div>

  <div class="row">

    <div class="col-6 no-padding">

      <form-field dialog
        label="cards.products.product_packaging_edit_transport_unit.label.unit_packaging_returnable.label"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_packaging_returnable" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsReturnablePackage, form.get('isReturnablePackage').value) : null">
        <slide-toggle toggle for="isReturnablePackage"></slide-toggle>
        <validation-errors for="isReturnablePackage"></validation-errors>
      </form-field>

    </div>
    <div class="col-6 no-padding"></div>

  </div>

  <div class="row align-center">
    <div class="col-6 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_is_package_reusable"
        description="cards.products.product_packaging_edit_basic_unit.label_description.unit_is_package_reusable" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsReusablePackage, form.get('isReusablePackage').value) : null">
        <slide-toggle toggle for="isReusablePackage"></slide-toggle>
        <validation-errors for="isReusablePackage"></validation-errors>
      </form-field>
    </div>
    <div class="col-6 no-padding">
      <form-field dialog for="packageName" type="input-text"
        label="cards.products.product_packaging_edit_basic_unit.label.unit_package_name"
        description="cards.products.product_packaging_edit_basic_unit.label_description.unit_package_name" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PackageName, form.get('packageName').value) : null">
        <validation-errors for="packageName"></validation-errors>
      </form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-6 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_transport_unit.label.unit_shelf_ready.label"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_shelf_ready" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsShelfReadyPackage, form.get('isShelfReadyPackage').value) : null">
        <slide-toggle toggle for="isShelfReadyPackage"></slide-toggle>
        <validation-errors for="isShelfReadyPackage"></validation-errors>
      </form-field>
    </div>
    <div class="col-6 no-padding">
      <form-field dialog *ngIf="form.get('isShelfReadyPackage')?.value" for="productFaceCount"
        label="cards.products.product_packaging_edit_transport_unit.label.unit_facings"
        description="cards.products.product_packaging_edit_transport_unit.label_description.unit_facings" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.ProductFaceCount, form.get('productFaceCount').value) : null">
        <input input class="lxm-input dialog-input-fixed-width-1" formControlName="productFaceCount" type="text" />
        <validation-errors for="productFaceCount"></validation-errors>
      </form-field>
    </div>
  </div>

</div>