<div [formGroup]="form" [readonly]="!canEdit">

  <div class="row">

    <div class="col-12 no-padding">
      
      <div class="flex f-1">
        <div class="flex f-1 flex-column">
          <div class="flex f-1 flex-row align-center mb10">
      
            <form-field 
              dialog 
              label="cards.products.product_packaging_edit_basic_unit.label.picking_unit" 
              for="pickingUnitId"
              description="cards.products.product_packaging_edit_basic_unit.label_description.picking_unit" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PickingUnit, form.get('pickingUnitId').value) : canEdit">
              <lxm-select any class="dialog-input-fixed-width-3" for="pickingUnitId"
                value="id"
                [options]="formData.units"
                [searchable]="true"
                labelField="translatedValue"
                [isTranslated]="true"
                [clearable]="false"
                [sortByLabel]="true"
                [readonly]="!canEdit">
              </lxm-select>
              <validation-errors for="pickingUnitId"></validation-errors>
            </form-field>  
            <lxm-checkbox class="ml10" for="hasVariableWeight" label="cards.products.product_packaging_edit_basic_unit.label.has_variable_weight" [readonly]="!canEdit"></lxm-checkbox>
          </div>
        </div>
      </div>

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.deposit" description="cards.products.product_packaging_edit_basic_unit.label_description.deposit" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PackageDeposits, form.get('packageDeposits').value) : null">
        <deposit-manager [readonly]="!canEdit" any for="packageDeposits" [depositOptions]="formData.packageDepositTypes"></deposit-manager>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.package_material" description="cards.products.product_packaging_edit_basic_unit.label_description.package_material" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PackageMaterials, form.get('packageMaterials').value) : (canEdit ? FieldRequiredType.Recommended : null)">
        <package-material-manager [readonly]="!canEdit" any for="packageMaterials" [materialOptions]="formData.packageMaterialTypes"></package-material-manager>
      </form-field>

    </div>

  </div>
  <div class="separator"></div>
  <div class="row">
    <div class="col-6 no-padding">

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_net_weight" for="netWeight_g" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_net_weight"  [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.NetWeight, form.get('netWeight_g').value) : canEdit">
        <combined-input any class="dialog-input-fixed-width-1" for="netWeight_g">
          <span suffix>{{'enums.UnitShort.Gram' | translate}}</span>
          <validation-errors for="netWeight_g"></validation-errors>
        </combined-input>
      </form-field>


      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_gross_weight" for="grossWeight_g" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_gross_weight" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.GrossWeight, form.get('grossWeight_g').value) : null">
        <combined-input any class="dialog-input-fixed-width-1" for="grossWeight_g" [readonly]="true">
          <span suffix>{{'enums.UnitShort.Gram' | translate}}</span>
          <validation-errors for="grossWeight_g"></validation-errors>
        </combined-input>
      </form-field>


    </div>
    <div class="col-6 no-padding">

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_height" for="height_mm" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_height" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Height, form.get('height_mm').value) : canEdit">
        <combined-input any class="dialog-input-fixed-width-1" for="height_mm">
          <span suffix>{{'enums.UnitShort.Millimeter' | translate}}</span>
          <validation-errors for="height_mm"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_width" for="width_mm" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_width" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Width, form.get('width_mm').value) : canEdit">
        <combined-input any class="dialog-input-fixed-width-1" for="width_mm">
          <span suffix>{{'enums.UnitShort.Millimeter' | translate}}</span>
          <validation-errors for="width_mm"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_length" for="length_mm" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_length" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.Length, form.get('length_mm').value) : canEdit">
        <combined-input any class="dialog-input-fixed-width-1" for="length_mm">
          <span suffix>{{'enums.UnitShort.Millimeter' | translate}}</span>
          <validation-errors for="length_mm"></validation-errors>
        </combined-input>
      </form-field>

    </div>

  </div>

  <div class="row" *ngIf="!subUnitHasNetAndGrossWeight">
    <div class="col-12 no-padding">
      <card-message type="error">
        {{'cards.products.product_packaging.sub_unit_invalid_warning' | translate}}
      </card-message>
    </div>
  </div>

  <div class="row" *ngIf="packageCapacityCheckFailed">
    <div class="col-12 no-padding">
      <card-message type="warning">
        {{ packageCapacityCheckError?.value | translate: packageCapacityCheckError?.args }}
      </card-message>
    </div>
  </div>

  <div class="row" *ngIf="userHasChangedHeight">
    <div class="col-12 no-padding">
      <card-message type="message">
        {{'cards.products.product_packaging_edit_pallet.warning_message.height_manual_change' | translate}}
      </card-message>
    </div>
  </div>

  <div class="separator"></div>

  <div class="row">

    <div class="col-6 no-padding">

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.is_compressable.label" description="cards.products.product_packaging_edit_basic_unit.label_description.is_compressable" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsCompressable, form.get('isCompressable').value) : null">
        <slide-toggle toggle for="isCompressable"></slide-toggle>
      </form-field>

    </div>

    <div class="col-6 no-padding">

      <form-field dialog *ngIf="form.get('isCompressable')?.value" for="compressedHeight_mm" label="cards.products.product_packaging_edit_basic_unit.label.unit_compressed_height" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_compressed_height" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.CompressedHeight, form.get('compressedHeight_mm').value) : null">
        <combined-input any class="dialog-input-fixed-width-1" for="compressedHeight_mm">
          <span suffix>mm</span>
          <validation-errors for="compressedHeight_mm"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog *ngIf="form.get('isCompressable')?.value" for="compressedWidth_mm" label="cards.products.product_packaging_edit_basic_unit.label.unit_compressed_width" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_compressed_width" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.CompressedWidth, form.get('compressedWidth_mm').value) : null">
        <combined-input any class="dialog-input-fixed-width-1" for="compressedWidth_mm">
          <span suffix>mm</span>
          <validation-errors for="compressedWidth_mm"></validation-errors>
        </combined-input>
      </form-field>

      <form-field dialog *ngIf="form.get('isCompressable')?.value" for="compressedLength_mm" label="cards.products.product_packaging_edit_basic_unit.label.unit_compressed_length" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_compressed_length" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.CompressedLength, form.get('compressedLength_mm').value) : null">
        <combined-input any class="dialog-input-fixed-width-1" for="compressedLength_mm">
          <span suffix>mm</span>
          <validation-errors for="compressedLength_mm"></validation-errors>
        </combined-input>
      </form-field>

    </div>

  </div>

  <div class="separator"></div>

  <div class="row">
    <div class="col-12 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_package_type" for="packageTypeId" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_package_type" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PackageType, form.get('packageTypeId').value) : canEdit">
        <lxm-select any class="dialog-input-fixed-width-3" for="packageTypeId" 
                    [options]="formData.packageTypes" 
                    [searchable]="true" 
                    value="id" 
                    labelField="translatedValue" 
                    [isTranslated]="true"
                    [sortByLabel]="true"
                    [clearable]="false"
                    [readonly]="!canEdit">
        </lxm-select>
        <validation-errors for="packageType"></validation-errors>
        <validation-errors for="packageTypeId"></validation-errors>
      </form-field>
    </div>
  </div>

  <div class="row align-center">
    <div class="col-6 no-padding">
      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.unit_is_package_reusable" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_is_package_reusable" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsReusablePackage, form.get('isReusablePackage').value) : null">
        <slide-toggle toggle for="isReusablePackage"></slide-toggle>
        <validation-errors for="isReusablePackage"></validation-errors>
      </form-field>
    </div>
    <div class="col-6 no-padding">
      <form-field dialog for="packageName" type="input-text" label="cards.products.product_packaging_edit_basic_unit.label.unit_package_name" description="cards.products.product_packaging_edit_basic_unit.label_description.unit_package_name" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.PackageName, form.get('packageName').value) : null">
        <validation-errors for="packageName"></validation-errors>
      </form-field>
    </div>
  </div>

  <div class="row">

    <div class="col-6 no-padding">

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.has_peghole.label" description="cards.products.product_packaging_edit_basic_unit.label_description.has_peghole" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.HasPegHole, form.get('hasPegHole').value) : null">
        <slide-toggle toggle for="hasPegHole"></slide-toggle>
        <validation-errors for="hasPegHole"></validation-errors>
      </form-field>

      <form-field dialog label="cards.products.product_packaging_edit_basic_unit.label.is_stackable.label" for="maxStack" description="cards.products.product_packaging_edit_basic_unit.label_description.is_stackable" [required]="productValidity.shouldValidate ? isFieldInvalid(ProductUnitField.IsStackable, form.get('isStackable').value) : null">
        <span any row class="f-1 space-between">
          <slide-toggle for="isStackable"></slide-toggle>
          <combined-input any for="maxStack"
            [readonly]="!canEdit || !(form.get('isStackable')?.value)"
            [isValid]="form.get('maxStack').valid"
            class="ml10"
            style="flex:0 !important; width:100px; min-width: 100px;"
          >

            <span suffix>{{'enums.UnitShort.Piece' | translate}}</span>
            <validation-errors for="maxStack"></validation-errors>

          </combined-input>
        </span>
      </form-field>

    </div>

    <div class="col-6 no-padding"></div>

  </div>

</div>
