import { formatNumber } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin, merge, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { appSettings } from 'src/app/app.settings';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { asArray, asObject, ProductCardSection, ProductPriceSchemeType, UserAction } from 'src/app/enum';
import { MarkupMethod } from 'src/app/enum/markup-mehtod';
import { IDateInfo } from 'src/app/models';
import { AppState } from 'src/app/state/app.state';
import { numberUtil } from 'src/app/util/number-util';
import { LxmDialog } from 'src/app/_helpers';
import { calculateDiscount, calculateDiscountNumber, calculateMargin, calculateMarkup, calculateRetailPriceWithoutVatByBuyInPriceAndMargin, nextSchemeDay } from 'src/app/_helpers/calculate';
import { locale, ProductPricelistService } from 'src/app/_services';
import { ProductCardBase } from '../product-card-base';
import { IClvDto, ICountry, ICountryTranslated, IPriceGroup, IProductCardFormData } from '../product-info/product-info.model';
import { IDialogDataBase, ProductPricelistDialogBase } from './product-pricelist-dialog-base';
import { IPricesInfo, IRetailerSupplierPriceScheme, IVatDto } from './product-pricelist.model';
import { formUtil } from 'src/app/util/form-util';

@Component({
  selector: "product-supplier-prices-card",
  templateUrl: './product-supplier-prices.card.html',
  styleUrls: ['./product-supplier-prices.card.scss']
})
export class ProductSupplierPricesCardComponent extends ProductCardBase<IPricesInfo> {

  private _defaultColumns = [
    'user',
    'valid',
    'scheme_type',
    'supplier',
    'buyInPrice',
    'margin',
    // 'listPrice',
    // 'discount',
    'retailPriceWithoutVat',
    'retailPrice',
    'vat',
    'info'
  ];

  private _priceGroupedColumns = [
    'user',
    'valid',
    'scheme_type',
    'supplier',
    'buyInPrice',
    'margin',
    // 'listPrice',
    // 'discount',
    'retailPriceWithoutVat',
    'retailPrice',
    'price_group',
    'vat',
    'info'
  ]

  public UserAction = UserAction;
  public cardSection = ProductCardSection.PriceSchemes;

  @Input()
  public productId: string;

  @Input()
  public title: string;

  public pricesInfo: IPricesInfo;
  public formData: IProductCardFormData;

  public expanded = true;

  public cardValid = true;
  public canEdit = true;
  public isOwnProduct: boolean;
  public markupMethod: MarkupMethod

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_SUPPLIER_PRICES_CARD_OPEN;

  constructor(
    injector: Injector,
    public appState: AppState,
    private _dialog: LxmDialog,
    private _route: ActivatedRoute,
    private _productPricelistService: ProductPricelistService
  ) {
    super(injector, 'productSupplierPrices', 'productSupplierPricesChanged');
    this.pricesInfo = this._route.snapshot.data.productCard.pricesInfo as IPricesInfo;
    this.formData = this._route.snapshot.data.productCard.formData as IProductCardFormData;
    this.isOwnProduct = this._route.snapshot.data.productCard.isOwnProduct;

    this.canEdit = this.isOwnProduct && appState.hasRight([UserAction.ManagePrices]);
    this.markupMethod = this.formData.markupMethod;
  }

  public get columns(): string[] {
    return this.hasAnyPriceGroups ? this._priceGroupedColumns : this._defaultColumns;
  }

  public get hasAnyPriceGroups(): boolean {
    return this.pricesInfo?.retailerSupplierPrices?.find(x => x.priceGroups?.length > 0) ? true : false;
  }

  public calculateDiscount(row: any) {
    return calculateDiscount(row.listPrice, row.buyInPrice);
  }

  public calculateMargin(row: any) {
    const vat = (row.vat?.numericValue / 100) || 0;
    var currencyId = row.buyInPriceCurrency?.id;
    const retailPrice = (row.retailPrices || []).find(x => x.currency.id == currencyId)?.price;
    const buyInPrice = row.buyInPrice;
    if (this.markupMethod == MarkupMethod.Coverage) {
      return calculateMarkup(retailPrice, buyInPrice, row.vat?.numericValue, this.markupMethod);
    }
    return (retailPrice / (1 + vat) - buyInPrice) / buyInPrice;
  }

  public openDialog(row?: any) {
    if ((row && !row.isEditable) || !this.canEdit) {
      return;
    }

    this._dialog.open(ProductPricelistSupplierSchemeDialog, {
      width: '1000px',
      height: 'auto',
      data: {
        pricelistCard: this,
        scheme: row ? { ...row } : null,
        editable: this.canEdit
      },
      resolve: () => {
        const resolvers: any = {
          formData: this._productPricelistService.getRetailerSupplierPriceSchemeFormData(this.productId, row?.id)
        };
        return forkJoin(resolvers);
      }
    });
  }

  public reload() {
    this._productPricelistService
      .getRetailerSupplierPrices(this.productId)
      .subscribe(result => {
        this.pricesInfo.retailerSupplierPrices = result;
      }, err => {
        // todo
      });
  }

}

@Component({
  selector: "product-supplier-scheme-dialog",
  host: { 'class': 'dialog' },
  templateUrl: './template/supplier-scheme.dialog.html'
})
// export class ProductPricelistSupplierSchemeDialog extends ProductPricelistDialogBase<IRetailDialogData, IRetailerSupplierPriceSchemeFormData, IRetailerSupplierPriceScheme> {
  export class ProductPricelistSupplierSchemeDialog extends ProductPricelistDialogBase<IRetailDialogData, IRetailerSupplierPriceAndAssortmentSchemeFormData, IRetailerSupplierPriceScheme> {
  public saveLoading = false;

  public ProductPriceSchemeType = ProductPriceSchemeType;
  public schemeTypeOptions = [
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Main),
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Campaign),
    asObject(ProductPriceSchemeType, ProductPriceSchemeType.Procurement)
  ];

  public vatOptions: IVatDto[] = [];
  public canSetSupplier: boolean;
  public defaultCurrency: IClvDto;
  public acceptedCurrencies: IClvDto[] = [];
  public salesCurrencies: IClvDto[] = [];
  public suppliersOptions: IClvDto[];
  public logisticsPartnersOptions: IClvDto[];
  public priceGroups: IPriceGroup[];
  public procurements = [];

  public markupMethod: MarkupMethod;

  protected createForm(): FormGroup {
    return new FormGroup({
      validFrom: new FormControl(),
      validTo: new FormControl(),
      buyInPrice: new FormControl(),
      listPrice: new FormControl(),
      discount: new FormControl(),
      supplier: new FormControl(),
      buyInPriceCurrency: new FormControl(),
      logisticsPartner: new FormControl(),
      vat: new FormControl(),
      retailPrices: new FormControl(),
      margin: new FormControl(),
      schemeType: new FormControl(),
      procurement: new FormControl(),
      procurementFullfillmentTime: new FormControl(),
      procurementSupplyConditions: new FormControl(),
      procurementNotes: new FormControl(),
      isCampaign: new FormControl(),
      priceGroups: new FormArray([])
    });
  }

  override onLoad(): void {

    this.defaultCurrency = this._appState.defaultCurrency;
    this.acceptedCurrencies = this.data.formData.priceSchemeFormData.acceptedCurrencies;
    this.salesCurrencies = this.data.formData.priceSchemeFormData.salesCurrencies;
    this.procurements = this.data.formData.priceSchemeFormData.procurements;
    this.suppliersOptions = this.data.formData.priceSchemeFormData.suppliers;
    this.logisticsPartnersOptions = this.data.formData.priceSchemeFormData.logisticsPartners;
    this.vatOptions = this.getVats(this.data.formData.priceSchemeFormData.retailerCountry?.id);
    this.markupMethod = this.data.formData.priceSchemeFormData.markupMethod;

    this.priceGroups = this.data.formData.priceSchemeFormData.priceGroups;

    if (this.data.formData.priceSchemeFormData.countries) {
      this.countryOptions = this.data.formData.priceSchemeFormData?.countries;
    }

    this.canSetSupplier = this.data.formData.priceSchemeFormData?.canSetSupplier;
    if (!this.canSetSupplier) {
      this.form.get('supplier').setValue(this.data.formData.priceSchemeFormData.retailerSupplier);
    }

    if (this.data.formData.priceSchemeFormData?.defaultLogisticsPartner) {
      this.form.get('logisticsPartner').setValue(this.data.formData.priceSchemeFormData.defaultLogisticsPartner);
    }

  }

  private getVats(countryId: string) {
    let vats = this.data.formData.priceSchemeFormData?.vats
      .filter(x => countryId ? x.country.id === countryId : true)
      .map(this.formatVat);

    if (vats.length === 0) {
      vats = this.data.formData.priceSchemeFormData.vats
        .map(this.formatVat);
    }

    return vats;
  }

  private formatVat = (vat: IVatDto): any => {
    return {
      id: vat.id,
      value: `${formatNumber(vat.numericValue, locale, '1.0-0')}% (${vat.country.name})`,
      numericValue: vat.numericValue
    };
  }

  public get schemeId() {
    return this.data?.scheme?.id;
  }

  public get scheme() {
    return this.data?.scheme;
  }

  public get editable() {
    return this.data?.editable;
  }

  public supplierAcLabel(item: any) {
    var label = item.name;
    if (item.regNo) {
      label += ` (${item.regNo})`;
    }
    return label;
  }

  public get existingPriceGroups(): any[] {
    return this.form.get('priceGroups').value;
  }

  public get availablePriceGroups() {
    const existingPriceGroupIds = this.existingPriceGroups?.map(x => x.priceGroup?.id) ?? [];
    return this.priceGroups?.filter(x => !existingPriceGroupIds.includes(x.id));
  }

  public get priceGroupsFormArrayRef(): FormArray {
    return <FormArray>this.form.get('priceGroups');
  }

  public availablePriceGroupOptions: IPriceGroup[] = this.availablePriceGroups;

  public createPriceGroupFormGroup(data: any) {
    const { priceGroup, margin, retailPrices } = data;

    return new FormGroup({
      priceGroup: new FormControl(priceGroup),
      margin: new FormControl(margin),
      retailPrices: new FormControl(retailPrices)
    })
  }

  public addPriceGroup() {
    const firstAvailablePriceGroup = this.availablePriceGroups?.[0];

    const data = {
      priceGroup: firstAvailablePriceGroup,
      margin: null,
      retailPrices: [{
        currency: this.defaultCurrency,
        price: null,
        priceWithoutVat: null,
        isNew: true
      }]
    }
    const newPriceGroup = this.createPriceGroupFormGroup(data);

   this.priceGroupsFormArrayRef.push(newPriceGroup);
   this.availablePriceGroupOptions = this.availablePriceGroups;
  }

  public onPriceGroupOptionChange(val, groupIndex: number) {
    if (val === undefined) {
      this.priceGroupsFormArrayRef.removeAt(groupIndex);
    }
    this.availablePriceGroupOptions = this.availablePriceGroups;
  }

  public save(): void {
    this.saveLoading = true;
    const f = this.form.value;
    const req = {
      retailerSupplierId: f.supplier?.id,
      logisticsPartnerId: f.logisticsPartner?.id,
      buyInPriceCurrencyId: f.buyInPriceCurrency?.id,
      validFrom: f.validFrom,
      validTo: f.validTo,
      listPrice: f.listPrice,
      buyInPrice: f.buyInPrice,
      schemeType: f.schemeType,
      vatId: f.vat?.id,
      retailPrices: (f.retailPrices || []).map(x => ({ currencyId: x.currency?.id, price: x.price, priceWithoutVat: x.priceWithoutVat })),
      procurementId: f.procurement?.id,
      procurementFullfillmentTime: f.procurementFullfillmentTime,
      procurementSupplyConditions: f.procurementSupplyConditions,
      procurementNotes: f.procurementNotes,
      priceGroups: (f.priceGroups || []).map(x => ({ priceGroupId: x.priceGroup?.id, retailPrices: (x.retailPrices || []).map(p => ({ currencyId: p.currency?.id, price: p.price, priceWithoutVat: p.priceWithoutVat }) )  })),
    };

    this._productPricelistService
      .saveRetailerSupplierPrice(this.data.pricelistCard?.productId, this.schemeId, req)
      .result(this.form, () => {
        this.data.pricelistCard.reload();
        this.closeDialog();
        this.saveLoading = false;
      }, err => {
        this.saveLoading = false;
      });

  }

  public deleteScheme(): void {
    this.deleteSchemeInternal(
      id => this._productPricelistService.deleteRetailerSupplierPrice(this.data.pricelistCard?.productId, id),
      () => this.data.pricelistCard.reload()
    );
  }

  protected normalizeData(scheme: IRetailerSupplierPriceScheme): any {

    const result = <any>scheme || {};

    if (!scheme && this.data.pricelistCard?.pricesInfo?.retailerSupplierPrices) {
      var prevScheme = this.data.pricelistCard.pricesInfo.retailerSupplierPrices[0];
      if (prevScheme) {
        result.buyInPriceCurrency = prevScheme.buyInPriceCurrency || this.defaultCurrency;
        result.listPrice = prevScheme.listPrice;
        result.buyInPrice = prevScheme.buyInPrice;
        result.vat = this.vatOptions.find(x => x.id == prevScheme?.vat?.id);
        result.retailPrices = prevScheme.retailPrices || [];
        result.discount = numberUtil.toNumber(calculateDiscountNumber(prevScheme.listPrice, prevScheme.buyInPrice));
        let minDate = [prevScheme.validFrom?.editableDate, this.nextDay].reduce((a, b) => a > b ? a : b);
        result.validFrom = nextSchemeDay(minDate);
        result.supplier = prevScheme.supplier;
        result.priceGroups = prevScheme.priceGroups ? prevScheme.priceGroups.map(x => this.createPriceGroupFormGroup(x)) : [];
        
        if (prevScheme.logisticsPartner) {
          result.logisticsPartner = prevScheme.logisticsPartner;
        }
      } else {
        result.validFrom = this.nextDay;
        result.buyInPriceCurrency = this.defaultCurrency;
      }
    }
    else {
      result.buyInPriceCurrency = scheme?.buyInPriceCurrency || this.data.currency || this.defaultCurrency;
      let minDate = [this.data.validFrom, this.nextDay].reduce((a, b) => a > b ? a : b);
      result.validFrom = scheme?.validFrom.editableDate || minDate;
      result.validTo = scheme?.validTo?.editableDate || this.data.validTo;
      result.schemeType = scheme?.schemeType || this.data.schemeType;
      result.vat = this.vatOptions.find(x => x.id == scheme?.vat?.id) || this.vatOptions.find(x => x.id == this.data.vatId);
      result.listPrice = scheme?.listPrice || this.data.listPrice;
      result.buyInPrice = scheme?.buyInPrice || this.data.buyInPrice;
      const hasDefaultRetailPrice = this.data.currency && this.data.retailPriceWithoutVat;
      const retailPriceWithVat = numberUtil.applyVat(this.data.retailPriceWithoutVat, result.vat?.numericValue);
      const defaultRetailPrices = hasDefaultRetailPrice ? [{ currency: this.data.currency, price: retailPriceWithVat, priceWithoutVat: this.data.retailPriceWithoutVat }] : [];
      result.retailPrices = scheme?.retailPrices || defaultRetailPrices;
      result.priceGroups = scheme?.priceGroups ? scheme.priceGroups.map(x => this.createPriceGroupFormGroup(x)) : [];
      result.discount = numberUtil.toNumber(calculateDiscountNumber(result.listPrice, result.buyInPrice));

      const originalProcurement = scheme?.procurement;
      result.procurement = this.procurements.find(x => x.id == originalProcurement?.id);
      result.procurementFullfillmentTime = originalProcurement?.fullfillmentTime;
      result.procurementSupplyConditions = originalProcurement?.supplyConditions;
      result.procurementNotes = originalProcurement?.notes;
    }

    return result;
  }

  public applyCalculationsToPriceGroups(callback: Function) {
    const priceGroups = this.form.controls?.priceGroups as FormArray;
    if (priceGroups?.controls?.length) {
      priceGroups?.controls?.forEach((group: FormGroup) => {
          callback(group);
      });
    }
  }

  protected bindCalculations(): void {
    this.calcInit();
    this.applyCalculationsToPriceGroups(this.calcInit.bind(this));

    this.availablePriceGroupOptions = this.availablePriceGroups;
  }

  private calcInit() {
    this.calcDiscount();
  }

  public handleCalculations(field = 'listPrice', form = this.form) {
    switch (field) {
      case 'currency':
          this.calcMargin();
      case 'listPrice':
        this.calcDiscount();
        break;
      case 'discount':
        this.calcBuyInPrice();
        this.calcRetailPrices();
        this.applyCalculationsToPriceGroups(this.calcRetailPrices.bind(this));
        break;
      case 'buyInPrice':
        this.calcDiscount();
        this.calcRetailPrices();
        this.applyCalculationsToPriceGroups(this.calcRetailPrices.bind(this));

        break;
      case 'vat':
        this.calcRetailPrices();
        this.applyCalculationsToPriceGroups(this.calcRetailPrices.bind(this));
        break;
      case 'margin':
        this.calcRetailPrices(form);
        break;
      case 'retailPrices':
        this.calcMargin(form);
        break;
    }
  }

  public calcDiscount() {
    const buyInPrice = numberUtil.toNumber(this.form.get('buyInPrice').value, this.digits.buyInPrice);
    const listPrice = numberUtil.toNumber(this.form.get('listPrice').value, this.digits.listPrice);

    if (!numberUtil.isNumber(buyInPrice) || !numberUtil.isNumber(listPrice) || !listPrice) {
      return;
    }

    const newDiscount = numberUtil.toNumber(calculateDiscount(listPrice, buyInPrice), this.digits.discount) * 100;

    this.form.patchValue({
      discount: numberUtil.round(newDiscount, 2)
    }, {
      emitEvent: false
    });

    this.calcMargin();
  }

  public calcBuyInPrice() {
    const listPrice = numberUtil.toNumber(this.form.get('listPrice').value, this.digits.listPrice);
    const discountInputValue = this.form.get('discount').value;
    const discount = numberUtil.toNumber(discountInputValue, this.digits.discount);

    if (!numberUtil.isNumber(listPrice) || !numberUtil.isNumber(discount)) {
      return;
    }

    const newBuyInPrice = numberUtil.round(listPrice * (1 - discount / 100), this.digits.buyInPrice);

    this.form.patchValue({
      buyInPrice: newBuyInPrice
    }, {
      emitEvent: false
    });

    this.calcMargin();
  }

  public get selectedVat() {
    const selectedVat = this.form.get('vat').value;
    const vat = numberUtil.toNumber(selectedVat?.numericValue) || 0;
    return vat;
  }

  public calcMargin(form: FormGroup = this.form) {
    const selectedVat = this.form.get('vat').value;
    const vat = numberUtil.toNumber(selectedVat?.numericValue) ?? 0;
    const buyInPrice = this.form.get('buyInPrice').value;
    const buyInPriceCurrency = this.form.get('buyInPriceCurrency').value;
    const retailPrices = form.get('retailPrices').value;
    const retailPrice = retailPrices?.find(x => x.currency?.id == buyInPriceCurrency?.id)?.price;
    const data: any = {};

    if (!retailPrice || isNaN(retailPrice) || !buyInPrice || isNaN(buyInPrice)) {
      data.margin = null;
    } else {
      const newMargin = calculateMarkup(retailPrice, buyInPrice, vat, this.markupMethod) * 100;
      data.margin = numberUtil.round(newMargin, this.digits.margin);
    }
    form.patchValue(data, { emitEvent: false });
  }

  public calcRetailPrices(form: FormGroup = this.form) {
    const selectedVat = this.form.get('vat').value;
    const vat = numberUtil.toNumber(selectedVat?.numericValue) ?? 0;
    const buyInPrice = numberUtil.toNumber(this.form.get('buyInPrice').value, this.digits.buyInPrice);
    const margin = numberUtil.toNumber(form.get('margin').value, this.digits.margin);
    const hasBuyInPriceAndMargin = !isNaN(buyInPrice) && !isNaN(margin);
    const retailPriceWithoutVat = hasBuyInPriceAndMargin ? calculateRetailPriceWithoutVatByBuyInPriceAndMargin(buyInPrice, margin, this.markupMethod) : null;
    const retailPrice = hasBuyInPriceAndMargin ? numberUtil.applyVat(retailPriceWithoutVat, vat) : null;

    if (!isNaN(retailPrice) && !isNaN(retailPriceWithoutVat)) {
      const buyInPriceCurrency = this.form.get('buyInPriceCurrency').value;
      const retailPrices = form.get('retailPrices').value;
      const otherRetailPrices = retailPrices.filter(x => x.currency?.id != buyInPriceCurrency?.id);
      const mainRetailPrice = retailPrices.find(x => x.currency?.id == buyInPriceCurrency?.id) || {};
      mainRetailPrice.price = numberUtil.toNumber(retailPrice, this.digits.defaultPrice);
      mainRetailPrice.priceWithoutVat = numberUtil.toNumber(retailPriceWithoutVat, this.digits.defaultPrice);
      const updatedRetailPrices = [mainRetailPrice].concat(otherRetailPrices);

      form.patchValue({ retailPrices: updatedRetailPrices }, { emitEvent: true });
      // this.calcMargin();
    }

  }

  

}

interface IRetailDialogData extends IDialogDataBase<IRetailerSupplierPriceAndAssortmentSchemeFormData, IRetailerSupplierPriceScheme> {
  supplier: IRetailerSupplier;
  editable: boolean;
  vatId: string;
  listPrice: number;
  buyInPrice: number;
  retailPrice: number;
  retailPriceWithoutVat: number;
  currency: IClvDto;
  validFrom: Date;
  validTo: Date;
  schemeType: ProductPriceSchemeType;
}

interface IRetailerSupplier {
  id: string;
  name: string;
  regNo: string;
  country: ICountry;
}

interface IRetailerSupplierPriceSchemeFormData {
  countries?: ICountryTranslated[];
  retailerSupplier: IRetailerSupplier;
  retailerCountry: ICountry;
  canSetSupplier: boolean;
  vats: IVatDto[];
  defaultLogisticsPartner: IClvDto;
  acceptedCurrencies: IClvDto[];
  salesCurrencies: IClvDto[];
  suppliers: IClvDto[];
  logisticsPartners: IClvDto[];
  procurements: [];
  markupMethod: MarkupMethod;
  priceGroups: IPriceGroup[];
}

interface IRetailerSupplierPriceAndAssortmentSchemeFormData {
  priceSchemeFormData: IRetailerSupplierPriceSchemeFormData,
  assortmentFormData: any
}