<card [fullScreen]="state.isFullScreen">
  <span header-title
    >{{'cards.products.products_supplier.title' | translate}}</span
  >
  <div header-actions>

    <div class="flex align-center">
      <ng-container *nonVisitor>
        <div
          class="selected-products"
          [tooltip]="productsTemplate"
          tooltipApplyClass="stripped"
          *ngIf="selection.length > 0"
        >
          <span class="selected-count">
            {{(selection.length)}}
          </span>
        </div>

        <ng-template #productsTemplate>
          <div class="selected-products-wrapper">
            <div class="flex flex-row space-between align-center mb10">
              <span class="flex flex-row align-center">
                <h2>
                  {{'cards.products.products_supplier.selected_products.selected_products'
                  | translate}}
                </h2>
                <span class="ml5">
                  ({{selection.length}})
                </span>
              </span>
              <lxm-button
                type="transparent"
                icon="trash"
                padding="8px 0"
                (click)="emptySelectedProducts()"
              >
              </lxm-button>
            </div>
            <table class="selected-products-table">
              <tr class="header-row">
                <th class="select-column"></th>
                <th class="image-column"></th>
                <th class="name-column">
                  {{'cards.products.products_supplier.table.full_name' |
                  translate}}
                </th>
                <th class="ean-column">
                  {{'cards.products.products_supplier.table.ean' | translate}}
                </th>
              </tr>
              <tr class="product-row" *ngFor="let selection of selection">
                <td class="select-column">
                  <mat-checkbox
                    class="selected-checkbox"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? toggleSelection(selection) : null"
                    [checked]="isProductSelected(selection)"
                  >
                  </mat-checkbox>
                </td>
                <td class="image-column">
                  <image-popover
                    class="selected-image"
                    appendTo="#selected-products-wrapper"
                    type="product"
                    [popperPositionFixed]="false"
                    [image]="selection.imageThumbUrl"
                    [thumbnail]="selection.thumbnail"
                    [thumbnailBase64]="true"
                    [thumbnailContentType]="selection.thumbnailContentType"
                    [status]="selection.status"
                    [description]="getImageDescription(row)"
                    [isActiveBottom]="selection.activeViaOffer"
                  >
                  </image-popover>
                </td>
                <td class="name-column">
                  <a
                    class="link"
                    href="/products/{{selection.id}}"
                    target="_blank"
                  >
                    {{selection.fullName}}
                  </a>
                </td>
                <td class="ean-column">
                  {{selection.ean}}
                </td>
              </tr>
            </table>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *requiredRights="[UserAction.ManageSalesOrders]">
        <lxm-button *ngIf="selection.length" class="ml15" type="green" icon="plus-dark" label="Koosta müügitellimus" (click)="createSalesOrderFromSelection()"></lxm-button> 
      </ng-container>

      <ng-container *requiredRights="[UserAction.ManageSupplierOffers]">
        <lxm-button
          *ngIf="selection.length"
          label="cards.products.product_base_info.action.create_offer"
          class="ml15"
          type="green"
          icon="plus-dark"
          (click)="createOffer()"
        >
        </lxm-button>
      </ng-container>
      <lxm-button
        type="beige"
        class="ml15"
        icon="plus-dark"
        label="cards.products.products_supplier.add_product"
        (click)="newProduct()"
        *requiredRights="[UserAction.ManageProducts]"
      >
      </lxm-button>
      <ng-container *ngIf="appState.hasModule(LxmAppModule.Pdf)">
        <lxm-button
          [disabled]="!selection.length"
          class="ml15"
          type="beige"
          icon="pdf"
          (click)="openExportProductsDialog()"
        ></lxm-button>
      </ng-container>
      <ng-container *nonVisitor>
        <lxm-button
          *ngIf="hasProducts"
          type="beige"
          class="ml15"
          icon="excel"
          title="action.download_xls"
          (click)="export()"
        >
        </lxm-button>
      </ng-container>
    </div>
  </div>

  <div body class="sticky-table">
    <div class="sticky-table-body full-width overflow-x">
      <mat-table
        [dataSource]="dataSource"
        matSort
        [@.disabled]="true"
        [zoomValue]="state.zoom"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="product_select" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div class="flex f-1 justify-center align-center">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="isAllSelected()"
                [indeterminate]="!isAllSelected()"
              >
              </mat-checkbox>
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            stop-propagation
            (click)="$event ? toggleSelection(row) : null"
          >
            <div class="flex f-1 justify-center align-center">
              <mat-checkbox
                class="table-checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelection(row) : null"
                [checked]="isProductSelected(row)"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Image Popover Column -->
        <ng-container matColumnDef="product_image" sticky>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <image-popover
              [image]="row.imageThumbUrl"
              [thumbnail]="row.thumbnail"
              [thumbnailBase64]="true"
              [thumbnailContentType]="row.thumbnailContentType"
              [status]="row.status"
              [description]="getImageDescription(row)"
              [isActiveBottom]="row.activeViaOffer"
            ></image-popover>
          </mat-cell>
        </ng-container>

        <!-- Full Name Column -->
        <ng-container matColumnDef="product_name" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_supplier.table.full_name' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex overflow-ellipsis">
              {{row.fullName}}
            </span>
          </mat-cell>
        </ng-container>

        <!-- EAN Column -->
        <ng-container matColumnDef="product_ean">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              <span [innerHTML]="'cards.products.products_supplier.table.ean' | translate"></span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <span class="cell-row">
                &nbsp;
              </span>
              <div class="cell-row">{{row.ean}}</div>
              <div class="cell-row alt-text">
                {{row.supplierCode}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="product_brand">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_supplier.table.brand' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.brand}}
          </mat-cell>
        </ng-container>

        <!-- Product Tree Column -->
        <ng-container matColumnDef="product_tree">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_supplier.table.product_tree' |
              translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
              {{row.segment}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container
          *ngFor="let chain of formData.retailChains"
          [matColumnDef]="chain.id"
        >
          <mat-header-cell *matHeaderCellDef class="mat-column-product_chain" [title]="chain.name">
            <div class="th-retail-chain">
              {{chain.shortName}}
              <div style="font-size: 10px; font-weight: normal;">
                {{chain.country?.iso3 || '-'}}
              </div>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-product_chain">
            <div class="flex flex-column">
              <supplier-product-price-info-popover
                [scheme]="getRetailPriceScheme(row, chain.id)"
                [hasInvalidValues]="getRetailerHasInvalidValues(row, chain.id)">
              </supplier-product-price-info-popover>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="table.columns"
          class="products-table-head"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: table.columns;"
          class="table-row-hover"
          routerLink="/products/{{row.id}}"
        ></mat-row>
      </mat-table>
    </div>

    <no-content
      heading="cards.products.no_products_heading" 
      text="cards.products.no_products_text"
      image="no-products"
      *ngIf="!hasProducts"
    >
    </no-content>

    <data-table-footer
      [localStorageKey]="LOCAL_STORAGE_KEY"
      [isLoading]="isLoading"
      (paginator)="hookPaginator($event)"
      [search]="search"
      (onSearch)="onSearch($event)"
      [(isFullScreen)]="state.isFullScreen" 
      (onToggleFullScreen)="state.isFullScreen = $event"
      [(zoom)]="state.zoom"
      (onLoad)="onLoad()">
    </data-table-footer>
    
  </div>
</card>


