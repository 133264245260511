<div class="tab-content">
    <card-section>
        <span header-title>
            {{'cards.settings.assortment_groups.title' | translate}}
        </span>
        <div header-actions>
            <div class="flex">
              <lxm-button 
                *ngIf="canEdit"
                type="beige"
                class="ml15"
                icon="plus-dark"
                label="cards.settings.assortment_groups.action.add"
                (click)="openDialog()">
              </lxm-button>
            </div>
        </div>
    </card-section>
</div>

<div class="clv-table">

  <div class="loading-container" *ngIf="dataSource.loading$ | async">
    <div class="loading">
      {{'global.table.loading' | translate}}
    </div>
  </div>

  <mat-table [dataSource]="dataSource" matSort [@.disabled]="true">

    <ng-container matColumnDef="sortOrder">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text [valueType]="TextFilterValueType.Number">
          {{'cards.settings.assortment_groups.label.sort_order' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{row.sortOrder | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="code">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{'cards.settings.assortment_groups.label.code' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{row.code | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{'cards.settings.assortment_groups.label.name' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{row.name | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-text>
          {{'cards.settings.assortment_groups.label.description' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{row.description | formValue}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="children">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{'cards.settings.assortment_groups.label.children' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        <div class="flex flex-row-wrap" class="comma-delimited-list">
          <span *ngFor="let group of getChildren(row, AssortmentGroupLinkType.Includes);">{{ group.value }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="suggested_children">
      <mat-header-cell *matHeaderCellDef>
        <div>
          {{'cards.settings.assortment_groups.label.suggested_children' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        <div class="flex flex-row-wrap" class="comma-delimited-list">
          <span *ngFor="let group of getChildren(row, AssortmentGroupLinkType.Suggestion)">{{ group.value }}</span>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header data-table-filter-dropdown [options]="statuses">
          {{'cards.settings.assortment_groups.label.status' | translate}}
        </div>
      </mat-header-cell>

      <mat-cell *matCellDef="let row">
        {{row.status | translateEnum : 'Status'}}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns" class="clv-table-head"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" class="table-row-hover" (click)="openDialog(row)"></mat-row>
  </mat-table>

  <data-table-footer
    [localStorageKey]="LOCAL_STORAGE_KEY"
    (paginator)="hookPaginator($event)">
  </data-table-footer>

</div>
