<div class="flex flex-row mb15">
  <card
    [formGroup]="form"
    [translationsPath]="translationsPath"
    [title]="offerTitle"
    class="flex f-1 mr20"
  >
    <div header-actions *nonVisitor>
      <owner-select
        [formGroup]="form"
        [readonly]="!canEdit"
        [options]="ownerOptions"
        (change)="onOwnerChange($event)"
        for="offerOwner"
      >
      </owner-select>
    </div>

    <div body>
      <card-section>
        <div class="row">
          <div class="col-6 no-padding">
            <form-field label="supplier" labelPosition="top">
              <span text>
                {{ offer.supplier?.name }}
              </span>
            </form-field>

            <form-field label="sales_manager" labelPosition="top">
              <span text>
                {{ offer.salesManager.name }}
              </span>
            </form-field>

            <form-field
              label="offer_type"
              labelPosition="top"
              description="offer_type"
              [readonly]="true"
            >
              <div text class="flex flex-row">
                <type-indicator [type]="offer.offerType"></type-indicator>
                {{ offer.offerType | systemClvName }}
                <ng-container *ngIf="offer.retailerCampaign">
                  ({{ offer.retailerCampaign | systemClvName }})
                </ng-container>
              </div>
            </form-field>
          </div>

          <div class="col-6 no-padding">
            <form-field
              label="offer_status"
              labelPosition="top"
              description="offer_status"
              [readonly]="true"
            >
              <div any>
                <status-indicator
                  [status]="getOfferStatusProgress(offer?.status)"
                  [description]="offer?.status | translateEnum : 'ReceivingOfferStatus'"
                ></status-indicator>
              </div>
            </form-field>

            <form-field
              label="offer_date"
              labelPosition="top"
              description="offer_date"
              [readonly]="true"
            >
              <span text>
                {{ offer.from | formDate }} - {{ offer.to | formDate }}
              </span>
            </form-field>

            <form-field label="currency" labelPosition="top" *ngIf="currency">
              <span text>
                {{currency.value}} ({{currency.code}})
              </span>
            </form-field>
          </div>
        </div>

        <div
          class="row"
          *ngIf="showOfferSupplyPeriodField || isOfferTypeOrderable"
        >
          <div class="col-6 no-padding" *ngIf="showOfferSupplyPeriodField">
            <form-field
              label="procurement_supply_date"
              labelPosition="top"
              description="procurement_supply_date"
              [readonly]="true"
            >
              <div text class="row align-center">
                <span class>{{procurement.supplyFrom | formDate}}</span>
                <span class="date-range__separator">-</span>
                <span class>{{procurement.supplyTo | formDate}}</span>
              </div>
            </form-field>
          </div>

          <ng-container *requiredModules="['CreatePurchaseOrders']">
            <div class="col-6 no-padding" *ngIf="isOfferTypeOrderable">
              <form-field
                label="orderable"
                labelPosition="top"
                description="orderable"
                [readonly]="true"
              >
                <slide-toggle toggle for="canOrder" [readonly]="true">
                </slide-toggle>
              </form-field>
            </div>
          </ng-container>
        </div>

        <div class="row" *ngIf="isProcurement">
          <div class="col-12 no-padding">
            <form-field label="procurement_description" labelPosition="top">
              <quill-view
                any
                class="full-width"
                [content]="procurementDescription"
              ></quill-view>
            </form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 no-padding">
            <form-field label="offer_description" labelPosition="top">
              <span
                text
                class="multiline-text input-padding"
                style="padding-left: 0;"
              >
                {{ offer.description | formValue }}
              </span>
            </form-field>
          </div>
        </div>
      </card-section>
    </div>
  </card>

  <!-- ACTIONS -->

  <card
    [formGroup]="form"
    class="flex"
    style="min-width: 280px; max-width: 320px;"
  >
    <div header-title class="mb10">
      <h3>{{'table.actions' | translate}}</h3>
    </div>
    <scope [translationsPath]="translationsPath" body>
      <div class="flex flex-column">
        <div class="action-column action-column-top">
          <lxm-button
            *ngIf="canCreatePurchaseOrder"
            class="mb15"
            type="green"
            icon="plus-dark"
            height="40px"
            (click)="createPurchaseOrder()"
            label=".action.create_order"
            title="{{translationsPath}}.action_description.create_order"
            justify="start"
          >
          </lxm-button>

          <ng-container
            *ngIf="offer.status == OfferStatus.Sent || offer.status == OfferStatus.Negotiating"
          >
            <lxm-button
              *ngIf="!hasAutomaticConfirmation && appState.hasRight([UserAction.ConfirmReceivedOffers])"
              class="mb15"
              type="green"
              icon="accept"
              height="40px"
              (click)="accept()"
              label=".action.approve_conditions"
              title="{{translationsPath}}.action_description.approve_conditions"
              [loading]="acceptingOffer"
              justify="start"
            >
            </lxm-button>
            <lxm-button
              *ngIf="appState.hasRight([UserAction.ConfirmReceivedOffers])"
              class="mb15"
              type="grey"
              icon="crossmark-sm"
              height="40px"
              (click)="reject()"
              label=".action.decline_offer"
              justify="start"
              title="{{translationsPath}}.action_description.decline_offer"
            >
            </lxm-button>
            <lxm-button
              *ngIf="canEdit"
              class="mb15"
              type="white"
              icon="send"
              height="40px"
              (click)="send()"
              label=".action.send_back"
              justify="start"
              title="{{translationsPath}}.action_description.send_back"
            >
            </lxm-button>
          </ng-container>
        </div>

        <div class="action-column action-column-bottom">
          <!-- <lxm-button class="mb15" type="beige" icon="edit" height="40px" title="action.edit" (click)="toggleEdit()"
            *ngIf="canEdit && (offer.status == OfferStatus.Sent || offer.status == OfferStatus.Negotiating)">
          </lxm-button> -->

          <lxm-button
            class="mb15"
            type="transparent"
            icon="excel"
            height="40px"
            label="action.download_xls"
            (click)="exportXls()"
            justify="start"
            title="{{translationsPath}}.action_description.download_xls"
          ></lxm-button>
          <lxm-button
            *ngIf="canDownloadEdiPricelist"
            class="mb15"
            type="transparent"
            icon="edi"
            height="40px"
            label="action.download_edi_priceList"
            (click)="exportEdiPricelist()"
            justify="start"
            title="{{translationsPath}}.action_description.download_edi_priceList"
          ></lxm-button>
          <lxm-button
            class="mb15"
            type="transparent"
            icon="download-images"
            height="40px"
            label="action.download_images"
            (click)="downloadImages()"
            justify="start"
            title="{{translationsPath}}.action_description.download_images"
          ></lxm-button>
        </div>
      </div>
    </scope>
  </card>
</div>

<!-- PRODUCTS -->

<ng-container body [ngSwitch]="offerTypeId">
  <ng-container *ngSwitchDefault>
    <ng-container [ngTemplateOutlet]="defaultProductsTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="OfferType.Procurement">
    <ng-container
      [ngTemplateOutlet]="procurementProductsTemplate"
    ></ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultProductsTemplate>
  <card [formGroup]="form">
    <span header-title>{{'cards.offer.products.title' | translate}}</span>
    <div header-actions class="flex align-center">
      <ng-container [ngSwitch]="edit">
        <ng-container *ngSwitchCase="false">
          <lxm-button
            class="mb15"
            type="beige"
            icon="edit"
            title="action.edit"
            (click)="toggleEdit()"
            *ngIf="canEdit && (offer.status == OfferStatus.Sent || offer.status == OfferStatus.Negotiating)"
          >
          </lxm-button>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <lxm-button
            class="ml15"
            type="grey"
            icon="back"
            label="action.cancel"
            (click)="offerId ? toggleEdit() : backToList()"
          >
          </lxm-button>
          <lxm-button
            class="ml15"
            type="beige"
            icon="save"
            label="action.save"
            [loading]="saveLoading"
            (click)="save()"
            buttonType="submit"
          >
          </lxm-button>
        </ng-container>
      </ng-container>
    </div>
    <div body>
      <receiving-offer-products-list
        for="products"
        [currency]="currency"
        [date]="offer.from.editableDate"
        [dateTo]="offer.to?.editableDate"
        [offerType]="offer.offerType.id"
        [readonly]="!canEdit || !edit"
        [canOrder]="isOfferTypeOrderable && form.get('canOrder').value"
        [isKnownSupplier]="isKnownSupplier"
        [campaignType]="campaignType"
        (requestBindSupplierDialog)="openBindSupplierDialog()"
      >
      </receiving-offer-products-list>
    </div>
  </card>
</ng-template>

<ng-template #procurementProductsTemplate>
  <card [formGroup]="procurementProductsFormGroup">
    <span header-title
      >{{'cards.offer.procurement.products.title' | translate}}</span
    >
    <div header-actions class="flex align-center">
      <ng-container [ngSwitch]="edit">
        <ng-container *ngSwitchCase="false">
          <lxm-button
            class="ml15"
            type="beige"
            icon="edit"
            title="action.edit"
            (click)="toggleEdit()"
            *ngIf="canEdit && (offer.status == OfferStatus.Sent || offer.status == OfferStatus.Negotiating)"
          >
          </lxm-button>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <lxm-button
            class="ml15"
            type="grey"
            icon="back"
            label="action.cancel"
            (click)="offerId ? toggleEdit() : backToList()"
          >
          </lxm-button>
          <lxm-button
            class="ml15"
            type="beige"
            icon="save"
            label="action.save"
            [loading]="saveLoading"
            (click)="save()"
            buttonType="submit"
          >
          </lxm-button>
        </ng-container>
      </ng-container>
    </div>
    <div body>
      <ng-container *ngFor="let item of procurement?.items; let i = index">
        <section-expanding
          class="procurement-items-section"
          [headerFullWidth]="true"
          *ngIf="procurementProductsFormGroup.get(item.id).value?.length > 0"
        >
          <div header-title class="procurement-header">
            <span class="procurement-header__name">
              {{item.name}}:&nbsp;
            </span>
            <span class="procurement-header__amount">
              {{item.amount}}
            </span>
            <span class="procurement-header__comment" *ngIf="item.comment">
              , {{item.comment}}
            </span>
          </div>
          <div header-actions class="procurement-total">
            {{'global.total' | translate}}:
            {{procurementProductsFormGroup.get(item.id).value?.length || 0}}
          </div>

          <div body class="mt10">
            <receiving-offer-products-list
              for="{{item.id}}"
              [currency]="currency"
              [date]="offer.from.editableDate"
              [dateTo]="offer.to?.editableDate"
              [offerType]="offerTypeId"
              [showTotal]="false"
              [readonly]="!canEdit || !edit"
              [isKnownSupplier]="isKnownSupplier"
              (requestBindSupplierDialog)="openBindSupplierDialog()"
            >
            </receiving-offer-products-list>
          </div>
        </section-expanding>
      </ng-container>
    </div>
  </card>
</ng-template>

<a class="no-display" #download></a>
