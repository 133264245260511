<div class="code-manager-wrapper" [formGroup]="fgInternal" [class.readonly]="readonly">

  <ul class="code-list" [formArrayName]="for">
    <li class="code-row" *ngFor="let field of formArray.controls; let i = index"
      [class.to-delete]="field.controls['isDeleted']?.value" [class.readonly]="field.controls['isSystemCode']?.value"
      [formGroupName]="i">

      <div class="code-checkbox" [class.input-padding]="small && readonly" style="padding-right: 0;">
        <lxm-checkbox 
          *ngIf="!field.controls['isSystemCode'].value"
          for="isPrimary"
          [class.hidden]="field.controls['isDeleted']?.value"
          (click)="togglePrimary(i)"
          [readonly]="readonly">
        </lxm-checkbox>

        <lxm-checkbox 
          *ngIf="field.controls['isSystemCode'].value"
          for="isSystemCode"
          [class.hidden]="field.controls['isDeleted']?.value"
          [readonly]="true">
        </lxm-checkbox>

        <span *ngIf="field.controls['isDeleted']?.value" class="to-delete-icon" style="stroke: red"></span>
      </div>

      <div class="code-type" [ngStyle]="{'maxWidth': small ? '190px' : 'auto'}">
        <lxm-select for="typeId" *ngIf="!field.controls['isSystemCode']?.value" labelField="translatedValue"
          [isTranslated]="true" value="id" class="full-width" [clearable]="false" [options]="codeTypes" (change)="typeChangeHandler(i)"
          [readonly]="field.controls['isDeleted'].value">
        </lxm-select>

        <span *ngIf="field.controls['isSystemCode']?.value" class="lxm-input">
          Lexi-ID
        </span>

        <validation-errors [for]="'codes['+(i-startIndex)+'].typeId'" aliasTo="typeId" [errorsFormGroup]="formGroup">
        </validation-errors>
      </div>

      <div class="code-format" [ngStyle]="{'maxWidth': small ? '165px' : 'auto'}">
        <lxm-select for="formatId" labelField="value" value="id" class="full-width"
          [disabled]="!field.controls['typeId']?.value"
          [hidden]="field.controls['isSystemCode']?.value"
          [options]="getFormats(field.controls['typeId']?.value)"
          [readonly]="field.controls['isDeleted'].value">
          <validation-errors 
          [for]="'codes['+(i-startIndex)+'].formatId'" aliasTo="formatId"
          [errorsFormGroup]="formGroup"></validation-errors>
        </lxm-select>

      </div>

      <div class="code-value" [class.readonly]="field.controls['isDeleted'].value"
        [ngStyle]="{'width': small ? '142px' : '300px'}">
        <lxm-input style="width: 300px" [readonly]="readonly" for="value" [class.nowrap]="field.controls['isSystemCode']?.value">
          <validation-errors [for]="'codes['+(i-startIndex)+'].value'" aliasTo="value" [errorsFormGroup]="formGroup">
          </validation-errors>
        </lxm-input>

      </div>

      <div class="flex manager-actions ml10 mr10" style="width: 100px;"
        [class.hidden]="field.controls['isSystemCode'].value">
        <lxm-button id="test" type="transparent" padding="6px" icon="accept"
          [disabled]="!field.controls['value'].value"
          [loading]="isCodeRowValidationLoading(i)"
          [class.readonly]="field.controls['isDeleted'].value"
          [class.hidden]="!canValidate(field)"
          class="gs1-check status-{{field.controls['gs1CheckStatus'].value}}" 
          (click)="readonly ? null : validateCode(field, i)">
          <span class="gs1-check-tooltip" *ngIf="isValidResponseType(field.controls['gs1CheckStatus'].value)">
            {{getGs1CheckTranslationKey(field.controls['gs1CheckStatus'].value) | translate}}
          </span>
        </lxm-button>
        <lxm-button type="transparent" padding="6px" icon="cogs" 
          [class.readonly]="field.controls['isDeleted'].value"
          [disabled]="!field.controls['formatId'].value" (click)="generateCode(field, i)">
        </lxm-button>
        <lxm-button type="transparent" padding="6px"
          [icon]="field.controls['isDeleted'].value ? 'generate' : 'trash'" (click)="removeItem(i)"
          [class.hidden]="readonly">
        </lxm-button>
      </div>

    </li>
  </ul>

  <!-- ULRELATED LABEL PATH -->
  <lxm-button type="transparent" class="new-code-button" padding="8px 0" icon="plus-dark"
    label="cards.products.product_packaging_edit_transport_unit.action.add_code" (click)="addItem()">
  </lxm-button>

</div>

