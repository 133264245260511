<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
  <span header-title>{{'cards.products.product_catalog.title' | translate}}</span>
  <div body style="padding-bottom: 40px;">

    <form-field label="cards.products.product_assortment_management_edit.label.valid" description="cards.products.product_assortment_management_edit.label_description.valid" [required]="true">
      <lxm-datepicker combined class="dialog-input-fixed-width-1" for="validFrom"></lxm-datepicker>
      <validation-errors for="validFrom"></validation-errors>
    </form-field>

    <form-field label="cards.products.product_assortment_management_edit.label.status" description="cards.products.product_assortment_management_edit.label_description.status" [required]="true">
      <lxm-select select class="dialog-input-fixed-width-2" labelField="name" [clearable]="false" [options]="statuses" value="id" for="status"></lxm-select>
      <validation-errors for="status"></validation-errors>
    </form-field>

    <span class="separator"></span>

    <form-field label="cards.products.product_assortment_management_edit.label.retailers_list_is_exclusive" description="cards.products.product_assortment_management_edit.label_description.retailers_list_is_exclusive" [recommended]="true">
      <slide-toggle toggle for="retailersListIsExclusive"></slide-toggle>
      <validation-errors for="retailersListIsExclusive"></validation-errors>
    </form-field>

    <form-field 
    [label]="form.get('retailersListIsExclusive').value
      ? 'cards.products.product_assortment_management_edit.label.exclusive_retailers'
      : 'cards.products.product_assortment_management_edit.label.inclusive_retailers'" 
      [description]="form.get('retailersListIsExclusive').value
      ? 'cards.products.product_assortment_management_edit.label_description.exclusive_retailers'
      : 'cards.products.product_assortment_management_edit.label_description.inclusive_retailers'">

      <lxm-select select class="full-width" labelField="name" [multiple]="true" [options]="retailChains" value="id" for="retailers" [searchable]="true" [sortByLabel]="true"></lxm-select>
      <validation-errors for="retailers"></validation-errors>
      <validation-errors for="retailerIds"></validation-errors>
    </form-field>

  </div>

  <div footer class="space-between">

    <span class="flex flex-row">
      <lxm-button type="orange" label="cards.products.product_file_management.save_file" [loading]="saveLoading" (click)="save()" buttonType="submit"></lxm-button>
      <lxm-button type="grey" class="ml10" label="cards.products.product_file_management.cancel_file" (click)="closeDialog()"></lxm-button>
    </span>

    <span>
      <lxm-button *ngIf="data.schema?.id" type="transparent" label="action.delete" icon="trash" class="ml30" (click)="deleteScheme()"></lxm-button>
    </span>

  </div>

</lxm-dialog>
