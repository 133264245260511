<card-expanding [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{'cards.products.product_pricelist.title_clientprices' | translate}}</span>

  <div header-actions *ngIf="productCard.isOwnProduct">
    <ng-container *requiredRights="[UserAction.ManagePrices]">
      <lxm-button 
        type="beige" 
        icon="plus-dark" 
        label="cards.products.product_pricelist.action.add_schema"
        (click)="openRetailerSchemeDialog(null, chain)">
      </lxm-button>
    </ng-container>
  </div>

  <div body>

    <div *ngIf="!retailersWithPrices?.length">
      <no-content image="no-product-price-agreements" text="global.no_price_agreements" [inline]="true"></no-content>
    </div>

      <section-expanding [expanded]="false" class="mt20" *ngFor="let retailer of retailersWithPrices">
        <span header-title>{{retailer.name}}</span>
        <div body>
          <div class="row overflow-x">
            <div class="col-12 no-padding">
              <mat-table [dataSource]="retailPrices[retailer.id]" matSort [@.disabled]="true">
                <ng-container matColumnDef="list_user">
                  <mat-header-cell *matHeaderCellDef>
                    <span>
                      {{'cards.products.product_pricelist.table.retail_prices.user' | translate}}
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span>
                      {{row.modifiedBy | formValue}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_valid">
                  <mat-header-cell *matHeaderCellDef>
                    <span>
                      {{'cards.products.product_pricelist.table.retail_prices.valid' | translate}}
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span>
                      {{row.validFrom | formDate}} - {{row.validTo | formDate}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_scheme_type">
                  <mat-header-cell *matHeaderCellDef>
                    <span [innerHTML]="'cards.products.product_pricelist.table.retail_prices.scheme_type' | translate">
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div class="flex flex-row nowrap">
                      <type-indicator [typeId]="row.schemeType" enum="ProductPriceSchemeType"></type-indicator>
                      {{row.schemeType | translateEnum : "ProductPriceSchemeType" }}
                    </div>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_publicSalesPrice">
                  <mat-header-cell *matHeaderCellDef>
                    <span [innerHTML]="'cards.products.product_pricelist.table.retail_prices.public_sales_price' | translate"></span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span>
                      {{row.publicSalesPrice | priceValue:3:row.publicSalesPriceCurrency?.value}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_discount">
                  <mat-header-cell *matHeaderCellDef>
                    <span>
                      {{'cards.products.product_pricelist.table.retail_prices.discount' | translate}}
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{calculateRetailDiscount(row) | percentValue}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_retailSalesPrice">
                  <mat-header-cell *matHeaderCellDef>
                    <span [innerHTML]="'cards.products.product_pricelist.table.retail_prices.retail_sales_price' | translate"></span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span>
                      {{row.retailSalesPrice | priceValue:3:row.publicSalesPriceCurrency?.value}}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_margin">
                  <mat-header-cell *matHeaderCellDef>
                    <span>
                      {{'cards.products.product_pricelist.table.retail_prices.margin' | translate}}
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    {{calculateMargin(row) | percentValue}}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_approved">
                  <mat-header-cell *matHeaderCellDef>
                    <span>
                      {{'cards.products.product_pricelist.table.retail_prices.approved' | translate}}
                    </span>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <span *ngIf="row.isConditionApproved">
                      <a class="arrow-link" [routerLink]="['/offers', row.offerId]"
                        target="_blank">{{ row.conditionApprovedAt | amDateFormat: 'L' }}</a>
                    </span>
                    <span *ngIf="!row.isConditionApproved">
                      {{ '' | formValue }}
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="list_actions">
                  <mat-header-cell *matHeaderCellDef>
          
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <lxm-button *ngIf="canEdit && (row.isEditable || row.isCurrent)" type="grey" [icon]="row.isEditable ? 'edit' : 'mark-as-expired'" (click)="row.isEditable ? openRetailerSchemeDialog(row, chain) : openPricelistExpirationDialog(row)"></lxm-button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
                <mat-row class="pricelist-table-row" 
                  [class.current]="row?.isCurrent" 
                  [class.upcoming]="row?.isEditable" 
                  [class.expired]="!row.isCurrent && !row.isEditable" 
                  *matRowDef="let row; columns: table.columns;">
                </mat-row>
              </mat-table>
            </div>
          </div>
        </div>
      </section-expanding>

  </div>

</card-expanding>