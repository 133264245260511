<card-expanding [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{'cards.products.product_pricelist.title' | translate}}</span>

  <div header-actions *ngIf="isOwnProduct">
    <ng-container *requiredRights="[UserAction.ManagePrices]">
      <lxm-button 
        type="beige" 
        icon="plus-dark" 
        label="cards.products.product_pricelist.action.add_schema"
        (click)="openBaseSchemeDialog(null)">
      </lxm-button>
    </ng-container>
  </div>

  <div body>

    <div *ngIf="!pricesInfo?.supplierBasePrices?.length">
      <no-content image="no-product-pricelist" text="global.no_product_pricelist" [inline]="true"></no-content>
    </div>

      <div class="row overflow-x" *ngIf="pricesInfo?.supplierBasePrices?.length">
        <div class="col-12 no-padding">

          <mat-table [dataSource]="pricesInfo.supplierBasePrices" matSort>

            <ng-container matColumnDef="base_country">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.country' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <!--{{row.country | json}} todo - iso3, currently missing from db-->
                {{row.country.iso | formValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_valid">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.valid' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.validFrom | formDate}} - {{row.validTo | formDate}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_user">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.user' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.modifiedBy | formValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_manufacturer">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.manufacturer' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.manufacturer?.value | formValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_manufacturerSalesPrice">
              <mat-header-cell *matHeaderCellDef>
                <span [innerHTML]="'cards.products.product_pricelist.table.base_prices.manufacturer_sales_price' | translate"></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.manufacturerSalesPrice | priceValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_discount">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.discount' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{calculateSupplierDiscount(row) | percentValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_buyInPrice">
              <mat-header-cell *matHeaderCellDef>
                <span [innerHTML]="'cards.products.product_pricelist.table.base_prices.buy_in_price' | translate"></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.buyInPrice | priceValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_logisticsPartner">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.logistics_partner' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{row.logisticsPartner?.value | formValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_publicSalesPrice">
              <mat-header-cell *matHeaderCellDef>
                <span [innerHTML]="'cards.products.product_pricelist.table.base_prices.public_sales_price' | translate"></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <div *ngIf="row.publicSalesPrices?.length">
                  <div *ngFor="let price of row.publicSalesPrices">
                    {{ price.price | priceValue:3:price.currency.value}}
                  </div>
                </div>
                <div *ngIf="!row.publicSalesPrices?.length">
                  {{ row.publicSalesPrice | priceValue }}
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_currency">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.currency' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{(row.currency || '€') | formValue}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="base_vat">
              <mat-header-cell *matHeaderCellDef>
                <span>
                  {{'cards.products.product_pricelist.table.base_prices.vat' | translate}}
                </span>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                {{(row.vat?.numericValue + "%") | formValue}}
              </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
            <mat-row class="pricelist-table-row" 
              [class.table-row-hover]="canEdit && row?.isEditable"
              [class.current]="row?.isCurrent" 
              [class.upcoming]="row?.isEditable" 
              [class.expired]="!row.isCurrent && !row.isEditable" 
              [class.current]="row?.isCurrent" 
              *matRowDef="let row; columns: table.columns;"
              (click)="openBaseSchemeDialog(row)">
            </mat-row>
          </mat-table>

        </div>
      </div>
  </div>

</card-expanding>